import React, { useState, useRef, useEffect } from 'react';
import { postRequest, postRequestForm } from "../../components/Common/Utils.js";
import { TextField, Button, Box, Typography, Modal, createTheme, ThemeProvider } from '@mui/material';
import { useLocation } from "react-router-dom";
import Chatimage from './image1.png';
import cryptLib from "@skavinvarnan/cryptlib";
import io from 'socket.io-client';
import swal from 'sweetalert2';
import './Chat.css';

const socketUrl = 'https://socket.7nightsuae.com';

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const Chat = () => {
  const location = useLocation();
  const state = location.state?.row;

  const [message, setMessage] = useState('');
  const [currentOrg, setCurrentOrg] = useState(state?.channelId);
  const [currentEventId, setCurrentEventId] = useState(state?.channelId);
  const [eventData, setEventData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState(null);
  const [userList, setUserList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  // const [currentAuthor, setCurrentAuthor] = useState(localStorage.getItem("stored_venueId") ? localStorage.getItem("stored_venueId") : null);

  const [currentAuthor, setCurrentAuthor] = useState("6474381f505922fab62d8d72");

  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const socket = useRef(null);

  useEffect(() => {
    socket.current = io(socketUrl);

    socket.current.on('connect', () => {
      //console.log('Connected to the chat server');
    });

    socket.current.on('new_message', (newMessage) => {
      if (newMessage.chatId === currentEventId) {
        fetchMessages();
      }
    });

    return () => {
      socket.current.disconnect();
    };
  }, [currentEventId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    fetchMessages();
    const interval = setInterval(fetchMessages, 5000);
    return () => clearInterval(interval);
  }, [currentEventId]);

  useEffect(() => {
    if (eventData) {
      if (eventData.type === 'image') handleSendMessage(true);
      if (eventData.type === 'audio') handleSendMessage(false, true);
      if(eventData.type === 'text') handleSendMessage(false, false);
    }
  }, [eventData]);

  function base64Decode(str, channel = "") {
    try {

      return cryptLib.decryptCipherTextWithRandomIV(str, channel);
    } catch (error) {
        //console.log("error",error, channel, str)
      return "";
    }
  }

  function base64Encode(str, channel = "") {
    try {
      return cryptLib.encryptPlainTextWithRandomIV(str, channel);
    } catch (error) {
      return "";
    }
  }

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const fetchMessages = async () => {
    const payload = { chatId: currentEventId };
    const response = await postRequest(`chat/venue/chat-detail`, payload);

    if (response.status === 1) {
      const currentOrgId = currentAuthor;

      let messages = response.data.map((msg) => ({
        ...msg,
        authorImage: state?.image || `https://ui-avatars.com/api/?name=${state?.first_name + " " + state?.last_name}&size=256&round=true`,
        id: msg._id,
        text: base64Decode(msg.msg, msg.chatId),
        sender: msg.author === currentOrgId ? 'user' : 'admin',
        time: formatTimestamp(msg.date),
      }));     

        //   messages = messages.filter((msg) => msg.text.trim() !== '');
      
      messages = messages.reverse();

      setMessages(messages);
    } else {
      setMessages([]);
    }
  };
 

  function generateRandomString() {
    let hexPart = '';
    for (let i = 0; i < 24; i++) {
      hexPart += Math.floor(Math.random() * 16).toString(16);
    }
    let numberPart = Math.floor(Math.random() * 10000000000).toString();
    let decimalPart = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
    return hexPart + '_' + numberPart + '.' + decimalPart;
  }

  const handleSendMessage = (image = false, audio = false) => {
    let msg = message;
    if (message.trim() === '' && !eventData) return;

    if (eventData && image) {
      msg = eventData.data;
    }

    if (eventData && audio) {
      msg = eventData.data;
    }

    const membersFromChannelId = currentEventId.split(',');

    const messageData = {
      id: generateRandomString(),
      chatId: currentEventId,
      msg: base64Encode(msg, currentEventId),
      type: image ? 'image' : audio ? 'audio' : 'text',
      chatType: 'venue',
      audioDuration: "",
      author: currentAuthor,
      members: [
        membersFromChannelId[1],
        membersFromChannelId[0],
      ],      
      status: 1,
      date: fetchTimeStamps(),
      receivers: [],
    }
    socket.current.emit('new_message', JSON.stringify(messageData));
    const msgFe = {
      ...messageData,
      text: msg,
      type: image ? 'image' : audio ? 'audio' : 'text',
      sender: 'user',
      time: formatTimestamp(Date.now()),
    }

    setMessages((prevMessages) => [...prevMessages, msgFe]);
    setMessage('');
    setEventData(null);
    
  };

  const fetchTimeStamps = () => {
    const date = new Date();
    return date.toLocaleString('en-US');
  };

  const formatTimestamp = (timestamp) => {
    // Ensure timestamp is treated as a number
    const date = new Date(Number(timestamp));
    return date.toLocaleString('en-US', {
      hour12: true, // Ensure AM/PM is used
    });
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      const formData = new FormData();
      formData.append('image', selectedFile);
      const res = await postRequestForm("comman/img/upload", formData);
      if (res.status === 1) {
        setEventData({ type: 'image', data: res.data.url });
      } else {
        swal("Error", "Error in uploading image", "error");
      }
    } else if (selectedFile.type.startsWith('audio/')) {
      const formData = new FormData();
      formData.append('audio', selectedFile);
      const res = await postRequestForm("comman/doc/upload", formData);
      if (res.status === 1) {
        setEventData({ type: 'audio', data: res.data.url });
      } else {
        swal("Error", "Error in uploading audio", "error");
      }
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleOpenModal = (message) => {
    setSelectedMessage(message);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleMessageBoxClick = async (msg) => {
    if (msg.receivers.length < 2) return;

    const ids = msg.members;

    const payload = {
      limit: 10000,
      page: 1,
      userIds: ids
    };
    const response = await postRequest(`user/ids`, payload);
    let data = response.data;

    data = data.map((user) => ({
      ...user,
      seen: msg.seenBy.includes(user._id),
      delivered: msg.receivers.includes(user._id),
    }));

    setUserList(data);
    handleOpenModal(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="chat-container">
        <Typography variant="h4" align="center" gutterBottom style={{ width: "100%" }}>
          Support Chat With {state?.member?.title || "User"}
        </Typography>

        <Box flex="1" minHeight={0} overflow="auto" style={{ width: "100%" }}>
          {messages.length === 0 && (
            <div style={{ marginTop: "48px" }}>
              <center><img src={Chatimage} alt="Dummy Image" style={{ height: "300px", width: "300px", borderRadius: "50%" }} /></center>
              <Typography variant="h3" align="center" style={{ marginTop: '50px' }}>
                Type a message to start the conversation
              </Typography>
            </div>
          )}

          {messages.map((msg) => (
            <Box key={msg.id} textAlign={msg.sender === 'user' ? 'right' : 'left'} mt={1} mb={3} style={{ position: 'relative' }}>
              {msg.sender === 'admin' && (
                <>
                  <div className="message-container">
                    <img src={msg.authorImage} alt="Avatar" style={{ height: '40px', width: '40px', borderRadius: '50%', marginRight: '10px' }} />
                    <Typography variant="body2">{msg.authorName}</Typography>
                    <Typography className="message-bubble" style={{ marginLeft: "16px" }}>
                      {msg.type === 'image' && <img src={msg.text} alt="Uploaded File" style={{ maxWidth: '100%', height: '330px', marginTop: '10px' }} />}
                      {msg.type === 'audio' && <audio src={msg.text} controls style={{ width: '300px', marginTop: '10px' }} />}
                      {msg.type === 'text' && msg.text}
                    </Typography>
                  </div>
                  <Typography variant="caption" display="block" className="message-time">
                    {msg.time}
                  </Typography>
                </>
              )}

              {msg.sender === 'user' && (
                <>
                  <Typography className="message-bubble user-message">
                    {msg.type === 'image' && <img src={msg.text} alt="Uploaded File" style={{ maxWidth: '100%', height: '330px', marginTop: '10px', marginRight: "16px" }} />}
                    {msg.type === 'audio' && <audio src={msg.text} controls style={{ width: '300px', marginTop: '10px', marginRight: "16px" }} />}
                    {msg.type === 'text' && msg.text}
                  </Typography>
                  <Typography variant="caption" display="block" className="message-time" style={{ marginRight: "16px" }}>
                    {msg.time}
                  </Typography>
                  {msg.receivers.length > 0 && (
                    <Typography onClick={() => handleMessageBoxClick(msg)} variant="caption" display="block" className="message-time" style={{ marginRight: "16px" }}>
                      <span style={{ color: "#fff" }}> Delivered to {msg?.receivers?.length - 1}</span> / <span style={{ color: "#fff" }}>Seen by {msg?.seenBy?.length}</span>
                    </Typography>
                  )}
                </>
              )}
            </Box>
          ))}

          <div ref={messagesEndRef} />
        </Box>

        <Box className="chat-input-container" style={{ width: "100%" }}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept="image/*, audio/*"
          />
          <Button variant="contained" onClick={() => fileInputRef.current.click()} className="send-button-custom">
            Upload File
          </Button>

          <TextField
            placeholder="Type a message..."
            variant="outlined"
            fullWidth
            value={message}
            onChange={handleMessageChange}
            className="chat-input"
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            InputLabelProps={{ className: 'placeholder-text' }}
          />

          <Button variant="contained" onClick={() => handleSendMessage()} className="send-button-custom">
            Send
          </Button>
        </Box>

        <Modal
          open={shouldOpenModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
};

export default Chat;
