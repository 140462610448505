import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  postRequest,
  customValidator,
} from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import FileUpload from "components/Common/FileUpload.js";

import BusinessModal from "components/Modal/BannerModal.js";

export default function CreateCategory() {
  const [categoryData, setCategoryData] = useState({
    title: "",
    subTitle: "",
    startColor: "",
    endColor: "",
    selectedImage: "",
  });
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [model, setModel] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [businessText, setBusinessText] = useState("");
  const [businessImage, setBusinessImage] = useState("");
  const [bussinessData, setBussinessData] = useState([]);

  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

   // **Validation Function**
   const validateForm = () => {
    let tempErrors = {};
    if(!categoryData.title) {
      tempErrors.title = "Title is required";
    }

    if(!categoryData.startColor) {
      tempErrors.startColor = "Start color is required";
    }

    if(!categoryData.endColor) {
      tempErrors.endColor = "End color is required";
    }

    if(!categoryData.selectedImage) {
      tempErrors.selectedImage = "Image is required";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };



  const Createcategory = async (e) => {
    e.preventDefault();
    categoryData.selectedImage = selectedImage;
    // const validator = customValidator(
    //   [
    //     {
    //       field: "title",
    //       errorMsg: "Title is required",
    //     },
    //     // {
    //     //   field: "subTitle",
    //     //   errorMsg: "Please enter subtitle",
    //     // },
    //     {
    //       field: "startColor",
    //       errorMsg: "Please enter start color",
    //     },
    //     {
    //       field: "endColor",
    //       errorMsg: "Please enter end color",
    //     },
    //   ],
    //   categoryData
    // );
    // if (!validator.success) {
    //   toast.error(validator.message);
    //   return;
    // }
     
    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      return;
    }

    setStartLoader(true);
    
   
    const selectedImageForm = new FormData();
    selectedImageForm.append("image", categoryData.selectedImage);
    const selectedImageRes = await postRequestForm(
      "comman/img/upload",
      selectedImageForm
    );

    setStartLoader(false);   

    setLoading(true);

    const payload = {     
      title: categoryData.title,
      subTitle: categoryData.subTitle,
      startColor: categoryData.startColor,
      endColor: categoryData.endColor,
      displayAllweek: categoryData.displayAllweek ,
      allowShuffle: categoryData.allowShuffle,
      status: categoryData.status,
      image: selectedImageRes?.data?.url || "",  
      banners: [businessId],
    }

    const response = await postRequest("venue/category/add", payload);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/category");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...categoryData };
    list[index] = value;
    setCategoryData(list);
  };

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const selectRow = (id, name, logo) => {
    setBusinessId(id?._id);
    setBusinessText(id?.title);
    setBusinessImage(id?.items[0]?.image);   
    closeModal();
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Category Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Category Management</li>:{" "}
              <li className="breadcrumb-item">Create Category</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Category </h4>
                <Form onSubmit={Createcategory}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                            {errors.title && <span className="text-danger">{errors.title}</span>}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Sub Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("subTitle", e.target.value)
                              }
                              name="subTitle"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Color
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="color"
                              onChange={(e) =>
                                updateValue("startColor", e.target.value)
                              }
                              name="startColor"
                              className="form-control"
                            />
                            {errors.startColor && <span className="text-danger">{errors.startColor}</span>}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Color
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="color"
                              onChange={(e) =>
                                updateValue("endColor", e.target.value)
                              }
                              name="endColor"
                              className="form-control"
                            />
                            {errors.endColor && <span className="text-danger">{errors.endColor}</span>}
                          </div>
                        </div>
                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Display all week offers
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("displayAllweek", e.target.value)
                            }
                            className="form-select"
                          >
                            <option value="true" selected>
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Allow shuffle
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("allowShuffle", e.target.value)
                            }
                            className="form-select"
                          >
                            <option value="true" selected>
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Select Banner
                          </label>

                            <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            
                            {businessText ? (
                             
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Select Banner
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={businessImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={businessText}
                                  id="selectedVenue"
                                  placeholder="No Banner chosen"
                                />
                              </div>
                            ) : (
                              
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Select Banner
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No Business chosen"
                                />
                              </div>
                            )}
                          </div>   
                          </div> 

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            {errors.selectedImage && <span className="text-danger">{errors.selectedImage}</span>}
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      
      <FileUpload message="File Uploading" status={startLoader} />


      <BusinessModal show={model} closeModal={closeModal} selectRow={selectRow} />

    </React.Fragment>
  );
}
