import React, { useState, useEffect } from "react";
import { postRequest } from "../Common/Utils.js";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Modal,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

const TableComponent = ({ selectRow, venueId, categoryId, published = false }) => {
  const [offerdata, setOfferData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sort, setSort] = useState("createdAt");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  // State for storing multiple selected offers
  const [selectedOffers, setSelectedOffers] = useState([]);

  useEffect(() => {
    offerManagment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    offerManagment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizePerPageOrg, sort]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    if (type === "pagination" && page !== currentPage) {
      setCurrentPage(page);
    }
  };

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img src={row.image} alt="logo img" width="50px" />
      </>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  // Define columns without the individual "Select" button column
  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "title",
      text: "Title",
      sort: false,
    },
    {
      dataField: "venueName",
      text: "Venue",
      sort: false,
    },
    {
      dataField: "logo",
      text: "Logo",
      formatter: ImgLogoFormatter,
      sort: false,
    },
  ];

  const handleChange = (event) => {
    setSizePerPage(Number(event.target.value));
  };

  const handlesorting = (event) => {
    setSort(event.target.value);
  };

  const handleReload = () => {
    window.location.reload();
  };

  // Define selectRow prop for multi-select with checkboxes
  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedOffers.map((offer) => offer._id),
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedOffers((prev) => [...prev, row]);
      } else {
        setSelectedOffers((prev) => prev.filter((r) => r._id !== row._id));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelectedOffers(rows);
      } else {
        setSelectedOffers([]);
      }
    },
  };

  const offerManagment = async () => {
    setLoader(true);
    // You can use localStorage or props for venueId
    const vid = localStorage.getItem("stored_venueId");

    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: "asc",
      venueId: vid ? vid : venueId,
      categoryId: categoryId,
    };

    if (published) {
      payload.unpublished = false;
    }

    if (searchText) {
      payload.search = searchText;
    }

    const response = await postRequest(`venue/offer/list/admin`, payload);
    const data = response.data;
    setTotalPage(data.count);
    setCurrentFPage(data.page);
    setOfferData(data.list);
    setLoader(false);
  };

  return (
    <>
      <Row>
        <Col className="col-12">
          <Card>
            {loader ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory({
                    page: currentFPage,
                    sizePerPage: sizePerPageOrg,
                    totalSize: totalPage,
                    custom: true,
                  })}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <>
                      <ToolkitProvider
                        keyField="_id"
                        data={offerdata}
                        columns={columns}
                      >
                        {(props) => (
                          <Row>
                            <Col className="col-lg-12 d-flex mb-3">
                              <div className="w-10 d-flex">
                                <label className="mb-0 text-label">
                                  Page Size
                                </label>
                                <select
                                  value={sizePerPageOrg}
                                  onChange={handleChange}
                                  className="form-select"
                                  style={{ marginRight: "90px" }}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                </select>
                              </div>
                              <div className="d-flex g-3 justify-content-end">
                                <input
                                  type="search"
                                  placeholder="Search"
                                  value={searchText}
                                  className="form-control"
                                  onChange={(e) => setSearchText(e.target.value)}
                                  style={{ width: "350px" }}
                                />
                                <button
                                  onClick={offerManagment}
                                  type="button"
                                  className="btn btn-primary mx-2"
                                >
                                  Search
                                </button>

                                {selectedOffers.length > 0 && (
                                     <Button
                                     color="primary"
                                     onClick={() => selectRow(selectedOffers)}
                                   >
                                     Confirm Selection
                                   </Button>
                                )}                               


                              </div>
                            </Col>

                           

                            <Col className="col-lg-12">
                              <BootstrapTable
                                onTableChange={handleTableChange}
                                remote
                                classes="table-striped"
                                selectRow={selectRowProp}
                                {...props.baseProps}
                                {...paginationTableProps}
                              />
                            </Col>

                           
                          </Row>
                        )}
                      </ToolkitProvider>

                      <Row>
                        <Col className="col-lg-12 text-end">
                          <div className="text-md-right">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </PaginationProvider>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

const OfferModal = ({
  show,
  closeOfferModal,
  selectRow,
  venueId,
  categoryId,
  published = false,
}) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Offer List
        </h5>
        <button
          onClick={closeOfferModal}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {/* Pass down the selectRow callback which now receives an array of offers */}
        <TableComponent
          selectRow={selectRow}
          venueId={venueId}
          categoryId={categoryId}
          published={published}
        />
      </div>
    </Modal>
  );
};

export default OfferModal;
