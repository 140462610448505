import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import { deleteConfirmationWords } from "../../components/Common/Utils.js";
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FilterModal from "../../components/Modal/FilterModal";
// import CategoryDragModal from "./CategoryDragModal.js";

import {
  postRequest,
  deleteRequest,
  putRequestForm,
  convertTimeToFormattedString
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { set } from "lodash";

const filterObj = {
  title: "",
  description: "",
  capacity: "",
  venueName: "",  
  status: "",
  createdAt: "",
};

const VenueTableList = (props) => {
  const  permissions  = props.permissions || { permissions: { create: false, update: false, delete: false, show: false } };
  const [modal_center, setmodal_center] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [categorydata, setcategorydata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);
  const [sort, setsort] = React.useState("order");
  const [sortOrder, setsortOrder] = React.useState("asc");
  const [showOrder, setShowOrder] = useState(false);
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(20);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [categorydataAll, setcategorydataAll] = useState([]);
  const [filter, setFilter] = useState({ ...filterObj });
  const [model, setModel] = useState(false);

  // useEffect(() => {
  //   categoryManagment();
  // }, [currentPage]);

  // useEffect(() => {
  //   categoryManagment();
  // }, [sizePerPageOrg, sort, sortOrder]);


  // useEffect(() => {
  //   if (resetSearch) {
  //     setCurrentPage(1);
  //     categoryManagment(true);
  //     setResetSearch(false);
  //     return;
  //   }

  //   categoryManagment();
  // }, [currentPage, sort, sortOrder, sizePerPageOrg, resetSearch]);


  useEffect(() => {
    categoryManagment();
  }, [currentPage]);

  useEffect(() => {
    if(currentPage === 1){
      categoryManagment();
    }else{
      if (sizePerPageOrg || sort || sortOrder) {
        setCurrentPage(1);
      }
    }
  }, [sizePerPageOrg, sort, sortOrder]);


  useEffect(() => {
    if (!resetSearch) return;
    if (currentPage === 1) {;
      categoryManagment([], true);      
    }else{
      setCurrentPage(1);
  }}, [currentPage, sort, sortOrder, sizePerPageOrg, resetSearch]);

 



  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? true : false;

    const formData = new FormData();
    formData.append("categoryId", actId);
    formData.append("status", status);

    const res = await putRequestForm("venue/category/update", formData);

    if (res.status == 1) {
      toast.success("Category Status updated Successfully");
      categoryManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];
    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        if (key === "startColor") {
          var filterObject = {
            "color.startColor": filter["startColor"],
          };
        } else if (key === "endColor") {
          var filterObject = {
            "color.endColor": filter["endColor"],
          };
        } else {
          var filterObject = {
            [key]: filter[key]?.trim(),
          };
        }

        if (
          key === "title" ||
          key === "description" ||         
          key === "startColor" ||
          key === "endColor"
        ) {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        }  else if (key === "capacity") {
          filterObject.type = "eq";
        }
        else if (key === "venueName") {
          filterObject.type = "regex";
        }
        else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
   
    toggleModel();
    categoryManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    categoryManagment();
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function startColorFormatter(cellContent, row, i) {
    return (
      <>
        <div style={{ backgroundColor: row?.color?.startColor }}>
          <p style={{ color: "#fff" }}>{row?.color?.startColor}</p>
        </div>
      </>
    );
  }
  function endColorFormatter(cellContent, row, i) {
    return (
      <>
        <div style={{ backgroundColor: row?.color?.endColor }}>
          <p style={{ color: "#fff" }}>{row?.color?.endColor}</p>
        </div>
      </>
    );
  }
  function dateFormatter(cellContent, row) {
    return (
      <div>
        {convertTimeToFormattedString(row.createdAt)}        
      </div>
    );
  }

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status === true ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange(0, row?._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange(1, row?._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }

  
  function ImageFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.images[0]}
          onClick={() => showLightBox(row.images[0])}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, rowIndex) {
    let firstItemIndex;

      //console.log("totalPage", sortOrder) 

      if (sortOrder === 'desc') {
        // Calculate the first item index on the current page in descending order
        firstItemIndex = totalPage - ((currentPage - 1) * sizePerPageOrg);
      } else {
        // Calculate the first item index on the current page in ascending order
        firstItemIndex = ((currentPage - 1) * sizePerPageOrg) + 1;
      }

      // Adjust the rowIndex to calculate the actual item index
      const itemIndex = sortOrder === 'desc' ? firstItemIndex - rowIndex : firstItemIndex + rowIndex;


      return <>{itemIndex}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Title</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.title}
          onChange={(e) => updateFilter("title", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

<div className="row mb-4" key="title">
<label className="col-sm-3 col-form-label">Venue Name</label>
<div className="col-sm-6">
  <input
    type="text"
    value={filter.venueName}
    onChange={(e) => updateFilter("venueName", e.target.value)}
    name="title"
    className="form-control"
  />
</div>
</div>,   

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Description</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.description}
          onChange={(e) => updateFilter("description", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,   

      <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Capacity</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.capacity}
          onChange={(e) => updateFilter("capacity", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
      </div>,  



    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,    

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: true,
    },

    {
      dataField: "venueName",
      text: "Venue Name",
      sort: true,
    },

    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "capacity",
      text: "Capacity",
      sort: true,
    },
   

    {
      dataField: "image",
      text: "Image",
      formatter: ImageFormatter,
      sort: false,
    },

    // {
    //   dataField: "status",
    //   text: "Status",
    //   formatter: activeFormatter,
    //   sort: false,
    // },
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
      
    },
    {
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText(""); 
  };
 


  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div >
       
          {permissions.update && (
          <Tippy content="Edit">
          <button type="button" className=" btn btn-info  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/update-venue-table/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i class="fal fa-pencil fs-5 text-light"></i>
            </Link>{" "}
          </button>
          </Tippy>
          )}
          {permissions.delete && (
          <Tippy content="Delete">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          </Tippy>
          )}
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const categoryManagment = async (filterArgs = []) => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText.trim();
      }
    }
   try{
    const response = await postRequest(`venue/table/list`, payload);
    const data = response.data;
    if(resetSearch){
      setResetSearch(false);
    }
    setTotalPage(data.count);
    setCurrentFPage(data.page);
    setcategorydata(data.list);
   }catch(error){
    console.error("Failed to fetch data:", error);
    }finally{
    setLoader(false);
    }
  };

  const categoryManagmentAll = async (filterArgs = []) => {
    setLoader(true);
    const payload = {
      limit: 100000,
      page: 1,
      sortField: "order",
      sortOrder: "asc",
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }
    try {
      const response = await postRequest(`venue/table/list`, payload);
      const data = response.data;
      setcategorydataAll(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    categoryManagmentAll();
  }, []);

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteCategory(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteCategory = async (id) => {
    const response = await postRequest(`venue/table/remove`, {
      tableId: id,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      categoryManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await postRequest("venue/table/remove", { ids: ids });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      categoryManagment();
      setselectedItems([]);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox", 
    clickToSelect: false, 
    style: { background: "#c8e6c9" }, 
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };

  const updateCategoryData = async (data) => {
    setcategorydataAll(data);
    setShowOrder(false);
    const response = await postRequest(`venue/category/update/order`, {
      data: data,
    });

    if(response.status == 1){
      toast.success("Order updated successfully");
      // wait 3 seconds before reloading the page
      setTimeout(() => {
        categoryManagment();
      }, 1000);

    }else{
      toast.error("Something went wrong");
    }
    
  };

  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <DndProvider backend={HTML5Backend}>
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Venue Table Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Venue Table Management</li>:{" "}
                <li className="breadcrumb-item">Venue Table List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
          {/* <Button
                onClick={() => setShowOrder(true)}
                className="btn btn-info"
                style={{ marginRight: "10px" }}
              >
                <i className="fas fa-grip-vertical"></i> Order
              </Button> */}
              
              {permissions.create && 
              (  
            <Link to="/create-venue-table">
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link>
            )}
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={categorydata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>

                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "25%" }}
                                  >                                   
                                    <option value="title">Title</option>
                                    <option value="venueName">Venue Name</option>
                                    <option value="description">Description</option>
                                    <option value="capacity">Capacity</option>
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                    
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                     value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "20%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger mx-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        categoryManagment();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger"
                                    >
                                      Reset
                                    </button>
                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info mx-2"
                                    >
                                      Filter
                                    </button>
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    noDataIndication={() => <div>No data available</div>}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    classes="table-custom-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                                { totalPage > sizePerPageOrg ? (
                                   <p style={{ marginTop: "12px", fontSize: "1rem" }}> Showing <b>{ sizePerPageOrg }</b> From <b>{totalPage}</b> </p> 
                                ) : (
                                  <p style={{ marginTop: "12px", fontSize: "1rem" }}> Showing <b>{ totalPage }</b> From <b>{totalPage}</b> </p>
                                ) }

                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        </DndProvider>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
         {/* <CategoryDragModal
          show={showOrder}
          closeModal={() => setShowOrder(false)}
          data={categorydataAll}
          setData={updateCategoryData}
        /> */}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default VenueTableList;
