import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  postRequest,
  customValidator,
} from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import StoryPackageModal from "components/Modal/StoryPackageModal.js";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import OfferModal from "components/Modal/OfferModalOrg.js";
import FileUpload from "components/Common/FileUpload.js";


import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => (
  <i className="fas fa-grip-lines" style={{ cursor: 'move' }}></i>
));

const SortableItem = SortableElement(({ item, deletePackage }) => (
  <tr>
     <td>
      {/* This is where the drag handle is rendered */}
      <DragHandle />
    </td>
    <td>{item.mediaType}</td>
    <td>{item.contentType}</td>    
    <td>
      {item.mediaType === 'video' && item.mediaUrl ? (
        <video width="100" controls onClick={() => showLightBox(item.mediaUrl)}>
          <source src={item.mediaUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : item.mediaUrl ? (
        <img
          src={item.mediaUrl}
          style={{ width: '100px' }}
          alt="Media Content"
          onClick={() => showLightBox(item.mediaUrl)}
        />
      ) : (
        <span>Video</span>
      )}
    </td>
    <td>{item.buttonText}</td>
    <td>     
      <i
        className="fas fa-trash-alt"
        style={{
          marginLeft: '10px',
          cursor: 'pointer',
          color: 'red',
        }}
        onClick={() => deletePackage()}
      ></i>
    </td>
   
  </tr>
));


const SortableBody = SortableContainer(({ items, deletePackage }) => {
  return (
    <tbody>
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item._id}`}
          index={index}
          item={item}         
          deletePackage={() => deletePackage(index)}
        />
      ))}
    </tbody>
  );
});
export default function CreateStory() {
  const location = useLocation();
  const state = location.state?.row;

  const [storyData, setStoryData] = useState({
    buttonText: "",
    venueId: "",
    mediaType: "",
    contentType: "",
    selectedImage: "",
  });
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [offerImage, setOfferImage] = useState("");
  const [venueList, setVenueList] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [offerModel, setOfferModel] = useState(false);
  const [offerList, setOfferList] = useState([]);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [packages, setPackages] = useState([]);
  const [openPackage, setOpenPackage] = useState(false);

  const [model, setModel] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  let history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    if (!state) return;
    setVenueId(state?.venueId);
    setVenueText(state?.venueName);
    setVenueImage(state?.venueLogo);
  }, [state]);

  useEffect(() => {
    if (!selectedImage) return;

    // check file type is image or video

    if (storyData?.mediaType == "video") {
      if (selectedImage?.type?.split("/")[0] != "video") {
        toast.error("Please select video file");
        return;
      }
    } else {
      if (selectedImage?.type?.split("/")[0] != "image") {
        toast.error("Please select image file");
        return;
      }
    }

    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);



  const onSortEnd = ({ oldIndex, newIndex }) => {
    setPackages((prevPackages) =>
      arrayMoveImmutable(prevPackages, oldIndex, newIndex)
    );
  };

  useEffect(() => {
    fetchList();
    fetchOfferList();
  }, []);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      setVenueList(response.data.list);
    }
    setLoading(false);
  };

  const fetchOfferList = async () => {
    setLoading(true);
    const response = await postRequest("venue/offer/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setOfferList(response.data.list);
    }
    setLoading(false);
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const selectOfferRow = (id, name, row) => {
    setOfferId(id);
    setOfferText(name);
    setOfferImage(row.image);
    closeOfferModal();
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const closeModal = () => {
    setModel(false);
  };

  const closePackageModal = () => {
    setOpenPackage(false);
  };

  
  const addPackage = () => {
    setOpenPackage(true);
    // setPackages([
    //   ...packages,
    //   { title: "", amount: "", discount: "", qty: "", isFeatured: false },
    // ]);
  };

  const addPackageData = (data) => {

    console.log("hello data", data);


    setPackages([...packages, data]);

    setOpenPackage(false);
  };
  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);
    setPackages(newPackages);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModal();
  };

  const Createstory = async (e) => {
    e.preventDefault();

    storyData.venueId = venueId;
    storyData.selectedImage = selectedImage;

    const validator = customValidator(
      [
        {
          field: "venueId",
          errorMsg: "Please select venue",
        },
        // {
        //   field: "mediaType",
        //   errorMsg: "Please select media type",
        // },
        // {
        //   field: "contentType",
        //   errorMsg: "Please select content type",
        // },
        // {
        //   field: "selectedImage",
        //   errorMsg: "please choose file",
        // },
        // {
        //   field: "buttonText",
        //   errorMsg: "Please enter buttonText",
        // },
      ],
      storyData
    );

    if (!validator.success) {
      toast.error(validator.message);
      return;
    }

    // if (storyData?.contentType === "offer") {
    //   if (!offerId) {
    //     toast.error("Please select offer");
    //     return;
    //   }
    // }

    // if (storyData?.contentType === "web") {
    //   if (!storyData?.webUrl) {
    //     toast.error("Please enter web url");
    //     return;
    //   }
    // }

    setStartLoader(true);
    setLoading(true);

    const formData = new FormData();
    formData.append("venueId", venueId);
    formData.append("image", selectedImage);
    formData.append("mediaType", storyData.mediaType);
    formData.append("contentType", storyData.contentType);
    formData.append("buttonText", storyData.buttonText);
    formData.append("isPromoted", storyData.isPromoted ? true : false);

    const payload = {
      venueId: venueId,
      items: packages,
    }
    

    let url = "homeblock/story/create/new";

    // if (storyData.mediaType == "video") {
    //   url = "homeblock/story/create/video";
    // }

    const response = await postRequest(url, payload);
    setStartLoader(false);
    setLoading(false);

    if (response.status == 1) {
      toast.success("Story Created");
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...storyData };
    list[index] = value;
    setStoryData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Assign Venue Story</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Story</li>:{" "}
              <li className="breadcrumb-item">Assign Story</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Assign Story </h4>
                <Form onSubmit={Createstory}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Venue
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            {venueText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={venueImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={venueText}
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Is Promoted
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              defaultChecked={false}                      
                              onChange={(e) =>
                                updateValue("isPromoted", e.target.checked)
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Create Story
                          </label>
                          <div className="col-sm-6">
                            <button
                              type="button"
                              onClick={addPackage}
                              className="btn btn-primary mb-3"
                            >
                              Add Stories
                            </button>


                            <table className="table">
                              <thead>
                                <tr>  
                                  <th>-</th>                                
                                  <th>Media Type</th>
                                  <th>Content Type</th>
                                  <th>Image</th>                                 
                                  <th>Button Text</th>   
                                  <th>Action</th>                               
                                </tr>
                              </thead>
                              <SortableBody 
                              items={packages} 
                              onSortEnd={onSortEnd} 
                              lockAxis="y" 
                              useDragHandle                               
                              deletePackage={deletePackage} // Pass the delete function                              
                              />
                            </table> 
                            
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Media Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={storyData?.mediaType}
                              onChange={(e) =>
                                updateValue("mediaType", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              <option value="photo">Photo</option>
                              <option value="video">Video</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Content Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={storyData?.contentType}
                              onChange={(e) =>
                                updateValue("contentType", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              <option value="offer">Offer</option>
                              <option value="web">Web</option>
                            </select>
                          </div>
                        </div>
                        {storyData?.contentType === "web" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Enter Web URL
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="url"
                                onChange={(e) =>
                                  updateValue("webUrl", e.target.value)
                                }
                                value={storyData?.webUrl}
                                name="buttonText"
                                className="form-control"
                                id="horizontal-password-input"
                              />
                            </div>
                          </div>
                        )}
                        {storyData?.contentType === "offer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Offer
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleOfferModel()}
                            >
                              {offerText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Offer
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={offerImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Offer
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            File
                          </label>
                          <div className="col-sm-6">
                            {storyData?.mediaType == "video" ? (
                              <input
                                type="file"
                                accept="video/*"
                                onChange={(e) =>
                                  setSelectedImage(e.target.files[0])
                                }
                                name="mediaUrl"
                                className="form-control"
                                id="horizontal-password-input"
                              />
                            ) : (
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  setSelectedImage(e.target.files[0])
                                }
                                name="mediaUrl"
                                className="form-control"
                                id="horizontal-password-input"
                              />
                            )}

                            <br />
                            {storyData?.mediaType == "video"
                              ? null
                              : previewImage && (
                                  <img
                                    src={previewImage}
                                    onClick={() => showLightBox(previewImage)}
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Button Text
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("buttonText", e.target.value)
                              }
                              value={storyData?.buttonText}
                              name="buttonText"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div> */}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

      {/* modal */}
      <OfferModal
        show={offerModel}
        closeOfferModal={closeOfferModal}
        selectRow={selectOfferRow}
        venueId={venueId}
      />
      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <StoryPackageModal
        show={openPackage}
        closeModal={closePackageModal}
        addData={addPackageData}
      />
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
