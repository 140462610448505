import React from "react";
import { Redirect } from "react-router-dom";
//Email
// Authentication related pages
import Login from "../pages/Authentication/Login";
import VenueLoginToken from "../pages/Authentication/venueToken";
import EventLogin from "../pages/Authentication/EventLogin";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
import VenueDashboard from "pages/VenueDashboard/index";
import CategoryDashboard from "pages/CategoryDashboard/index";




import UserDashboard from "pages/UserDashboard/index";
import OfferDashboard from "pages/OfferDashboard/index";
import AllOffersDashboard from "pages/AllOffersDashboard/index";
import AllVenuesDashboard from "pages/AllVenuesDashboard/index";
import DashboardCommission from "../pages/DashboardCommission/index";
import DashboardSubscription from "../pages/DashboardSubscription/index";
import UserManagmentlist from "pages/UserManagement/UserManagmentlist";
import Updateuserdata from "pages/UserManagement/Updateuserdata";
import Userdetails from "pages/UserManagement/Userdetails.js";
import RoleAccessManagement from "pages/RoleAccessManagement/UserManagmentlist";
import CreateRoleAccess from "pages/RoleAccessManagement/CreateUser";
import UpdateRoleAccess from "pages/RoleAccessManagement/Updateuserdata";

import PollManagmentlist from "pages/PollManagement/PollManagementlist";
import CreatePoll from "pages/PollManagement/PollCreate";
import UpdatePoll from "pages/PollManagement/PollUpdate";

import EventForgotPassword from "pages/Authentication/EventForgotPassword";
import AdminForgotPassword from "pages/Authentication/AdminForgotPassword";


import BusinessManagmentlist from "pages/BusinessManagement/BusinessManagmentlist";
import OnBoardingManagementlist from "pages/OnBoardingUser/OnBoardingManagmentlist";
import OnBoardingVenuelist from "pages/OnBoardingVenue/OnBroadingVenuelist";
import OnBoardingVenueView from "pages/OnBoardingVenue/OnboardingVenue";
import BusinessDeletelist from "pages/BusinessManagement/BusinessDeletelist";
import CreateBusiness from "pages/BusinessManagement/CreateBusiness";
import UpdateBusiness from "pages/BusinessManagement/UpdateBusiness";
import VenueManagmentlist from "pages/VenueManagement/VenueManagmentlist";


import VenueReservationlist from 'pages/VenueManagement/VenueReservation';
import VenueReservationDetailPage from 'pages/VenueManagement/VenueReservationDetailPage';
import VAVenueReservationDetailPage from 'pages/VenueP/VenueManagement/VenueReservationDetailPage';
import VAReservationList from 'pages/VenueP/VenueManagement/VenueReservation';
import VAVenueTableList from "pages/VenueP/VenueTable/VenueTableList";
 import VACreateVenueTable from "pages/VenueP/VenueTable/CreateVenueTable";
 import VAUpdateVenueTable from "pages/VenueP/VenueTable/UpdateVenueTable";

import VenueOutingManagementList from "pages/VenueManagement/VenueOutingManagementList";
import VenueDeletelist from "pages/VenueManagement/VenueDeletelist";
import CreateVenue from "pages/VenueManagement/CreateVenue";
import UpdateVenue from "pages/VenueManagement/UpdateVenue";
import MenuManagmentlist from "pages/MenuManagement/MenuManagmentlist";
import CreateMenu from "pages/MenuManagement/CreateMenu";
import UpdateMenu from "pages/MenuManagement/UpdateMenu";
import RatingManagmentlist from "pages/RatingManagement/RatingManagmentlist";
import FollowManagementlist from "pages/FollowManagement/FollowManagmentlist";
import UpdateRating from "pages/RatingManagement/UpdateRating";
import ThemeManagmentlist from "pages/ThemeManagement/ThemeManagmentlist";
import CreateTheme from "pages/ThemeManagement/CreateTheme";
import UpdateTheme from "pages/ThemeManagement/UpdateTheme";
import CuisineManagmentlist from "pages/CuisineManagement/CuisineManagmentlist";
import CreateCuisine from "pages/CuisineManagement/CreateCuisine";
import UpdateCuisine from "pages/CuisineManagement/UpdateCuisine";
import CreateBusinessTheme from "pages/BusinessManagement/CreateBusinessTheme";
import CreateVenueTheme from "pages/VenueManagement/CreateVenueTheme";
import CreateVenueCuisine from "pages/VenueManagement/CreateVenueCuisine";
import CreateStory from "pages/StoryManagement/CreateStory";
import UpdateStory from "pages/StoryManagement/UpdateStory";
import StoryManagmentlist from "pages/StoryManagement/StoryManagmentlist";
import PageManagementlist from "pages/AppManagement/PageManagementlist";
import CreatePage from "pages/AppManagement/CreatePage";
import UpdatePage from "pages/AppManagement/UpdatePage";

import SevenNightManagementlist from "pages/7NManagement/PageManagementlist";
import CreateSevenNight from "pages/7NManagement/CreatePage";
import UpdateSevenNight from "pages/7NManagement/UpdatePage";

import ConvoManagmentlist from "pages/Conversation/ConvoManagmentlist";
import ChatWindow from "pages/Conversation/ChatWindow";

import VenueStoryManagementlist from "pages/VenueManagement/VenueStoryManagmentlist";
import CreateVenueStory from "pages/VenueManagement/CreateVenueStory";
import GalleryManagementlist from "pages/GalleryManagement/GalleryManagmentlist";
import CreateGallery from "pages/GalleryManagement/CreateGallery";
import CreateVenueMusic from "pages/VenueManagement/CreateVenueMusic";
import UpdateCredential from "pages/VenueManagement/UpdateCredential";
import CreateVenueFeature from "pages/VenueManagement/CreateVenueFeature";
import CustomComponentManagementlist from "pages/CustomComponentManagement/CustomCompoManagmentlist";
import CreateCustomComponent from "pages/CustomComponentManagement/CreateCustomComponent";
import InviteListTable from "pages/EventManagement/InviteListTable";
import CreateVenueAssignMusic from "pages/VenueManagement/CreateVenueAssignMusic";
import CreateVenueAssignFeature from "pages/VenueManagement/CreateVenueAssignFeature";
import CreateVenueAssignTheme from "pages/VenueManagement/CreateVenueAssignTheme";
import CreateVenueAssignCuisine from "pages/VenueManagement/CreateVenueAssignCuisine";
import VideoManagementlist from "pages/VideoManagement/VideoManagmentlist";
import CreateVideo from "pages/VideoManagement/CreateVideo";
import UpdateVideo from "pages/VideoManagement/UpdateVideo";
import HomeBlockManagementlist from "pages/HomeBlockManagement/HomeBlockManagmentlist";
import CreateHomeBlock from "pages/HomeBlockManagement/CreateHomeBlock";
import UpdateHomeBlock from "pages/HomeBlockManagement/UpdateHomeBlock";
import SearchBlockManagementlist from "pages/SearchBlockManagement/HomeBlockManagmentlist";
import CreateSearchBlock from "pages/SearchBlockManagement/CreateHomeBlock";
import UpdateSearchBlock from "pages/SearchBlockManagement/UpdateHomeBlock";
import ExploreBlockManagementlist from "pages/ExploreBlockManagement/HomeBlockManagmentlist";
import CreateExploreBlock from "pages/ExploreBlockManagement/CreateHomeBlock";
import UpdateExploreBlock from "pages/ExploreBlockManagement/UpdateHomeBlock";
import OfferManagementlist from "pages/OfferManagement/OfferManagmentlist";
import OfferDeletelist from "pages/OfferManagement/OfferDeletelist";
import OfferNotificationlist from "pages/OfferManagement/OfferNotificationlist";
import CreateOffer from "pages/OfferManagement/CreateOffer";
import UpdateOffer from "pages/OfferManagement/UpdateOffer";
import CountryManagmentList from "pages/CountryManagement/CountryManagmentlist";
import CreateCountry from "pages/CountryManagement/CreateCountry";
import UpdateCountry from "pages/CountryManagement/UpdateCountry";
import RegionManagmentList from "pages/RegionManagement/RegionManagmentlist";
import CreateRegion from "pages/RegionManagement/CreateRegion";
import UpdateRegion from "pages/RegionManagement/UpdateRegion";
import AdminPollSettings from "pages/AppManagement/AdminSettings";


import PublicHoliday from "pages/OfferManagement/PublicHolidayManagmentlist";
import CreatePublicHoliday from "pages/OfferManagement/CreateHoliday";
import UpdatePublicHoliday from "pages/OfferManagement/UpdateHoliday";

import TimeManagementlist from "pages/TimeManagement/TimeManagmentlist";
import CreateTime from "pages/TimeManagement/CreateTime";
import UpdateTime from "pages/TimeManagement/UpdateTime";

import HomeBannerlist from "pages/HomeBlockBannerManagement/HomeBannerlist";
import CreateHomeBanner from "pages/HomeBlockBannerManagement/CreateHomeBanner";
import UpdateHomeBanner from "pages/HomeBlockBannerManagement/UpdateHomeBanner";

import DiscountTypeManagementlist from "pages/DiscountManagement/DiscountTypeManagmentlist";
import CreateDiscountType from "pages/DiscountManagement/CreateDiscountType";
import UpdateDiscountType from "pages/DiscountManagement/UpdateDiscountType";
import DiscountManagementlist from "pages/DiscountManagement/DiscountManagmentlist";
import CreateDiscount from "pages/DiscountManagement/CreateDiscount";
import UpdateDiscount from "pages/DiscountManagement/UpdateDiscount";
import BannerTypeManagementlist from "pages/BannerManagement/BannerTypeManagmentlist";
import CreateBannerType from "pages/BannerManagement/CreateBannerType";
import UpdateBannerType from "pages/BannerManagement/UpdateBannerType";
import BannerManagementlist from "pages/BannerManagement/BannerManagmentlist";
import CreateBanner from "pages/BannerManagement/CreateBanner";
import UpdateBanner from "pages/BannerManagement/UpdateBanner";
import AddReply from "pages/RatingManagement/AddReply";
import SpecialOfferManagementlist from "pages/OfferManagement/SpecialOfferManagmentlist";
import SpecialOfferDeletelist from "pages/OfferManagement/SpecialOfferDeletelist";
import SpecialOfferClaimManagementlist from "pages/OfferManagement/SpecialOfferClaimManagmentlist";
import SpecialOfferClaimStatementlist from "pages/OfferManagement/SpecialOfferClaimStatementlist";
import VoucherClaimStatementlist from "pages/VoucherManagement/VoucherClaimStatementlist";
import ActivityBannerManagementlist from "pages/ActivityBannerManagement/ActivityBannerManagmentlist";
import ActivityFollowManagementlist from "pages/ActivityFollowManagement/ActivityFollowManagmentlist";
import UpdateActivityRating from "pages/ActivityRatingManagement/UpdateActivityRating";
import CreateActivityBanner from "pages/ActivityBannerManagement/CreateActivityBanner";

import FilterCategorylist from "pages/FilterCategoryManagement/FilterCategorylist";
import CreateFilter from "pages/FilterCategoryManagement/CreateFilter";
import UpdateFilter from "pages/FilterCategoryManagement/UpdateFilter";

import MusicList from "pages/Music/MusicList";
import CreateMusic from "pages/Music/CreateMusic";
import UpdateMusic from "pages/Music/UpdateMusic";
import FeatureList from "pages/Features/FeatureList";
import CreateFeature from "pages/Features/CreateFeature";
import UpdateFeature from "pages/Features/UpdateFeature";
import CheckinList from "pages/VenueCheckinManagement/CheckinList";
import BlockSizeList from "pages/HomeBlockSize/BlockSizeList";
import CreateBlockSize from "pages/HomeBlockSize/CreateBlockSize";
import UpdateBlockSize from "pages/HomeBlockSize/UpdateBlockSize";
import CategoryList from "pages/CategoryManagement/CategoryList";
import CreateCategory from "pages/CategoryManagement/CreateCategory";
import UpdateCategory from "pages/CategoryManagement/UpdateCategory";
import VenueTableList from "pages/VenueTable/VenueTableList";
import CreateVenueTable from "pages/VenueTable/CreateVenueTable";
import UpdateVenueTable from "pages/VenueTable/UpdateVenueTable";
import VerifyList from "pages/VenueVerifyManagement/VerifyList";
import CreateVerify from "pages/VenueVerifyManagement/CreateVerify";
import UpdateVerify from "pages/VenueVerifyManagement/UpdateVerify";
import BadgeList from "pages/VenueBadgeManagement/BadgeList";
import CreateBadge from "pages/VenueBadgeManagement/CreateBadge";
import UpdateBadge from "pages/VenueBadgeManagement/UpdateBadge";
import Categorydetails from "pages/CategoryManagement/Categorydetails";
import ChartComponets from "pages/DashboardChart/ChartComponent";
import SpecialOfferlist from "pages/SpecialOffer/SpecialOffer";
import CreateSpecialOffer from "pages/SpecialOffer/CreateSpecialOffer";
import UpdateSpecialOffer from "pages/SpecialOffer/UpdateSpecialOffer";
import CreateClaim from "pages/OfferManagement/CreateClaim";
import Homeblockdetails from "pages/HomeBlockManagement/Homeblockdetails";
import homeBlockPreview from "pages/HomeBlockManagement/HomeBlockPreview";
import Searchblockdetails from "pages/SearchBlockManagement/Homeblockdetails";
import searchBlockPreview from "pages/SearchBlockManagement/HomeBlockPreview";
import Exploreblockdetails from "pages/ExploreBlockManagement/Homeblockdetails";
import exploreBlockPreview from "pages/ExploreBlockManagement/HomeBlockPreview";

import PackageList from "pages/PackageManagement/PackageList";
import CreatePackage from "pages/PackageManagement/CreatePackage";
import UpdatePackage from "pages/PackageManagement/UpdatePackage";

import VenuePackageList from "pages/VenuePackage/PackageList";
import CreateVenuePackage from "pages/VenuePackage/CreatePackage";
import UpdateVenuePackage from "pages/VenuePackage/UpdatePackage";

import AddSubscription from 'pages/VenueSubscription/AddSubscription'
import CreateSubscription from 'pages/VenueSubscription/CreateSubscription'
import UpdateVenueSubscription from 'pages/VenueSubscription/UpdateVenueSubscripiton'
import VenueSubscriptionOrders from "pages/VenueSubscription/VenueSubscriptionOrders";
import VenueSubscriptionCommission from "pages/VenueSubscription/VenueSubscriptionCommission";
import OrderList from "pages/OrderManagement/OrderManagmentlist";
import ActivityOrderManagementlist from "pages/ActivityOrderManagement/ActivityOrderManagmentlist";
import OfferOrderManagementlist from "pages/OfferOrderManagement/OfferOrderManagmentlist";
import OfferCommissionManagementlist from "pages/OfferOrderManagement/OfferCommissionManagmentlist";
import DealOrderManagementlist from "pages/DealOrderManagement/DealOrderManagmentlist";

import Venuedetails from "pages/VenueManagement/Venuedetails";
import VenueOutingdetails from "pages/VenueManagement/VenueOutingdetails";
import NotificationList from "pages/Notification/NotificationList";
import NotificationPendingList from "pages/Notification/NotificationPendingList";
import UpdateNotification from "pages/Notification/UpdateNotification";
import CreateNotification from "pages/Notification/CreateNotification";
import Notificationdetails from "pages/Notification/NotificationDetails";
import NotificationApprove from "pages/Notification/NotificationApprove";
import UpdateCustomComponent from "pages/CustomComponentManagement/UpdateCustomComponent";

// import YachtClublist from "pages/YachtClub/YachtClubList";
// import CreateYachtClub from "pages/YachtClub/CreateYachtClub";
// import UpdateYachtClub from "pages/YachtClub/UpdateYachtClub";
// import YachtClubdetails from "pages/YachtClub/Yachtclubdetails";
// import YachtManagementlist from "pages/YachtManagement/YachtManagementList";
// import CreateYacht from "pages/YachtManagement/CreateYacht";
// import UpdateYacht from "pages/YachtManagement/UpdateYacht";
// import YachtGalleryManagementlist from "pages/YachtGallery/YachtGalleryManagement";
// import CreateYachtGallery from "pages/YachtGallery/CreateYachtGallery";
// import YachtRatingManagementlist from "pages/YachtRating/YachtRatingManagementList";
// import UpdateYachtRating from "pages/YachtRating/UpdateYachtRating";
// import AddYachtRatingReply from "pages/YachtRating/AddYachtRatingReply";
// import YachtFollowManagementlist from "pages/yachtFollow/YachtFollowManagmentlist";

import EventOrganizerlist from "pages/EventOrganizer/EventOrganizerlist";
import CreateEventOrganizerData from "pages/EventOrganizer/CreateEventOrganizer";
import UpdateEventOrganizerData from "pages/EventOrganizer/UpdateEventOrganizer";
import Eventdetails from "pages/EventManagement/Eventdetails";
import Organizerdetails from "pages/EventOrganizer/Organizerdetails";
import EventFollowManagementlist from "pages/EventFollowManagement/EventFollowManagmentlist";
import EventOrderManagmentlist from "pages/EventOrderManagement/EventOrderManagmentlist";

import Eventlist from "pages/EventManagement/Eventlist";
import EventDeletelist from "pages/EventManagement/EventDeletelist";
import CreateEvent from "pages/EventManagement/CreateEvent";
import UpdateEvent from "pages/EventManagement/UpdateEvent";
import ActivityProviderlist from "pages/ActivityProvider/ActivityProviderlist";
import CreateActivityProvider from "pages/ActivityProvider/CreateActivityProvider";
import UpdateActivityProvider from "pages/ActivityProvider/UpdateActivityProvider";

import EventGalleryManagementlist from "pages/EventGalleryManagement/EventGalleryManagementlist";
import CreateEventGallery from "pages/EventGalleryManagement/CreateEventGallery";

import EventRatingManagementlist from "pages/EventRatingManagement/EventRatingManagementList";
import AddEventRatingReply from "pages/EventRatingManagement/AddEventRatingReply";
import UpdateEventRating from "pages/EventRatingManagement/UpdateEventRating";
import EventOrganizerdetails from "pages/EventOrganizerDetail/EventOrganizerdetails";
import UpdateOrganizerProfile from "pages/EventOrganizerDetail/UpdateOrganizerProfile";
import BucketList from "pages/UserManagement/BucketList";
import BucketManagementList from "pages/BucketManagement/BucketManagementList";
import OutingManagementList from "pages/OutingManagement/OutingManagementList";
import ActivityTypeList from "pages/ActivityType/ActivityTypeList";
import CreateActivityType from "pages/ActivityType/ActivityTypeCreate";
import UpdateActivityType from "pages/ActivityType/ActivityTypeUpdate";
import ActivityManagementlist from "pages/ActivityManagement/ActivityManagementList";
import ActivityDeletelist from "pages/ActivityManagement/ActivityDeleteList";
import IconList from "pages/IconManagement/IconList";
import CreateIcon from "pages/IconManagement/CreateIcon";
import ActivityRatingManagementlist from "pages/ActivityRatingManagement/ActivityRatingManagementList";
import CreateActivity from "pages/ActivityManagement/createActivity";
import Activitydetails from "pages/ActivityManagement/Activitydetails";
import UpdateActivity from "pages/ActivityManagement/UpdateActivity";
import SubscriptionManagementlist from "pages/SubscriptionManagement/SubsriptionManagmentlist";
import CustomSubscriptionManagementlist from "pages/CustomSubscriptionManagement/CustomSubsriptionManagmentlist";
import CreateCustomSubscribe from "pages/CustomSubscriptionManagement/CreateCustomSubscribe";
import UpdateCustomSubscribe from "pages/CustomSubscriptionManagement/UpdateCustomSubscribe";
import CreateUser from "pages/UserManagement/CreateUser";
import CloneNotification from "pages/Notification/CloneNotification";

import NotificationReadManagementlist from "pages/NotificationRead/NotificationReadManagmentlist";
import UpdateEventOrg from "pages/EventOrganizerDetail/eventOrgProfile";
import AdsManagementlist from "pages/ADsManagements/AdManagementList";
import AdminProfileUpdate from "pages/AdminManagement/adminProfile";
import AdminSetting from "pages/AdminManagement/adminSetting";

import CreateAd from "pages/ADsManagements/CreateAd";

import LogManagementList from "../pages/LogManagementList/LogManagementList";
import LogVenue from "../pages/LogManagementList/LogVenue";
import LogOffer from "../pages/LogManagementList/LogOffer";
import LogManagementListRole from "pages/RoleAccessManagement/LogManagementList";



import UpdateAd from "pages/ADsManagements/UpdateAd";
import CloneAd from "pages/ADsManagements/CloneAd";
import Adlogslist from "pages/ADsManagements/Adlogslist";
import AdGraphdetails from "pages/ADsManagements/AdGraphdetails";
import NotificationForm from "pages/In-appNotification/InAppNotificationCreate";
import BusinessLogin from "pages/Authentication/BusinessLogin";
import BusinessDetails from "pages/BusinessPortal/BusinessDetails";
import BusinessForgotPassword from "pages/Authentication/BusinessForgotPassword";
import ResetBusinessPassword from "pages/BusinessManagement/ResetPassword";
import ContactUsList from "pages/ContactUs/ContactUsList";
import AddMessage from "pages/ContactUs/ContactUsReply";
import ContactUsMessages from "pages/ContactUs/ContactUsMessages";
import VoucherList from "pages/VoucherManagement/VoucherManagementList";
import CreateVoucher from "pages/VoucherManagement/CreateVoucher";
import UpdateVoucher from "pages/VoucherManagement/UpdateVoucher";
import InAppNotificationlist from "pages/In-appNotification/InAppNotificationList";
import Graph from "pages/Dashboard/graph"
import VenueGraphdetails from "pages/VenueManagement/VenueGraphdetails";
import EventGraphdetails from "pages/EventManagement/EventGraphdetails";
import OfferGraphdetails from "pages/OfferManagement/OfferGraphdetails";
import Businessdetail from "pages/BusinessManagement/Businessdetail";
import DeleteAccountManagementlist from "pages/UserManagement/DeleteAccountManagmentlist";



import BADashboard from "../pages/BusinessP/Dashboard/index";
import BABusinessManagementlist from "pages/BusinessP/BusinessManagement/BusinessManagmentlist";
import BAUpdateBusiness from "pages/BusinessP/BusinessManagement/UpdateBusiness";
import BAResetBusinessPassword from "pages/BusinessManagement/ResetPassword";
import BABusinessdetails from "pages/BusinessP/BusinessManagement/Businessdetail";

import BAVenueManagmentlist from "pages/BusinessP/VenueManagement/VenueManagmentlist";
import BACreateVenue from "pages/BusinessP/VenueManagement/CreateVenue";
import BAUpdateVenue from "pages/BusinessP/VenueManagement/UpdateVenue";
import BAUpdateCredential from "pages/BusinessP/VenueManagement/UpdateCredential";
import BAVenuedetails from "pages/BusinessP/VenueManagement/Venuedetails";
import BAVenueOutingdetails from "pages/BusinessP/VenueManagement/VenueOutingdetails";
import BAGalleryManagementlist from "pages/BusinessP/GalleryManagement/GalleryManagmentlist";
import BACreateGallery from "pages/BusinessP/GalleryManagement/CreateGallery";
import BAMusicList from "pages/BusinessP/Music/MusicList";
import BACreateMusic from "pages/BusinessP/Music/CreateMusic";
import BAUpdateMusic from "pages/BusinessP/Music/UpdateMusic";
import BAFeatureList from "pages/BusinessP/Features/FeatureList";
import BACreateFeature from "pages/BusinessP/Features/CreateFeature";
import BAUpdateFeature from "pages/BusinessP/Features/UpdateFeature";
import BAThemeManagmentlist from "pages/BusinessP/ThemeManagement/ThemeManagmentlist";
import BACreateTheme from "pages/BusinessP/ThemeManagement/CreateTheme";
import BAUpdateTheme from "pages/BusinessP/ThemeManagement/UpdateTheme";
import BACuisineManagmentlist from "pages/BusinessP/CuisineManagement/CuisineManagmentlist";
import BACreateCuisine from "pages/BusinessP/CuisineManagement/CreateCuisine";
import BAUpdateCuisine from "pages/BusinessP/CuisineManagement/UpdateCuisine";
import BARatingManagmentlist from "pages/BusinessP/RatingManagement/RatingManagmentlist";
import BAUpdateRating from "pages/BusinessP/RatingManagement/UpdateRating";
import BAAddReply from "pages/BusinessP/RatingManagement/AddReply";
import BAFollowManagementlist from "pages/BusinessP/FollowManagement/FollowManagmentlist";
import BAVoucherList from "pages/BusinessP/VoucherManagement/VoucherManagementList";
import BACreateVoucher from "pages/BusinessP/VoucherManagement/CreateVoucher";
import BAUpdateVoucher from "pages/BusinessP/VoucherManagement/UpdateVoucher";
import BAPublicHoliday from "pages/BusinessP/OfferManagement/PublicHolidayManagmentlist";
import BACreatePublicHoliday from "pages/BusinessP/OfferManagement/CreateHoliday";
import BAUpdatePublicHoliday from "pages/BusinessP/OfferManagement/UpdateHoliday";
import BASpecialOfferClaimManagementlist from "pages/BusinessP/OfferManagement/SpecialOfferClaimManagmentlist";
import BAOfferOrderManagementlist from "pages/BusinessP/OfferOrderManagement/OfferOrderManagmentlist";
import BAOfferNotificationlist from "pages/BusinessP/OfferManagement/OfferNotificationlist";
import BAOfferManagementlist from "pages/BusinessP/OfferManagement/OfferManagmentlist";
import BACreateOffer from "pages/BusinessP/OfferManagement/CreateOffer";
import BAUpdateOffer from "pages/BusinessP/OfferManagement/UpdateOffer";
import BASpecialOfferManagementlist from "pages/BusinessP/OfferManagement/SpecialOfferManagmentlist";
import BACreateSpecialOffer from "pages/BusinessP/SpecialOffer/CreateSpecialOffer";
import BAUpdateSpecialOffer from "pages/BusinessP/SpecialOffer/UpdateSpecialOffer";
import BACategoryList from "pages/BusinessP/CategoryManagement/CategoryList";
import BACreateCategory from "pages/BusinessP/CategoryManagement/CreateCategory";
import BAUpdateCategory from "pages/BusinessP/CategoryManagement/UpdateCategory";
import BACategorydetails from "pages/BusinessP/CategoryManagement/Categorydetails";
import BABannerManagementlist from "pages/BusinessP/BannerManagement/BannerManagmentlist";
import BACreateBanner from "pages/BusinessP/BannerManagement/CreateBanner";
import BAUpdateBanner from "pages/BusinessP/BannerManagement/UpdateBanner";
import BAEventlist from "pages/BusinessP/EventManagement/Eventlist";
import BACreateEvent from "pages/BusinessP/EventManagement/CreateEvent";
import BAUpdateEvent from "pages/BusinessP/EventManagement/UpdateEvent";
import BAEventdetails from "pages/BusinessP/EventManagement/Eventdetails";
import BAInviteListTable from "pages/BusinessP/EventManagement/InviteListTable";
import BASubScriptionList from "pages/BusinessP/SubscriptionManagement/SubScriptionList";
import BAMyScriptionList from "pages/BusinessP/SubscriptionManagement/MyScriptionList";
import BACommissionsList from "pages/BusinessP/SubscriptionManagement/BACommissionsList";


import BACreateStory from "pages/BusinessP/StoryManagement/CreateStory";
// import BAUpdateStory from "pages/BusinessP/StoryManagement/UpdateStory";
import BAStoryManagmentlist from "pages/BusinessP/StoryManagement/StoryManagmentlist";



// Venue Account

import VADashboard from "../pages/VenueP/Dashboard/index";

import VADashboardCommission from "../pages/VenueP/DashboardCommission/index";
import VAVenueDashboard from "pages/VenueP/VenuePDashboard/index";
import VAOfferPDashboard from "pages/VenueP/OfferPDashboard/index";
import VAVenueManagmentlist from "pages/VenueP/VenueManagement/VenueManagmentlist";
import VACreateVenue from "pages/VenueP/VenueManagement/CreateVenue";
import VAUpdateVenue from "pages/VenueP/VenueManagement/UpdateVenue";
import VAUpdateCredential from "pages/VenueP/VenueManagement/UpdateCredential";
import VAVenuedetails from "pages/VenueP/VenueManagement/Venuedetails";
import VAVenueOutingdetails from "pages/VenueP/VenueManagement/VenueOutingdetails";
import VAGalleryManagementlist from "pages/VenueP/GalleryManagement/GalleryManagmentlist";
import VACreateGallery from "pages/VenueP/GalleryManagement/CreateGallery";
import VAMusicList from "pages/VenueP/Music/MusicList";
import VACreateMusic from "pages/VenueP/Music/CreateMusic";
import VAUpdateMusic from "pages/VenueP/Music/UpdateMusic";
import VAFeatureList from "pages/VenueP/Features/FeatureList";
import VACreateFeature from "pages/VenueP/Features/CreateFeature";
import VAUpdateFeature from "pages/VenueP/Features/UpdateFeature";
import VAThemeManagmentlist from "pages/VenueP/ThemeManagement/ThemeManagmentlist";
import VACreateTheme from "pages/VenueP/ThemeManagement/CreateTheme";
import VAUpdateTheme from "pages/VenueP/ThemeManagement/UpdateTheme";
import VACuisineManagmentlist from "pages/VenueP/CuisineManagement/CuisineManagmentlist";
import VACreateCuisine from "pages/VenueP/CuisineManagement/CreateCuisine";
import VAUpdateCuisine from "pages/VenueP/CuisineManagement/UpdateCuisine";
import VARatingManagmentlist from "pages/VenueP/RatingManagement/RatingManagmentlist";
import VAUpdateRating from "pages/VenueP/RatingManagement/UpdateRating";
import VAAddReply from "pages/VenueP/RatingManagement/AddReply";
import VAFollowManagementlist from "pages/VenueP/FollowManagement/FollowManagmentlist";
import VAVoucherList from "pages/VenueP/VoucherManagement/VoucherManagementList";
import VACreateVoucher from "pages/VenueP/VoucherManagement/CreateVoucher";
import VAUpdateVoucher from "pages/VenueP/VoucherManagement/UpdateVoucher";
import VAPublicHoliday from "pages/VenueP/OfferManagement/PublicHolidayManagmentlist";
import VACreatePublicHoliday from "pages/VenueP/OfferManagement/CreateHoliday";
import VAUpdatePublicHoliday from "pages/VenueP/OfferManagement/UpdateHoliday";
import VASpecialOfferClaimManagementlist from "pages/VenueP/OfferManagement/SpecialOfferClaimManagmentlist";
import VAOfferOrderManagementlist from "pages/VenueP/OfferOrderManagement/OfferOrderManagmentlist";
import VAOfferNotificationlist from "pages/VenueP/OfferManagement/OfferNotificationlist";
import VAOfferManagementlist from "pages/VenueP/OfferManagement/OfferManagmentlist";
import VACreateOffer from "pages/VenueP/OfferManagement/CreateOffer";
import VAUpdateOffer from "pages/VenueP/OfferManagement/UpdateOffer";
import VASpecialOfferManagementlist from "pages/VenueP/OfferManagement/SpecialOfferManagmentlist";
import VACreateSpecialOffer from "pages/VenueP/SpecialOffer/CreateSpecialOffer";
import VAUpdateSpecialOffer from "pages/VenueP/SpecialOffer/UpdateSpecialOffer";
import VACategoryList from "pages/VenueP/CategoryManagement/CategoryList";
import VACreateCategory from "pages/VenueP/CategoryManagement/CreateCategory";
import VAUpdateCategory from "pages/VenueP/CategoryManagement/UpdateCategory";
import VACategorydetails from "pages/VenueP/CategoryManagement/Categorydetails";
import VABannerManagementlist from "pages/VenueP/BannerManagement/BannerManagmentlist";
import VACreateBanner from "pages/VenueP/BannerManagement/CreateBanner";
import VAUpdateBanner from "pages/VenueP/BannerManagement/UpdateBanner";
import VAEventlist from "pages/VenueP/EventManagement/Eventlist";
import VACreateEvent from "pages/VenueP/EventManagement/CreateEvent";
import VAUpdateEvent from "pages/VenueP/EventManagement/UpdateEvent";
import VAEventdetails from "pages/VenueP/EventManagement/Eventdetails";
import VAInviteListTable from "pages/VenueP/EventManagement/InviteListTable";
import VASubScriptionList from "pages/VenueP/SubscriptionManagement/SubScriptionList";
import VAMyScriptionList from "pages/VenueP/SubscriptionManagement/MyScriptionList";
import VACommissionsList from "pages/VenueP/SubscriptionManagement/BACommissionsList";

import VACreateStory from "pages/VenueP/StoryManagement/CreateStory";
// import BAUpdateStory from "pages/BusinessP/StoryManagement/UpdateStory";
import VAStoryManagmentlist from "pages/VenueP/StoryManagement/StoryManagmentlist";

import VANotificationList from "pages/VenueP/Notification/NotificationList";
import VAUpdateNotification from "pages/VenueP/Notification/UpdateNotification";
import VACreateNotification from "pages/VenueP/Notification/CreateNotification";
import VANotificationdetails from "pages/VenueP/Notification/NotificationDetails";
import VACloneNotification from "pages/VenueP/Notification/CloneNotification";

import VAConvoManagmentlist from "pages/VenueP/Conversation/ConvoManagmentlist";
import VAChatWindow from "pages/VenueP/Conversation/ChatWindow";

import VAOfferCommissionManagementlist from "pages/VenueP/OfferOrderManagement/OfferCommissionManagmentlist";







const userRoutes = [
  // Dashboard
  { path: "/dashboard", component: Dashboard },
  // Venue Dashboard
  { path: "/venue-analytic", component: VenueDashboard, permission: "analytics" },
  // Offer Dashboard
  { path: "/offer-analytic", component: OfferDashboard, permission: "analytics" },
  // Category Dashboard
  { path: "/category-analytic", component: CategoryDashboard, permission: "analytics" },
  // User Dashboard
  { path: "/user-analytic", component: UserDashboard, permission: "analytics" },
  // Offers Analytics
  { path: "/offers-analytics", component: AllOffersDashboard, permission: "analytics" },	
  // Venue Analytics
  { path: "/venues-analytics", component: AllVenuesDashboard, permission: "analytics" },
  // Commission Report
  { path: "/commission-report", component: DashboardCommission, permission: "commissionReport" },
  // Subscription Report
  { path: "/subscription-report", component: DashboardSubscription, permission: "subscriptionReport" },
  // Chart List
  { path: "/chart", component: ChartComponets },
  // Graph page
  { path: "/graph", component: Graph },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  { path: "/users", component: UserManagmentlist, permission: "users" },

  { path: "/roles", component: RoleAccessManagement, permission: "adminManagement" },
  { path: "/createRole", component: CreateRoleAccess, permission: "adminManagement" },
  { path: "/updateRole/:id", component: UpdateRoleAccess , permission: "adminManagement" },


  // Delete Account List
  { path: "/deleteAccount", component: DeleteAccountManagementlist, permission: "users"  },
  // business List
  { path: "/business", component: BusinessManagmentlist, permission: "businessManagement" },

  // Poll List
  { path: "/poll", component: PollManagmentlist },


  { path: "/poll/create", component: CreatePoll },
  { path: "/poll/update/:id", component: UpdatePoll },

  // Admin Settings
  { path: "/admin-settings", component: AdminPollSettings },

  // OnBoarding User List
  { path: "/onBoardingUser", component: OnBoardingManagementlist, permission: "onboarding" },

  // OnBoarding Venue List
  { path: "/onBoardingVenue", component: OnBoardingVenuelist, permission: "onboarding" },

  // OnBoarding Venue View
  { path: "/onBoardingVenueView/:id", component: OnBoardingVenueView, permission: "onboarding" },

  // Business Account Dashboard
  { path: "/business-account/dashboard", component: BADashboard },

   // Venue Account Dashboard
   { path: "/venue-account/dashboard", component: VADashboard },

    // Venue Account Dashboard
    { path: "/venue-account/venue-reservations", component: VAReservationList },

    // Venue Account Reservation Details
    { path: "/venue-account/venue-reservation-detail/:id", component: VAVenueReservationDetailPage },

    // venue account Venue Table list
    { path: "/venue-account/venue-table", component: VAVenueTableList },

    { path: "/venue-account/create-venue-table", component: VACreateVenueTable},
    { path: "/venue-account/update-venue-table/:id", component: VAUpdateVenueTable},




   // Venue Account Commission Report
   { path: "/venue-account/order-report", component: VADashboardCommission },

   // Venue Account Venue Analytics
   { path: "/venue-account/venue-analytic", component: VAVenueDashboard },

   // Venue Account Offer Analytics
   { path: "/venue-account/offer-analytic", component: VAOfferPDashboard },

  // Business Account Business List
  { path: "/business-account/business", component: BABusinessManagementlist },

  // log management list
  { path: "/Log-Management-list", component: LogManagementList, permission: "adminManagement"},
  // Log Management detail in Role Access
  { path: "/log-management-list/:id", component: LogManagementListRole, permission: "adminManagement"},

  // log venue
  { path: "/Logdetails-venue/:id", component: LogVenue, permission: "adminManagement"},

  // log offer
  { path: "/Logdetails-offer/:id", component: LogOffer, permission: "adminManagement"},

  // admin profile
  { path: "/adminProfile", component: AdminProfileUpdate },

  // admin profile Setting
  { path: "/adminSetting", component: AdminSetting },


  // business delete List
  { path: "/businessDelete", component: BusinessDeletelist , permission: "businessManagement" },
  // create business
  { path: "/createBusiness", component: CreateBusiness, permission: "businessManagement" },
  // update business
  { path: "/updateBusiness/:id", component: UpdateBusiness, permission: "businessManagement" },
  // Update Business Account
  { path: "/business-account/updateBusiness/:id", component: BAUpdateBusiness, permission: "businessManagement" },
  // reset business password
  { path: "/resetBusinessPassword/:id", component: ResetBusinessPassword, permission: "businessManagement" },

  // Reset Business Account Password
  { path: "/business-account/resetBusinessPassword/:id", component: BAResetBusinessPassword },

  // Business Details
  { path: "/businessdetails/:id", component: Businessdetail, permission: "businessManagement" },
  // Business Account Business Details
  { path: "/business-account/businessdetails/:id", component: BABusinessdetails },
  // Venue List
  { path: "/venues", component: VenueManagmentlist, permission: "businessManagement" },

  // Venue delete List
  { path: "/venue-reservations", component: VenueReservationlist, permission: "businessManagement" },

  // Venue Reservation Detail Page
  { path: "/venue-reservation-detail/:id", component: VenueReservationDetailPage, permission: "businessManagement" },

  // Business Account Venue List
  { path: "/business-account/venues", component: BAVenueManagmentlist },

   // Venue Account Venue List
   { path: "/venue-account/venues", component: VAVenueManagmentlist },

  { path: "/updateCredential/:id", component: UpdateCredential, permission: "businessManagement" },
  // Update Business Account Credential
  { path: "/business-account/updateCredential/:id", component: BAUpdateCredential },

  { path: "/venue-account/updateCredential/:id", component: VAUpdateCredential },

  // Venue Outing List
  { path: "/venueOuting/:id", component: VenueOutingManagementList },
  // Venue delete List
  { path: "/venueDelete", component: VenueDeletelist },
  // Create Venue
  { path: "/createVenue", component: CreateVenue, permission: "businessManagement" },
  // Business Account Create Venue
  { path: "/business-account/createVenue", component: BACreateVenue },
  { path: "/venue-account/createVenue", component: VACreateVenue },
  { path: "/createVenueTheme", component: CreateVenueTheme, permission: "businessManagement" },
  { path: "/createVenueCuisine", component: CreateVenueCuisine, permission: "businessManagement" },
  { path: "/createVenueMusic", component: CreateVenueMusic, permission: "businessManagement" },
  { path: "/createVenueFeature", component: CreateVenueFeature, permission: "businessManagement" },
  { path: "/createVenueAssignMusic", component: CreateVenueAssignMusic, permission: "businessManagement" },
  { path: "/createVenueAssignFeature", component: CreateVenueAssignFeature, permission: "businessManagement" },
  { path: "/createVenueAssignTheme", component: CreateVenueAssignTheme, permission: "businessManagement" },
  { path: "/createVenueAssignCuisine", component: CreateVenueAssignCuisine, permission: "businessManagement" },

  { path: "/venuedetails/:id", component: Venuedetails, permission: "businessManagement" },
  // Business Account Venue Details
  { path: "/business-account/venuedetails/:id", component: BAVenuedetails },
  { path: "/venue-account/venuedetails/:id", component: VAVenuedetails },
  // Venue Outing Details
  { path: "/venueOutingdetails/:id", component: VenueOutingdetails },

  // Business Account Venue Outing Details
  { path: "/business-account/venueOutingdetails/:id", component: BAVenueOutingdetails },

  { path: "/venue-account/venueOutingdetails/:id", component: VAVenueOutingdetails },

  { path: "/bucketdetails/:id", component: BucketList },

  // Update Venue
  { path: "/updateVenue/:id", component: UpdateVenue, permission: "businessManagement" },  

  // Business Account Update Venue
  { path: "/business-account/updateVenue/:id", component: BAUpdateVenue },

  { path: "/venue-account/updateVenue/:id", component: VAUpdateVenue },

  { path: "/venue-account/", component: VADashboard },

  // Venue Graph
  { path: "/venueGraphdetails/:id", component: VenueGraphdetails, permission: "businessManagement" },
  // Menu List
  { path: "/menu/:id", component: MenuManagmentlist },
  // Create Menu
  { path: "/createMenu/:id", component: CreateMenu },
  // Story List
  { path: "/story/:id", component: VenueStoryManagementlist, permission: "HomeBlockManagement" },
  { path: "/createStory/:id", component: CreateVenueStory, permission: "HomeBlockManagement" },
  // Update Menu
  { path: "/updateMenu/:id", component: UpdateMenu},
  // Rating List
  { path: "/ratings", component: RatingManagmentlist, permission: "businessManagement" },
  // Business Account Rating List
  { path: "/business-account/ratings", component: BARatingManagmentlist },

  // Venue Account Rating List
  { path: "/venue-account/ratings", component: VARatingManagmentlist },

  // Venue Account Update Rating
  { path: "/venue-account/updateRating/:id", component: VAUpdateRating },

  // Update Rating
  { path: "/updateRating/:id", component: UpdateRating, permission: "businessManagement" },
  // Business Account Update Rating
  { path: "/business-account/updateRating/:id", component: BAUpdateRating },
 
  




  //add reply
  { path: "/addReply/:id", component: AddReply, permission: "businessManagement" },
  // Business Account Add Reply
  { path: "/business-account/addReply/:id", component: BAAddReply },

   // Venue Account Add Reply
   { path: "/venue-account/addReply/:id", component: VAAddReply },
  // Theme List
  { path: "/themes", component: ThemeManagmentlist, permission: "businessManagement" },
  // Business Account Theme List
  { path: "/business-account/themes", component: BAThemeManagmentlist },
  
  { path: "/venue-account/themes", component: VAThemeManagmentlist },
  { path: "/createBusinessTheme/:id", component: CreateBusinessTheme },

  // Create Theme
  { path: "/createTheme", component: CreateTheme, permission: "businessManagement" },
  // Business Account Create Theme
  { path: "/business-account/createTheme", component: BACreateTheme },
  { path: "/venue-account/createTheme", component: VACreateTheme },
  // Update Theme
  { path: "/updateTheme/:id", component: UpdateTheme, permission: "businessManagement" },
  // Business Account Update Theme
  { path: "/business-account/updateTheme/:id", component: BAUpdateTheme },
  { path: "/venue-account/updateTheme/:id", component: VAUpdateTheme },

  // Cuisine List
  { path: "/cuisines", component: CuisineManagmentlist, permission: "businessManagement" },
  // Business Account Cuisine List
  { path: "/business-account/cuisines", component: BACuisineManagmentlist },

  // Venue Account Cuisine List
  { path: "/venue-account/cuisines", component: VACuisineManagmentlist },

  // Create Cuisine
  { path: "/createCuisine", component: CreateCuisine, permission: "businessManagement" },
  // Business Account Create Cuisine
  { path: "/business-account/createCuisine", component: BACreateCuisine },
  { path: "/venue-account/createCuisine", component: VACreateCuisine },
  // Update Cuisine
  { path: "/updateCuisine/:id", component: UpdateCuisine, permission: "businessManagement" },
  // Business Account Update Cuisine
  { path: "/business-account/updateCuisine/:id", component: BAUpdateCuisine },
  { path: "/venue-account/updateCuisine/:id", component: VAUpdateCuisine },

  { path: "/updateuserdata/:id", component: Updateuserdata, permission: "users" },
  { path: "/userdetails/:id", component: Userdetails, permission: "users" },
  { path: "/usercreate", component: CreateUser, permission: "users" },

  // FilterCategory List
  { path: "/filtercategories", component: FilterCategorylist, permission: "appManagement" },
  // Create FilterCategory
  { path: "/createFilter", component: CreateFilter, permission: "appManagement" },
  // Update FilterCategory
  { path: "/updateFilter/:id", component: UpdateFilter, permission: "appManagement" },

  //Story List
  { path: "/stories", component: StoryManagmentlist, permission: "HomeBlockManagement" },
  { path: "/business-account/stories", component: BAStoryManagmentlist },
  { path: "/venue-account/stories", component: VAStoryManagmentlist },


  // Story
  { path: "/createStory", component: CreateStory, permission: "HomeBlockManagement" },

  { path: "/business-account/createStory", component: BACreateStory },

  { path: "/venue-account/createStory", component: VACreateStory },




  // Update Story
  { path: "/updateStory/:id", component: UpdateStory, permission: "HomeBlockManagement" },

  // { path: "/business-account/updateStory/:id", component: BAUpdateStory },

  //Page List
  { path: "/7n/settings", component: SevenNightManagementlist, permission: "appManagement" },
  //Create Page
  { path: "/7n/settings/create", component: CreateSevenNight, permission: "appManagement" },
  //Update Page
  { path: "/7n/settings/update/:id", component: UpdateSevenNight, permission: "appManagement" },

  // 7N List
  { path: "/pages", component: PageManagementlist, permission: "appManagement" },

  { path: "/createPage", component: CreatePage, permission: "appManagement" },

  { path: "/updatePage/:id", component: UpdatePage, permission: "appManagement" },


  //Gallery List
  { path: "/galleries", component: GalleryManagementlist, permission: "businessManagement" },
  // Business Account Gallery List
  { path: "/business-account/galleries", component: BAGalleryManagementlist },

  { path: "/venue-account/galleries", component: VAGalleryManagementlist },
  //Create Gallery
  { path: "/createGallery", component: CreateGallery, permission: "businessManagement" },
  // Business Account Create Gallery
  { path: "/business-account/createGallery", component: BACreateGallery },
  // videos List
  { path: "/videos", component: VideoManagementlist, permission: "HomeBlockManagement" },
  // Create Video
  { path: "/createVideo", component: CreateVideo, permission: "HomeBlockManagement" },
  // Update Video
  { path: "/updateVideo/:id", component: UpdateVideo, permission: "HomeBlockManagement" },

  // Time List
  { path: "/time/:id", component: TimeManagementlist, permission: "businessManagement" },
  // Create Time
  { path: "/createTime/:id", component: CreateTime, permission: "businessManagement" },
  // Update Time
  { path: "/updateTime/:id", component: UpdateTime, permission: "businessManagement" },

  // HomeBlock List
  { path: "/homeblock", component: HomeBlockManagementlist, permission: "HomeBlockManagement" },
  // create HomeBlock
  { path: "/createHomeblock", component: CreateHomeBlock, permission: "HomeBlockManagement" },
  // Update HomeBlock
  { path: "/updateHomeblock/:id", component: UpdateHomeBlock, permission: "HomeBlockManagement" },
  //Home Block Details
  { path: "/homeblockdetails/:id", component: Homeblockdetails, permission: "HomeBlockManagement" },



  // HomeBlock Banner
  { path: "/homeblockbanner", component: HomeBannerlist, permission: "HomeBlockManagement" },
  // Create HomeBlock Banner
  { path: "/createHomeblockbanner", component: CreateHomeBanner, permission: "HomeBlockManagement" },
  // Update HomeBlock Banner
  { path: "/updateHomeblockbanner/:id", component: UpdateHomeBanner, permission: "HomeBlockManagement" },

  // Custom Banner
  // { path: "/banner", component: BannerManagementlist },
  // { path: "/createBanner", component: CreateBanner },
  // { path: "/updateBanner/:id", component: UpdateBanner },

  //Home Block Preview
  { path: "/homeBlockPreview", component: homeBlockPreview, permission: "HomeBlockManagement" },

  // SearchBlock List
  { path: "/searchblock", component: SearchBlockManagementlist, permission: "HomeBlockManagement" },
  // create SearchBlock
  { path: "/createSearchblock", component: CreateSearchBlock, permission: "HomeBlockManagement" },
  // Update SearchBlock
  { path: "/updateSearchblock/:id", component: UpdateSearchBlock, permission: "HomeBlockManagement" },
  //Search Block Details
  { path: "/searchblockdetails/:id", component: Searchblockdetails, permission: "HomeBlockManagement" },

  //Search Block Preview
  { path: "/searchBlockPreview", component: searchBlockPreview, permission: "HomeBlockManagement" },

  // ExploreBlock List
  { path: "/exploreblock", component: ExploreBlockManagementlist, permission: "HomeBlockManagement" },
  // create ExploreBlock
  { path: "/createExploreblock", component: CreateExploreBlock, permission: "HomeBlockManagement" },
  // Update ExploreBlock
  { path: "/updateExploreblock/:id", component: UpdateExploreBlock, permission: "HomeBlockManagement" },
  //Explore Block Details
  { path: "/exploreblockdetails/:id", component: Exploreblockdetails, permission: "HomeBlockManagement" },

  //Explore Block Preview
  { path: "/exploreBlockPreview", component: exploreBlockPreview, permission: "HomeBlockManagement" },

 // Country List
  { path: "/country", component: CountryManagmentList, permission: "appManagement" },
  // Create Country
  { path: "/createCountry", component: CreateCountry, permission: "appManagement" },
  // Update Country
  { path: "/updateCountry/:id", component: UpdateCountry, permission: "appManagement" },

  // Region List
  { path: "/region", component: RegionManagmentList, permission: "appManagement" },
  // Create Region
  { path: "/createRegion", component: CreateRegion, permission: "appManagement" },
  // Update Region
  { path: "/updateRegion/:id", component: UpdateRegion, permission: "appManagement" },

  //Music List
  { path: "/music", component: MusicList, permission: "businessManagement" },
  // Business Account Music List
  { path: "/business-account/music", component: BAMusicList },
  { path: "/venue-account/music", component: VAMusicList },
  { path: "/Createmusicdata", component: CreateMusic, permission: "businessManagement" },
  // Business Account Create Music
  { path: "/business-account/Createmusicdata", component: BACreateMusic },
  { path: "/venue-account/Createmusicdata", component: VACreateMusic },
  { path: "/Updatemusicdata/:id", component: UpdateMusic, permission: "businessManagement" }, 
  // Business Account Update Music
  { path: "/business-account/Updatemusicdata/:id", component: BAUpdateMusic },
  { path: "/venue-account/Updatemusicdata/:id", component: VAUpdateMusic },

  // Feature List
  { path: "/feature", component: FeatureList, permission: "businessManagement" },
  // Business Account Feature List
  { path: "/business-account/feature", component: BAFeatureList },
  { path: "/venue-account/feature", component: VAFeatureList },
  { path: "/Createfeaturedata", component: CreateFeature, permission: "businessManagement" },
  // Business Account Create Feature
  { path: "/business-account/Createfeaturedata", component: BACreateFeature },
  { path: "/venue-account/Createfeaturedata", component: VACreateFeature },
  { path: "/Updatefeaturedata/:id", component: UpdateFeature, permission: "businessManagement" },
  // Business Account Update Feature
  { path: "/business-account/Updatefeaturedata/:id", component: BAUpdateFeature },

  { path: "/venue-account/Updatefeaturedata/:id", component: VAUpdateFeature },

  // Venue Checkin List
  { path: "/venuecheckin", component: CheckinList, permission: "businessManagement" },

  // BlockSize List
  { path: "/blocksize", component: BlockSizeList, permission: "HomeBlockManagement" },
  { path: "/Createsizedata", component: CreateBlockSize, permission: "HomeBlockManagement" },
  { path: "/Updatesizedata/:id", component: UpdateBlockSize, permission: "HomeBlockManagement" },

  // Icon List
  { path: "/activityIcon", component: IconList, permission: "appManagement" },
  // Create Icon
  { path: "/createIcon", component: CreateIcon, permission: "appManagement" },

  // Activity Rating List
  { path: "/activityRating", component: ActivityRatingManagementlist },
  // update Activity Rating
  { path: "/updateActivityRating/:id", component: UpdateActivityRating },

  //Discount Type List
  { path: "/discountType", component: DiscountTypeManagementlist },
  // Create Discount Type
  { path: "/createDiscountType", component: CreateDiscountType },
  // Update Discount Type
  { path: "/updateDiscountType/:id", component: UpdateDiscountType },

  //Discount List
  { path: "/discount", component: DiscountManagementlist },
  // Create Discount
  { path: "/createDiscount", component: CreateDiscount },
  // Update Discount
  { path: "/updateDiscount/:id", component: UpdateDiscount },

  //Banner Type List
  { path: "/bannerType", component: BannerTypeManagementlist },
  // Create Banner Type
  { path: "/createBannerType", component: CreateBannerType },
  // Update Banner Type
  { path: "/updateBannerType/:id", component: UpdateBannerType },

  //Banner List
  { path: "/banner", component: BannerManagementlist },

  // Business Account Banner List
  { path: "/business-account/banner", component: BABannerManagementlist },

  { path: "/venue-account/banner", component: VABannerManagementlist },

  // Create Banner
  { path: "/createBanner", component: CreateBanner },
  // Business Account Create Banner
  { path: "/business-account/createBanner", component: BACreateBanner },
  { path: "/venue-account/createBanner", component: VACreateBanner },
  // Update Banner
  { path: "/updateBanner/:id", component: UpdateBanner },
  // Business Account Update Banner
  { path: "/business-account/updateBanner/:id", component: BAUpdateBanner },
  { path: "/venue-account/updateBanner/:id", component: VAUpdateBanner },
  //Activity Banner List
  { path: "/activityBanner", component: ActivityBannerManagementlist },
  // Create Activity Banner
  { path: "/createActivityBanner", component: CreateActivityBanner },

  // create Activity
  { path: "/createActivity", component: CreateActivity },
  // Update Activity
  { path: "/updateActivity/:id", component: UpdateActivity },
  // Activity Details
  { path: "/activitydetails/:id", component: Activitydetails },

  // Subscriber List
  { path: "/subscribe", component: SubscriptionManagementlist, permission: "subscriptionManagement" },

  // Custom Subscription List
  { path: "/customsubscribe", component: CustomSubscriptionManagementlist, permission: "subscriptionManagement" },
  { path: "/createCustomSubscribe", component: CreateCustomSubscribe, permission: "subscriptionManagement" },
  { path: "/updateCustomSubscribe/:id", component: UpdateCustomSubscribe, permission: "subscriptionManagement" },

  //Offer List
   { path: "/offer", component: OfferManagementlist, permission: "offerManagement" },

   // Business Account Offer List
    { path: "/business-account/offer", component: BAOfferManagementlist },

    { path: "/venue-account/offer", component: VAOfferManagementlist },

   // Offer Delete List
    { path: "/offerDelete", component: OfferDeletelist, permission: "offerManagement" },

    //Public Holiday List
    { path: "/public-holiday", component: PublicHoliday, permission: "offerManagement" },
    // Business Account Public Holiday List
    { path: "/business-account/public-holiday", component: BAPublicHoliday },
    { path: "/venue-account/public-holiday", component: VAPublicHoliday },
    // Create holiday
    { path: "/createHoliday", component: CreatePublicHoliday, permission: "offerManagement" },
    // Business Account Create holiday
    { path: "/business-account/createHoliday", component: BACreatePublicHoliday },
    { path: "/venue-account/createHoliday", component: VACreatePublicHoliday },

    // Update holiday
    { path: "/updateHoliday/:id", component: UpdatePublicHoliday },
    // Business Account Update holiday
    { path: "/business-account/updateHoliday/:id", component: BAUpdatePublicHoliday },
    { path: "/venue-account/updateHoliday/:id", component: VAUpdatePublicHoliday },
    

   // Offer graph   
   { path: "/offerGraphdetails/:id", component: OfferGraphdetails, permission: "offerManagement" },
  // Offer Notification List
  { path: "/offerNotification", component: OfferNotificationlist, permission: "offerManagement" },
  // Business Account Offer Notification List
  { path: "/business-account/offerNotification", component: BAOfferNotificationlist },
  { path: "/venue-account/offerNotification", component: VAOfferNotificationlist },
  // Create Offer
  { path: "/createOffer", component: CreateOffer, permission: "offerManagement" },
  // Business Account Create Offer
  { path: "/business-account/createOffer", component: BACreateOffer },
  { path: "/venue-account/createOffer", component: VACreateOffer },
  // Update Offer
  { path: "/updateOffer/:id", component: UpdateOffer, permission: "offerManagement" },
  // Business Account Update Offer
  { path: "/business-account/updateOffer/:id", component: BAUpdateOffer },
  { path: "/venue-account/updateOffer/:id", component: VAUpdateOffer },

  // Venue Verify List
  { path: "/venue-verify", component: VerifyList, permission: "venueVerifyManagement" }, 

  { path: "/Createverifydata", component: CreateVerify },

  { path: "/Updateverifydata/:id", component: UpdateVerify, permission: "venueVerifyManagement" },

  // Venue Badge List
  { path: "/venue-badge", component: BadgeList, permission: "venueBadgeManagement" }, 

  { path: "/Createbadgedata", component: CreateBadge },

  { path: "/Updatebadgedata/:id", component: UpdateBadge, permission: "venueBadgeManagement" },


  // Category List
  { path: "/category", component: CategoryList, permission: "categoryManagement" },

  // Venue Table List
  { path: "/venue-table", component: VenueTableList, permission: "venueTable" },
  { path: "/create-venue-table", component: CreateVenueTable, permission: "venueTable" },
  { path: "/update-venue-table/:id", component: UpdateVenueTable, permission: "venueTable" },
  
  // Business Account Category List
  { path: "/business-account/category", component: BACategoryList },
  { path: "/Createcategorydata", component: CreateCategory },
  // Business Account Create Category
  { path: "/business-account/Createcategorydata", component: BACreateCategory },
  { path: "/Updatecategorydata/:id", component: UpdateCategory, permission: "categoryManagement" },
  // Business Account Update Category
  { path: "/business-account/Updatecategorydata/:id", component: BAUpdateCategory },
  { path: "/categorydetails/:id", component: Categorydetails, permission: "categoryManagement" },
  // Business Account Category Details
  { path: "/business-account/categorydetails/:id", component: BACategorydetails },
  // Business Account subscription management
  { path: "/business-account/get-subscription", component: BASubScriptionList },
  { path: "/venue-account/get-subscription", component: VASubScriptionList },
  { path: "/business-account/my-subscription", component: BAMyScriptionList },
  { path: "/venue-account/my-subscription", component: VAMyScriptionList },
  { path: "/business-account/subscriptions/commissions", component: BACommissionsList },
  { path: "/venue-account/subscriptions/commissions", component: VACommissionsList },


  // Notification
  { path: "/notification", component: NotificationList, permission: "notificationManagement" }, 
  { path: "/notification-pending", component: NotificationPendingList, permission: "notificationManagement" }, 
  { path: "/venue-account/notification", component: VANotificationList },
  { path: "/notificationdetails/:id", component: Notificationdetails, permission: "notificationManagement" },
  { path: "/notification-approve/:id", component: NotificationApprove, permission: "notificationManagement"  },
  { path: "/venue-account/notificationdetails/:id", component: VANotificationdetails},
  { path: "/notificationclone", component: CloneNotification, permission: "notificationManagement" },
  { path: "/venue-account/notificationclone/:id", component: VACloneNotification },



  { path: "/Createnotification", component: CreateNotification, permission: "notificationManagement" },
  { path: "/venue-account/Createnotification", component: VACreateNotification},
  { path: "/Updatenotificationdata/:id", component: UpdateNotification, permission: "notificationManagement" },
  { path: "/venue-account/Updatenotificationdata/:id", component: VAUpdateNotification },
  { path: "/notificationread", component: NotificationReadManagementlist, permission: "notificationManagement" },

  // Event Organizer List
  { path: "/eventOrganizer", component: EventOrganizerlist },
  { path: "/createeventorg", component: CreateEventOrganizerData },
  { path: "/updateeventorg/:id", component: UpdateEventOrganizerData },
  { path: "/organizerdetails/:id", component: Organizerdetails },
  { path: "/eventdetails/:id", component: Eventdetails },

  // Business Account Event Details
  { path: "/business-account/eventdetails/:id", component: BAEventdetails },

  //

  //Event Organizer Details
  { path: "/eventOrganizerDetails", component: EventOrganizerdetails },
  { path: "/updateOrganizerProfile", component: UpdateOrganizerProfile },
  { path: "/updateOrgProfile", component: UpdateEventOrg },

  //Business Portal
  { path: "/businessDetails", component: BusinessDetails },
  { path: "/updateOrganizerProfile", component: UpdateOrganizerProfile },
  { path: "/updateOrgProfile", component: UpdateEventOrg },

  // 

  // Event Organizer Gallery List
  { path: "/eventGallery", component: EventGalleryManagementlist },
  { path: "/createEventGallery", component: CreateEventGallery },

  // Event Organizer Rating List
  { path: "/eventRating", component: EventRatingManagementlist },

  // Business
  { path: "/updateeventrating/:id", component: UpdateEventRating },
  { path: "/addeventratingreply/:id", component: AddEventRatingReply },

  // Evet Organizer Follow List
  { path: "/eventFollow", component: EventFollowManagementlist },
  { path: "/eventOrders", component: EventOrderManagmentlist },

  // Event List
  { path: "/events", component: Eventlist },
  // Business Account Event List
  { path: "/business-account/events", component: BAEventlist },
  { path: "/createevents", component: CreateEvent },


  { path: "/creat-banners", component: CreateEvent },


  // Business Account Create Event
  { path: "/business-account/createevents", component: BACreateEvent },
  { path: "/updateevents/:id", component: UpdateEvent },
  // Business Account Update Event
  { path: "/business-account/updateevents/:id", component: BAUpdateEvent },

  { path: "/inviteList/:id", component: InviteListTable },
  // Business Account Invite List
  { path: "/business-account/inviteList/:id", component: BAInviteListTable },

  // Event Delete List
  { path: "/eventDelete", component: EventDeletelist },

  // Event graph   
  { path: "/eventGraphdetails/:id", component: EventGraphdetails },

  // YachtClub
  // { path: "/yachtclub", component: YachtClublist },
  // { path: "/notificationdetails/:id", component: Notificationdetails },
  // { path: "/Createyachtclub", component: CreateYachtClub },
  // { path: "/Updateyachtclub/:id", component: UpdateYachtClub },

  // { path: "/yachtclubdetails/:id", component: YachtClubdetails },

  // Yacht Management
  // { path: "/yacht", component: YachtManagementlist },
  // { path: "/notificationdetails/:id", component: Notificationdetails },
  // { path: "/Createyacht", component: CreateYacht },
  // { path: "/Updateyacht/:id", component: UpdateYacht },

  // Yacht Gallery Management
  // { path: "/yachtgallery", component: YachtGalleryManagementlist },
  // { path: "/Createyachtgallery", component: CreateYachtGallery },

  // Yacht Rating List
  // { path: "/yachtrating", component: YachtRatingManagementlist },
  // { path: "/updateyachtrating/:id", component: UpdateYachtRating },
  // { path: "/addyachtratingreply/:id", component: AddYachtRatingReply },

  // Yacht Follow List
  // { path: "/yachtfollow", component: YachtFollowManagementlist },

  // Bucket Management List
  // { path: "/bucketlist", component: BucketManagementList },
  // { path: "/updateyachtrating/:id", component: UpdateYachtRating },
  // { path: "/addyachtratingreply/:id", component: AddYachtRatingReply },

  // Outing Management List
  { path: "/outinglist", component: OutingManagementList },

  // Activity Management List
  { path: "/activityProvider", component: ActivityProviderlist },
  { path: "/createActivityProvider", component: CreateActivityProvider },
  { path: "/updateActivityProvider/:id", component: UpdateActivityProvider },

  // Activity Delete List
  { path: "/activityDelete", component: ActivityDeletelist },

  // Activity Type Management List
  { path: "/activityType", component: ActivityTypeList },
  { path: "/Createactivitytypedata", component: CreateActivityType },
  { path: "/Updateactivitytypedata/:id", component: UpdateActivityType },

  // Activity Management List
  { path: "/activityManagement", component: ActivityManagementlist },
  { path: "/Createactivitytypedata", component: CreateActivityType },
  { path: "/Updateactivitytypedata/:id", component: UpdateActivityType },

  // Activity Follow List
  { path: "/activityFollow", component: ActivityFollowManagementlist },

  // in-app notification
  { path: "/inAppNotification", component: InAppNotificationlist, permission: "notificationManagement" },
  { path: "/inAppNotificationCreate", component: NotificationForm, permission: "notificationManagement" },

  // Contact Us
  { path: "/contactUs", component: ContactUsList, permission: "contactUs" },
  { path: "/addMessage/:id", component: AddMessage, permission: "contactUs" },
  { path: "/messages", component: ContactUsMessages, permission: "contactUs" },

  // Special Offer List
  //{ path: "/specialOffer", component: SpecialOfferManagementlist },

  // Speccial Discount Delete List
  { path: "/specialOfferDelete", component: SpecialOfferDeletelist, permission: "offerManagement" },

  // Special Offer Claim List
  { path: "/SpecialOfferClaim", component: SpecialOfferClaimManagementlist, permission: "offerManagement" },
  { path: "/SpecialOfferClaim-statement", component: SpecialOfferClaimStatementlist },

  { path: "/dealSales-statement", component: VoucherClaimStatementlist },
  //Business Account Special Offer Claim List
  { path: "/business-account/SpecialOfferClaim", component: BASpecialOfferClaimManagementlist },

  { path: "/venue-account/SpecialOfferClaim", component: VASpecialOfferClaimManagementlist },



    
  // Special Offer List
  { path: "/specialoffer", component: SpecialOfferManagementlist, permission: "offerManagement" },
  // Business Account Special Offer List
  { path: "/business-account/specialoffer", component: BASpecialOfferManagementlist },

  { path: "/venue-account/specialoffer", component: VASpecialOfferManagementlist },

  // { path: "/specialoffer", component: SpecialOfferlist },
  { path: "/CreateSpecialOffer", component: CreateSpecialOffer, permission: "offerManagement" },
  // Business Account Create Special Offer
  { path: "/business-account/CreateSpecialOffer", component: BACreateSpecialOffer },
  { path: "/venue-account/CreateSpecialOffer", component: VACreateSpecialOffer },

  { path: "/UpdateSpecialOffer/:id", component: UpdateSpecialOffer, permission: "offerManagement" }, 
  // Business Account Update Special Offer
  { path: "/business-account/UpdateSpecialOffer/:id", component: BAUpdateSpecialOffer },
  { path: "/venue-account/UpdateSpecialOffer/:id", component: VAUpdateSpecialOffer },
  // Create Claim
  { path: "/createClaim", component: CreateClaim, permission: "offerManagement" },
  // package List
  { path: "/package", component: PackageList, permission: "packageManagement" },
  // Create package
  { path: "/Createpackagedata", component: CreatePackage },
  // Update package
  { path: "/Updatepackagedata/:id", component: UpdatePackage },

  // Venue Package List

  { path: "/venue/package", component: VenuePackageList },
  { path: "/venue/subscriptions", component: AddSubscription },
  { path: "/venue/create/subscriptions", component: CreateSubscription },
  { path: "/venue/update/subscriptions/:id", component: UpdateVenueSubscription },
  { path: "/venue/subscriptions/orders", component: VenueSubscriptionOrders },
  { path: "/venue/subscriptions/commissions", component: VenueSubscriptionCommission },

  // Create Venue Package
  { path: "/venue/package-create", component: CreateVenuePackage },

  // Update Venue Package
   { path: "/venue/package/:id", component: UpdateVenuePackage },


   { path: "/conversation", component: ConvoManagmentlist },

   { path: "/venue-account/conversation", component: VAConvoManagmentlist },


   { path: "/chat-window/:id", component: ChatWindow },

   { path: "/venue-account/chat-window", component: VAChatWindow },



  // // Deal List
  // { path: "/deals", component: DealList },
  // Voucher List
  { path: "/hot-deals", component: VoucherList, permission: "HotDealManagement" },
  // Business Account Voucher List
  { path: "/business-account/vouchers", component: BAVoucherList },

  { path: "/venue-account/vouchers", component: VAVoucherList },

  { path: "/hot-deals/create", component: CreateVoucher, permission: "HotDealManagement" },
  // Business Account Create Voucher
  { path: "/business-account/createvoucher", component: BACreateVoucher },
  { path: "/venue-account/createvoucher", component: VACreateVoucher },

  { path: "/hot-deals/update/:id", component: UpdateVoucher, permission: "HotDealManagement" },
  // Business Account Update Voucher
  { path: "/business-account/updatevoucher/:id", component: BAUpdateVoucher },
  { path: "/venue-account/updatevoucher/:id", component: VAUpdateVoucher },

  { path: "/customComponents", component: CustomComponentManagementlist, permission: "HomeBlockManagement" },
  // Create Custom Component
  { path: "/createCustomComponent", component: CreateCustomComponent, permission: "HomeBlockManagement" },
  // Update Custom Component
  { path: "/updateCustomComponent/:id", component: UpdateCustomComponent, permission: "HomeBlockManagement" },
  // Follow List
  { path: "/follows", component: FollowManagementlist },

  // Business Account Follow List
  { path: "/business-account/follows", component: BAFollowManagementlist },
  { path: "/venue-account/follows", component: VAFollowManagementlist },


  // Ads List
  { path: "/AdsList", component: AdsManagementlist },
  // Create Ads
  { path: "/createAd", component: CreateAd },
  //  Update Ads
  { path: "/updateAd/:id", component: UpdateAd },
  // Clone Ads
  { path: "/cloneAd", component: CloneAd },
  // Ad Logs List
  { path: "/adLogs", component: Adlogslist },
  // Ad Graph
  { path: "/adGraph/:id", component: AdGraphdetails },

  { path: "/orders", component: OrderList },

  { path: "/activityOrder", component: ActivityOrderManagementlist },

  { path: "/offerOrder", component: OfferOrderManagementlist, permission: "subscriptionManagement" },

  { path: "/offerCommission", component: OfferCommissionManagementlist, permission: "subscriptionManagement" },

  // Business Account Offer Order List
  { path: "/business-account/offerOrder", component: BAOfferOrderManagementlist },

  // Venue Account Offer Order List

  { path: "/venue-account/offerOrder", component: VAOfferOrderManagementlist },

  { path: "/venue-account/offerCommission", component: VAOfferCommissionManagementlist },


  { path: "/dealOrder", component: DealOrderManagementlist, permission: "dealManagement" },

  

];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/venue/login/token", component: VenueLoginToken },
  { path: "/venue-account/venue/login/token", component: VenueLoginToken },
  { path: "/business-account/login", component: Login },
  { path: "/venue-account/login", component: Login },
  { path: "/forgotPassword", component: AdminForgotPassword },
  { path: "/eventlogin", component: EventLogin },
  { path: "/eventForgotPassword", component: EventForgotPassword },
  { path: "/businesslogin", component: BusinessLogin },
  { path: "/businessForgotPassword", component: BusinessForgotPassword },
];

export { userRoutes, authRoutes };
