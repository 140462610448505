import Swal from "sweetalert2";
import { Button } from "reactstrap";

export const RBButton = Button;

const BaseURL =
  //"http://192.168.2.8:8443/v1/";
//"http://localhost:8443/v1/";          
     "https://api.7nightsuae.com/v1/"; 
    // "http://192.168.0.233:8443/v1/";
    // "http://54.147.9.230:8443/v1/";

const getheader = async () => {
  let token = await localStorage.getItem("token");
  let header = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  return header;
};


const getheaderForm = async () => {
  let token = await localStorage.getItem("token");
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return header;
};

export const postRequestForm = async (endpoint, body) => {
  let hearder = await getheaderForm();
  let url = BaseURL + endpoint;
  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "POST", headers: hearder, body: body })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export const callCSVRequest = async (endpoint, body) => {
  let hearder = await getheaderForm();
  let url = BaseURL + endpoint;
  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "POST", headers: hearder, body: body })
      .then((response) => response.blob())
      .then((blob) => resolve(blob));
  });
  return promise;
}


export const putRequestForm = async (endpoint, body) => {
  let hearder = await getheaderForm();
  let url = BaseURL + endpoint;
  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "PUT", headers: hearder, body: body })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export const postCustomRequestForm = async (endpoint, body) => {
  let hearder = await getheaderForm();
  let url = BaseURL2 + endpoint;
  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "POST", headers: hearder, body: body })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export const pushUpdates = async (body) => {
  try {
    let header = await getheader();
    let url = BaseURL + "chat/event/update";
    const response = await fetch(url, {
      method: "POST",
      headers: header,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in POST request:", error);
    throw error;
  }
};

// export const postRequest = async (endpoint, body) => {
//   let header = await getheader();
//   let url = BaseURL + endpoint;
//   const promise = new Promise(async (resolve, reject ) => {
//     await fetch(url, {
//       method: "POST",
//       headers: header,
//       body: JSON.stringify(body),
//     })
//       .then((response) => response.json())
//       .then((data) => resolve(data))
//       .catch((error) => {
//         reject(error);
//       }
//   });
//   return promise;
// };

export const postRequest = async (endpoint, body) => {
  try {
    let header = await getheader();
    let url = BaseURL + endpoint;
    const response = await fetch(url, {
      method: "POST",
      headers: header,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in POST request:", error);
    throw error;
  }
};

export const getRequest = async (endpoint, body = null) => {
  let header = await getheader();
  let url = BaseURL + endpoint;

  if (body != null) {
    const promise = new Promise(async (resolve) => {
      await fetch(url, {
        method: "Get",
        headers: header,
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
    return promise;
  }
  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "Get", headers: header })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export const deleteConfirmationWords = ["Delete", "Remove", "Erase"];

export const deleteRequest = async (endpoint, body) => {
  let header = await getheader();
  let url = BaseURL + endpoint;
  const promise = new Promise(async (resolve) => {
    await fetch(url, {
      method: "DELETE",
      headers: header,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export const putRequest = async (endpoint, body) => {
  let header = await getheader();
  let url = BaseURL + endpoint;
  const promise = new Promise(async (resolve) => {
    await fetch(url, {
      method: "PUT",
      headers: header,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export function DeleteConfirmDialog() {
  const promise = new Promise(async (resolve, reject) => {
    try {
      Swal.fire({
        title: "Are you sure delete?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        resolve(result);
      });
    } catch (msg) {
      reject(msg);
    }
  });
  return promise;
}

// export function convertTimeToFormattedString(timestampString) {
//   if (!timestampString) {
//     return timestampString;
//   }

//    return 

//   // remove Z from end of string

//   timestampString = timestampString?.replace("Z", "");

//   // Convert to Date object
//   const dateObj = new Date(timestampString);

//   // Check if the date object is valid
//   if (isNaN(dateObj.getTime())) {
//     return timestampString;
//   }

//   // Extract date, month, year, hours, and minutes
//   const date = String(dateObj.getDate()).padStart(2, "0");
//   const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
//   const year = dateObj.getFullYear();

//   let hours = dateObj.getHours();
//   const minutes = String(dateObj.getMinutes()).padStart(2, "0");

//   // Determine AM or PM
//   const ampm = hours >= 12 ? "PM" : "AM";

//   // Convert hours from 24-hour to 12-hour format
//   hours = hours % 12;
//   hours = hours ? hours : 12; // the hour '0' should be '12'

//   // Construct formatted string
//   return `${date}/${month}/${year} ${hours}:${minutes} ${ampm}`;
// }

export function convertTimeToFormattedString(timestampString) {
  try {
    if (!timestampString) {
      return "N/A"; // Return 'N/A' if input is null or undefined
    }

    // Remove 'Z' from the end of the string if it exists
    timestampString = timestampString.replace("Z", "");

    // Convert to Date object
    const dateObj = new Date(timestampString);

    // Check if the date object is valid
    if (isNaN(dateObj.getTime())) {
      throw new Error("Invalid date format"); // Throw an error if date is invalid
    }

    // Extract date, month, year, hours, and minutes
    const date = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = dateObj.getFullYear();

    let hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    // Determine AM or PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Construct formatted string
    return `${date}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  } catch (error) {
    console.error("Error formatting date:", error.message);
    return "Invalid Date"; // Return fallback text in case of error
  }
}


// Validator utility
const validateEmail = (email) => {
  // Standard Email Validation RegExp
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const validatePhone = (phone) => {
  var re = /^[0-9\b]+$/; // checks for numeric value
  return re.test(phone);
};

const validateDate = (date) => {
  return !isNaN(Date.parse(date));
};

const validateDateTime = (dateTime) => {
  const date = new Date(dateTime);
  return !isNaN(date.getTime()) && date.toISOString() === dateTime;
};

export const customValidator = (validaterObj, valueObj) => {
  try {
    for (let i = 0; i < validaterObj.length; i++) {
      const {
        field,
        required = true,
        type = "any",
        errorMsg,
      } = validaterObj[i];

      // Check if field is required and missing
      if (required && !valueObj[field]) {
        return { success: false, message: errorMsg || `${field} is required` };
      }

      // Field value
      const fieldValue = valueObj[field];
      // Type validation
      switch (type) {
        case "email":
          if (!validateEmail(fieldValue)) {
            return {
              success: false,
              message: errorMsg || "Invalid email format",
            };
          }
          break;
        case "phone":
          if (!validatePhone(fieldValue)) {
            return {
              success: false,
              message: errorMsg || "Invalid phone number format",
            };
          }
          break;
        case "not-null":
          if (!fieldValue) {
            return {
              success: false,
              message: errorMsg || "Invalid file format",
            };
          }
          break;
        case "date":
          if (!validateDate(fieldValue)) {
            return {
              success: false,
              message: errorMsg || "Invalid date format",
            };
          }
          break;
        case "date-time":
          if (!validateDateTime(fieldValue)) {
            return {
              success: false,
              message: errorMsg || "Invalid datetime format",
            };
          }
          break;
        case "string":
          if (typeof fieldValue !== "string") {
            return {
              success: false,
              message: errorMsg || `${field} must be a string`,
            };
          }
          break;
        case "number":
          if (typeof fieldValue !== "number") {
            return {
              success: false,
              message: errorMsg || `${field} must be a number`,
            };
          }
          break;
        case "boolean":
          if (typeof fieldValue !== "boolean") {
            return {
              success: false,
              message: errorMsg || `${field} must be a boolean`,
            };
          }
          break;
        case "array":
          if (!Array.isArray(fieldValue)) {
            return {
              success: false,
              message: errorMsg || `${field} must be an array`,
            };
          }
          break;
        case "object":
          if (typeof fieldValue !== "object") {
            return {
              success: false,
              message: errorMsg || `${field} must be an object`,
            };
          }
          break;
        // add other type validations as needed
      }
    }

    // If everything is good
    return { success: true };
  } catch (error) {
    return { success: false, message: "Something went wrong" };
  }
};
