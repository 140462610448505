import React, { useState } from "react";
import { Row, Col, Card, CardBody, Modal, Button, Form } from "reactstrap";

const FormComponent = ({ addData }) => {
  const [packageData, setPackageData] = useState({});

  const updateValue = (key, value) => {
    setPackageData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const addDataPackage = (e) => {
    e.preventDefault();

    // Validate required fields
    if (!packageData.title || packageData.title.trim() === "") {
      alert("Title is required");
      return;
    }

    if (
      packageData.qty === undefined ||
      packageData.qty === "" ||
      Number(packageData.qty) < 0
    ) {
      alert("Quantity is required and cannot be negative");
      return;
    }

    if (
      packageData.pricePerUnit === undefined ||
      packageData.pricePerUnit === "" ||
      Number(packageData.pricePerUnit) < 0
    ) {
      alert("Price Per Unit is required and cannot be negative");
      return;
    }

    // Create the package object with totalPrice calculated
    const pkg = {
      title: packageData.title,
      qty: Number(packageData.qty),
      pricePerUnit: Number(packageData.pricePerUnit),
      totalPrice: Number(packageData.qty) * Number(packageData.pricePerUnit),
    };

    addData(pkg);

    // Clear the form
    setPackageData({});
  };

  return (
    <>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody className="table-shorting">
              <Form onSubmit={addDataPackage}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Title
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("title", e.target.value)
                            }
                            value={packageData?.title || ""}
                            name="title"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Quantity
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            onChange={(e) =>
                              updateValue("qty", e.target.value)
                            }
                            value={packageData?.qty || ""}
                            name="qty"
                            min={0}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Price Per Unit
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            min={0}
                            onChange={(e) =>
                              updateValue("pricePerUnit", e.target.value)
                            }
                            value={packageData?.pricePerUnit || ""}
                            name="pricePerUnit"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="mt-5 mt-lg-4">
                        <div className="row justify-content-end">
                          <div className="col-sm-9">
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const PackageModal = ({ show, closeModal, addData }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Add Package
        </h5>
        <button
          onClick={closeModal}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <FormComponent addData={addData} />
      </div>
    </Modal>
  );
};

export default PackageModal;
