import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";



export default function JpSetting() {

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [vat, setVat] = useState(false);
  const [markup, setMarkup] = useState(false);

  let history = useHistory();

  const AdminGet = async () => {
    setLoader(true);
    try{
    const response = await postRequest(`user/admin/get-setting`, {});
    const data = response;
    setData(data.data)
    setVat(data.data.vat)
    setMarkup(data.data.markup)
   console.log(data)
    }catch(error){
      console.log("Failed to fetch data:", error)
    }finally{
    setLoader(false);
    }
  };
  useEffect(() => {
    AdminGet();
  }, []);

  const AdminSettingform = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    let formData=Object.fromEntries(data.entries())
    console.log(formData)
    const response = await postRequest("user/admin/update-setting", formData);
    console.log({response})
    if (response.status == 1) {
      toast.success(response.message);
      AdminGet()
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    console.log(vat);
  }, [vat]);
  useEffect(() => {
    console.log(markup);
  }, [markup]);

  return (
    <React.Fragment>
      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Settings</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Admin Settings</li>:{" "}
              <li className="breadcrumb-item">Admin Settings</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loader?
              <CardBody style={{height : "100px"}}>
              <Spinner
                  color="info"
                  style={{
                    height: '4rem',
                    width: '4rem',
                    position : "absolute",
                    left: "50%"
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            :
            <CardBody>
              <h4 className="card-title">Admin Settings</h4>

              <Form onSubmit={AdminSettingform}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Poll Block Disable Hours</label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            defaultValue={data.pollBlockDisableHours||""}
                            name="pollBlockDisableHours"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Apply VAT</label>
                            <div className="col-sm-6">
                              <select  defaultValue={data.vat} onChange={ (e) => setVat(e.target.value == "true" ? true : false) } name="vat"  class="form-select">
                                   <option> Select</option>
                                   <option value="true">Yes</option>
                                   <option value="false">No</option> 
                                 </select>
                            </div>                           
                        </div> 

                        {vat == true && (

                                <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  VAT %
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="number"
                                    min="0"
                                    max="99"                              
                                    defaultValue={data.vatPercentage||""}
                                    name="vatPercentage"
                                    className="form-control"
                                  />
                                </div>
                                </div>

                        )}

                      
                                <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                 Add Markup %
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="number"
                                    min="0"
                                    max="100"                              
                                    defaultValue={data.markupPercentage||""}
                                    name="markupPercentage"
                                    className="form-control"
                                  />
                                </div>
                                </div>

                       



                   

               
                      <div className="mt-5 mt-lg-4">
                        <div className="row justify-content-end">
                          <div className="col-sm-9">
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            }
          </Card>
        </Col>
      </Row>

    
    </React.Fragment>
  );
}
