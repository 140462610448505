import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
//import filter modal

import moment from "moment";
import FilterModal from "../../components/Modal/FilterModal";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
  deleteConfirmationWords,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import BucketList from "pages/UserManagement/BucketList";

const filterObj = {
  name: "",
  createdAt: "",
};

const NotificationUsers = ({ notification, handlerdelete }) => {


  const [modal_center, setmodal_center] = useState(false);
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedItems, setselectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [offer, setOffer] = useState();
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });



  const toggleModel = () => {
    setModel(!model);
  };
  const closeBucketModal = () => {
    setModel(false);
  };

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? true : false;

    const formData = new FormData();
    formData.append("userId", actId);
    formData.append("enable", status);

    const res = await putRequestForm("user/update", formData);

    if (res.status == 1) {
      toast.success("User Status updated Successfully");
      userManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (
          key === "first_name" ||
          key === "last_name" ||
          key === "email" ||
          key === "phone"
        ) {
          filterObject.type = "regex";
        } else if (key === "gender" || key === "enable") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    toggleModel();
    userManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    userManagment();
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function CreatedAt(cellContent, row) {
    let createdAt = row.createdAt;
    return (
      <React.Fragment>
        <div>
          <p>{moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")} </p>
        </div>
      </React.Fragment>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }
  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="img"
          width={"50px"}
        />
      </>
    );
  }

  function userFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p style={{ textAlign: "left" }}>
            <img
              className="avatar-sm rounded-circle"
              src={row.image}
              onClick={() => showLightBox(row.image)}
              alt="img"
              width={"50px"}
            />
            &nbsp;&nbsp;
            {row.first_name} {row.last_name}{" "}
          </p>
        </div>
      </React.Fragment>
    );
  }
  function platformFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="text-center">
          {row.platform === "ios" ? (
            <button type="button" className=" btn btn-dark text-light">
              {" "}
              <i className="fab fa-apple text-light"></i>
            </button>
          ) : (
            <>
              {row.platform === "android" ? (
                <button type="button" className=" btn btn-success text-light">
                  {" "}
                  <i className="fab fa-android text-light"></i>
                </button>
              ) : (
                <button type="button" className=" btn btn-warning text-light">
                  {" "}
                  <i className="fa fa-globe text-light"></i>
                </button>
              )}
            </>
          )}
        </div>
      </React.Fragment>
    );
  }

  //   function iconFormatter(cellContent, row) {
  //     return (
  //       <React.Fragment>
  //         <div className="d-flex gap-1">
  //           <button type="button" className=" btn btn-primary text-light">
  //             {" "}
  //             <Link
  //               to={{
  //                 pathname: `/userdetails/${row._id}`,
  //                 state: { row },
  //               }}
  //             >
  //               {" "}
  //               <i className="fal fa-eye text-light"></i>
  //             </Link>{" "}
  //           </button>

  //           <button
  //             type="button"
  //             className="btn btn-danger"
  //             onClick={() => handlerdelete(row._id)}
  //           >
  //             <i className="fal fa-trash  fs-5"></i>
  //           </button>
  //         </div>
  //       </React.Fragment>
  //     );
  //   }
  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "title",
      text: "Name",     
    },
    {
      dataField: "image",
      text: "Image",
      formatter: ImgFormatter,
      sort: false,
    },
    {
      dataField: "venueName",
      text: "Venue",
      sort: false,
    },  
    // {
    //   dataField: "platform",
    //   text: "Platform",
    //   formatter: platformFormatter,
    //   sort: false,
    // },

    // {
    //   dataField: "createdAt",
    //   text: "Created At",
    //   sort: true,
    //   formatter: CreatedAt,
    // },
    {
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, offer, row) {
    return (
      <React.Fragment>
        <div className="d-flex gap-1 justify-content-center align-item-center">

          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(offer._id)}
          >
            <i className="fal fa-trash  fs-5"></i>
          </button>
        </div>
      </React.Fragment>
    );
  }

  const userManagment = async (filterArgs = []) => {
    //setLoader(true);

    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder     
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }   

    setTotalPage(data?.count);
    setCurrentFPage(data?.page);
    setUserData(data?.list);
    setLoader(false);
  };

//   const handlerdelete = (id, ids) => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "You want to delete this user",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         showRandomWordDialog(id, ids);
//       }
//     });
//   };

  const showRandomWordDialog = (id, ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteRequestFunction(id, ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteRequestFunction = async (id, ids) => {
    const response = await deleteRequest("user/remove/admin", { userId: id });
    if (response.status === 1) {
      Swal.fire("Deleted!", "Deleted successfully");
      userManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("user/remove/admin", { ids: ids });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      userManagment();
      setselectedItems([]);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox", // or 'radio' for single selection
    clickToSelect: false, // enable click to select
    style: { background: "#c8e6c9" }, // background color for selected rows
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };
  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: notification?.length,
                        custom: false,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={notification}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable                                  
                                    remote={false}
                                    rowClasses={rowClasses}
                                    classes="table-custom-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          {/* <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row> */}
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <BucketList
        showBucketModal={model}
        closeBucketModal={closeBucketModal}
        offer={offer}
      />
    </>
  );
};

export default NotificationUsers;
