import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
// import filter modal
import FilterModal from "../../components/Modal/FilterModal";
import CustomAvatar from "../../components/Common/Avatar.js";

import {
  postRequest,
  deleteRequest,
  postRequestForm,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

const filterObj = {
  userName: "",
  orderName: "", 
  qty: "",
  price: "",
  commissionAmount: "",
  status: "",
  createdAt: "",
};

const BusinessManagementlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [businessdata, setbusinessdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);
  
  const [venues, setVenues] = useState([]);
  const [offers, setOffers] = useState([]);

  const [sort, setsort] = useState("createdAt");
  const [sortOrder, setsortOrder] = useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(20);
  const [image, setImage] = useState("");
  const [openLightbox, setOpenLightBox] = useState(false);

  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });

  useEffect(() => {
    businessManagment();
  }, [currentPage]);

  const businessManagmentSearch = async () => {
    if (currentPage === 1) {
      businessManagment();
      return;
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    if (currentPage === 1) {
      businessManagment();
      return;
    }
    setCurrentPage(1);
  }, [sizePerPageOrg, sort, sortOrder]);

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page !== currentPage) {
        setCurrentPage(page);
      }
    }
  };

  const updateFilter = (key, value) => {
    const filterObjCopy = { ...filter };
    filterObjCopy[key] = value;
    setFilter(filterObjCopy);
  };

  const fetchFilter = () => {
    const filterArgs = [];
    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        let filterObject;
        if (key === "business_name") {
          filterObject = { "business_id.name": filter["business_name"] };
        } else {
          filterObject = { [key]: filter[key]?.trim() };
        }
        if (
          key === "userName" ||
          key === "orderName" ||
          key === "qty" ||
          key === "price" ||
          key === "commissionAmount"
        ) {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        } else if (key === "venue") {
          filterObject.type = "venueId";
        } else if (key === "offer") {
          filterObject.type = "offerId";
        } else if (key === "startCreatedAt") {
          filterObject.type = "date-range-start";
          filterObject.name = "createdAt";
        } else if (key === "endCreatedAt") {
          filterObject.type = "date-range-end";
          filterObject.name = "createdAt";
        }
        filterArgs.push(filterObject);
      }
    });
    return filterArgs;
  };

  const applyFilter = () => {
    setFilterActive(true);
    const filterArgs = [];
    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        let filterObject;
        if (key === "business_name") {
          filterObject = { "business_id.name": filter["business_name"] };
        } else {
          filterObject = { [key]: filter[key]?.trim() };
        }
        if (key === "userName" || key === "orderName" || key === "qty" || key === "price") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }
        filterArgs.push(filterObject);
      }
    });
    toggleModel();
    if (currentPage === 1) {
      businessManagment(filterArgs);
    } else {
      setCurrentPage(1);
    }
  };

  const fetchOffers = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("venue/offer/list/admin", param);
    if (theme.status === 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, title: item.title };
      });
      setOffers(options);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  const fetchVenues = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("venue/list", param);
    if (theme.status === 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, title: item.name };
      });
      setVenues(options);
    }
  };

  useEffect(() => {
    fetchVenues();
  }, []);

  const resetFilter = () => {
    setFilterActive(false);
    setFilter({ ...filterObj });
    toggleModel();
    businessManagment(null, true);
  };

  function ThemeFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <Link
          to={{
            pathname: `/createBusinessTheme/${row._id}`,
            state: { row },
          }}
        >
          Theme
        </Link>
      </div>
    );
  }

  function WebsiteFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <a href={row.website} target="_blank" rel="noopener noreferrer">
          Website
        </a>
      </div>
    );
  }

  function dateFormatter(cellContent, row) {
    return row.createdAt;
    return (
      <div style={{ display: "flex" }}>
        {convertTimeToFormattedString(new Date(row.createdAt))}
      </div>
    );
  }

  function dateTimeFormatter(cellContent, row) {
    
    const orderDate = new Date(row.date);
    const formattedDate = convertTimeToFormattedString(orderDate);
    return (
      <div style={{ display: "flex" }}>
        {`${row.date} ${row.time}`}
      </div>
    );
  }

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.logo}
          onClick={() => showLightBox(row.logo)}
          alt="logo img"
          width={"50px"}
        />
      </>
    );
  }

  // FIXED UserFormatter: now using row.userDetails from API
  function UserFormatter(cellContent, row) {
    const user = row.userDetails;
    if (!user) {
      return <span>User Deleted</span>;
    }
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {user.image ? (
          <img
            src={user.image}
            onClick={() => showLightBox(user.image)}
            alt="User"
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        ) : (
          <CustomAvatar name={user.first_name || "N/A"} />
        )}
        <div style={{ marginLeft: "10px", fontWeight: "500" }}>
          {user.first_name} {user.last_name}
          <p style={{ margin: "0", fontSize: "0.85rem", color: "#555" }}>
            {user.email}
          </p>
        </div>
      </div>
    );
  }

  // Updated ItemFormatter:
  // For type "offer", we display offer details and, if available, package info.
  function ItemFormatter(cellContent, row) {
    let content;
    if (row.type === "offer") {
      content = row.offer ? row.offer : "Not Found";
    } else if (row.type === "activity") {
      content = row.activity ? row.activity : "Not Found";
      if (content && typeof content === "object") {
        content.title = content.name;
        content.image =
          content.galleries && content.galleries.length
            ? content.galleries[0]
            : "";
      }
    } else if (row.type === "deal") {
      content = row.deal ? row.deal : "Not Found";
    } else if (row.type === "table") {
      content = row.table ? row.table : "Not Found";
    } else {
      content = "Not Found";
    }

    if (content === "Not Found") {
      return <>Not Found</>;
    }

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {content.image ? (
          <img
            src={content.image}
            onClick={() => showLightBox(content.image)}
            alt="img"
            style={{
              width: "50px",
              height: "50px",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
        ) : (
          <CustomAvatar
            name={
              row.type === "table"
                ? "Table Booking"
                : content.title
                ? content.title
                : "N/A"
            }
          />
        )}
        <div style={{ marginLeft: "10px" }}>
          {row.type === "offer" && content && content.title && (
            <>
              <div>
                <strong>Offer:</strong> {content.title}
              </div>
              {row.package && row.package.title && (
                <div>
                  <strong>Package:</strong> {row.package.title}
                </div>
              )}
            </>
          )}
          {row.type === "deal" && content && content.title && (
            <>
              <div>
                <strong>Deal:</strong> {content.title}
              </div>
              {content.venue && content.venue.name && (
                <div>
                  <strong>Venue:</strong> {content.venue.name}
                </div>
              )}
            </>
          )}
          {row.type === "table" && content && content.date && (
            <>
              <div>
                <strong>Date:</strong>{" "}
                {convertTimeToFormattedString(new Date(content.date))}
              </div>
              <div>
                <strong>Time:</strong> {content.startTime} - {content.endTime}
              </div>
              {content.venue && content.venue.name && (
                <div>
                  <strong>Venue:</strong> {content.venue.name}
                </div>
              )}
            </>
          )}
          {row.type === "activity" && content && content.title && (
            <div>
              <strong>Activity:</strong> {content.title}
            </div>
          )}
        </div>
      </div>
    );
  }

  // New PaymentStatusFormatter for paymentStatus badge.
  function PaymentStatusFormatter(cellContent, row) {
    const status = cellContent ? cellContent.toLowerCase() : "unpaid";
    const style = {
      padding: "5px 10px",
      borderRadius: "5px",
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: status === "paid" ? "#28a745" : "#dc3545",
    };
    return <span style={style}>{cellContent}</span>;
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  function giftFormatter(cellContent, row) {
    return (
      <>
        {row.isGift === true ? (
          <button className="btn btn-active" type="button">
            Yes
          </button>
        ) : (
          <button className="btn btn-deactive" type="button">
            No
          </button>
        )}
      </>
    );
  }

  const components = [
    

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">User Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.userName}
          onChange={(e) => updateFilter("userName", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Qty</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.qty}
          onChange={(e) => updateFilter("qty", e.target.value)}
          name="phone"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Price</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.price}
          onChange={(e) => updateFilter("price", e.target.value)}
          name="price"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Commission Amount</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.commissionAmount}
          onChange={(e) => updateFilter("commissionAmount", e.target.value)}
          name="commissionAmount"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Status</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("status", e.target.value)}
          className="form-select"
          value={filter.status}
        >
          <option value="">Select Status</option>
          <option value="completed">Completed</option>
          <option value="initiated">Initiated</option>
        </select>
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Start Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.startCreatedAt}
          onChange={(e) => updateFilter("startCreatedAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">End Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.endCreatedAt}
          onChange={(e) => updateFilter("endCreatedAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,
    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  // Updated columns array:
  // Removed Venue Name and Offer Name columns.
  // Added Payment Status column.
  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "userDetails.name",
      formatter: UserFormatter,
      text: "User Name",
      sort: false,
    },

    {
      dataField: "type",     
      text: "Type",
      sort: false,
    },

    {
      dataField: "Item",
      formatter: ItemFormatter,
      text: "Order Detail",
      sort: false,
    },


    {
      dataField: "qty",
      text: "Qty",
      sort: false,
    },
    {
      dataField: "price",
      text: "Price",
      sort: false,
    },
    {
      dataField: "commissionAmount",
      text: "Commission Amount",
      sort: false,
    },
    {
      dataField: "paymentStatus",
      text: "Payment Status",
      formatter: PaymentStatusFormatter,
      sort: false,
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
    },
    {
      dataField: "createdAt",
      formatter: dateFormatter,
      text: "Created At",
      sort: false,
    },
  ];

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText("");
  };

  useEffect(() => {
    if (!resetSearch) return;
    if (currentPage === 1) {
      businessManagment([], true);
      return;
    }
    setCurrentPage(1);
  }, [resetSearch]);

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i className="fal fa-trash fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const businessManagment = async (filterArgs = [], resetFilterFlag = false) => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      //type: "offer", // Since this view is for offer orders.
      sortOrder: sortOrder,
    };
    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText.trim();
      }
    }
    if (filterActive && !filterArgs?.length && !resetFilterFlag) {
      payload.filterArgs = fetchFilter();
    }
    try {
      const response = await postRequest(`subscription/order/list/admin`, payload);
      const data = response.data;
      if (resetFilterFlag) {
        setResetSearch(false);
      }
      setTotalPage(data?.count || 0);
      setCurrentFPage(data.page || 1);
      setbusinessdata(data.list || []);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this business",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];
    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed && result.value === randomWord) {
        const response = await deleteRequest(`venue/business/delete`, {
          businessId: id,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          businessManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const [itemsdata, setItems] = useState([]);
  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];
    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("venue/business/delete", { ids: ids });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      businessManagment();
      setselectedItems([]);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    style: { background: "#c8e6c9" },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }
      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };

  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Order Management</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Order Management</li>:{" "}
                <li className="breadcrumb-item">Order List</li>
              </ol>
            </div>
          </Col>
      
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  <PaginationProvider
                    pagination={paginationFactory({
                      page: currentFPage,
                      sizePerPage: sizePerPageOrg,
                      totalSize: totalPage,
                      custom: true,
                    })}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <>
                        <ToolkitProvider
                          keyField="_id"
                          data={businessdata}
                          columns={columns}
                        >
                          {(props) => (
                            <Row>
                              <Col className="col-lg-12 d-flex mb-3">
                                <label className="mb-0 text-label">
                                  Page Size
                                </label>
                                <select
                                  value={sizePerPageOrg}
                                  onChange={handleChange}
                                  className="form-select"
                                  style={{ width: "10%" }}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                                <label className="mb-0 text-label ms-3">
                                  Sort By:
                                </label>
                                <select
                                  value={sort}
                                  onChange={handlesorting}
                                  className="form-select"
                                  style={{ width: "25%" }}
                                >
                                  <option value="qty">Qty</option>
                                  <option value="price">Price</option>
                                  <option value="status">Status</option>                                   
                                  <option value="createdAt">
                                    Created At
                                  </option>
                                </select>
                                <label className="mb-0 text-label ms-3">
                                  Sort Order:
                                </label>
                                <select
                                  value={sortOrder}
                                  onChange={handlesortOrder}
                                  className="form-select"
                                  style={{ width: "20%" }}
                                >
                                  <option value="asc">Ascending</option>
                                  <option value="desc">Descending</option>
                                </select>
                                <div className="filter-item">
                                  {selectedItems.length > 0 && (
                                    <button
                                      className="btn btn-danger mx-2"
                                      onClick={() => {
                                        handlerdeleteMultiple(selectedItems);
                                      }}
                                    >
                                      Delete Selected
                                    </button>
                                  )}
                                  <input
                                    type="search"
                                    placeholder="Search"
                                    value={searchText}
                                    className="form-control"
                                    onChange={(e) =>
                                      setSearchText(e.target.value)
                                    }
                                    style={{ width: "30%" }}
                                  />
                                  <button
                                    onClick={() => {
                                      businessManagmentSearch();
                                    }}
                                    type="button"
                                    className="btn btn-primary mx-2"
                                  >
                                    Search
                                  </button>
                                  <button
                                    type="button"
                                    onClick={handleReload}
                                    className="btn btn-danger"
                                  >
                                    Reset
                                  </button>
                                  <button
                                    type="button"
                                    onClick={toggleModel}
                                    className="btn btn-info mx-2"
                                  >
                                    Filter
                                  </button>
                                </div>
                              </Col>
      
                              <Col className="col-lg-12 text-end">
                                <BootstrapTable
                                  onTableChange={handleTableChange}
                                  noDataIndication={() => <div>No data available</div>}
                                  remote={true}
                                  selectRow={selectRow}
                                  rowClasses={rowClasses}
                                  classes="table-custom-striped"
                                  {...props.baseProps}
                                  {...paginationTableProps}
                                />
                              </Col>
                            </Row>
                          )}
                        </ToolkitProvider>
                        <Row>
                          <Col className="col-lg-12 text-end">
                            <div className="text-md-right">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </PaginationProvider>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default BusinessManagementlist;
