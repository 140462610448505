import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { parseISO, format } from "date-fns";
import OfferPackageModal from "../../components/Modal/OfferPackageModal.js";
import ReactCrop from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import OfferModal from "../../components/Modal/OfferModalMultiple.js";
import SelectedOfferList from "./SelectedOfferList.js";

import {
  Row,
  Col,
  Card,
  CardBody, 
  Form, 
  Spinner,
  Button
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  putRequestForm,
  postRequestForm,
  deleteRequest,
  pushUpdates,
  putRequest,
} from "../../components/Common/Utils.js";
//import DateTimePicker from 'react-datetime-picker';

import PackageModal from "components/Modal/PackageModalDeal.js";

import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import CancelPolicyModal from "../../components/Modal/CancelPolicyModal.js";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import EventOrgModal from "../../components/Modal/EventOrgModal.js";
import ActivityProviderModal from "../../components/Modal/ActivityProviderModal.js";



import Lightbox from "react-image-lightbox";
import IconPackModal from "components/Modal/IconPackModal.js";
import FileUpload from "components/Common/FileUpload.js";
export default function UpdateVoucher() {
  const location = useLocation();
  const blobUrlRef = useRef("");
  const state = location.state?.row;
  console.log("fix issue", state.packages);


  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [voucherData, setVoucherData] = useState({ type: "video",isTaxIncluded:"true" });
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [iconModal, setIconModal] = useState(false);

  const [selectedIcon, setSelectedIcon] = useState("");
  const [venueList, setVenueList] = useState([]);

  const [openPackage, setOpenPackage] = useState(false);
  const [venueDays, setVenueDays] = useState([]);
  const [rectangleImage, setRectangleImage] = useState(null);
  const [squareImage, setSquareImage] = useState(null);
  const [storyImage, setStoryImage] = useState(null);

  const [remoteRectangle, setRemoteRectangle] = useState(null);
  const [remoteSquare, setRemoteSquare] = useState(null);
  const [remoteStory, setRemoteStory] = useState(null);

  const [rectangleSelected, setrectangleSelected] = useState(0);
const [squareSelected, setSquareSelected] = useState(0);
const [storySelected, setStorySelected] = useState(0);

const [selectedBadge, setSelectedBadge] = useState("");



const [rectangleImagePreview, setRectangleImagePreview] = useState(state?.rectangleImage || null);
const [squareImagePreview, setSquareImagePreview] = useState(state?.squareImage || null);
const [storyImagePreview, setStoryImagePreview] = useState(state?.storyImage || null);

const [completedCrop2, setCompletedCrop2] = useState(null);
  const [completedCrop3, setCompletedCrop3] = useState(null);
  const [cropImage, setCropImage] = useState(null);

  const [dayList, setDayList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);

  const [defaultDays, setDefaultDays] = useState([]);

  const [listFetched, setListFetched] = useState(false);

  const [open, setOpen] = useState(false);
  const [packages, setPackages] = useState(state?.packages || []);

  const [model, setModel] = useState(false);
  const [modelOrg, setModelOrg] = useState(false);
  const [modelActivity, setModelActivity] = useState(false);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");

  const [eventOrgId, setEventOrgId] = useState("");
  const [eventOrgText, setEventOrgText] = useState("");
  const [eventOrgImage, setEventOrgImage] = useState("");

   const [venueTimings, setVenueTimings] = useState([]);

  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");

  const [policyModal, setPolicyModal] = useState(false);
  const [currentPolicyData, setCurrentPolicyData] = useState(null);
  const [policyRules, setPolicyRules] = useState([]);

   const [offerModel, setOfferModel] = useState(false);

   const [selectedOffers, setSelectedOffers] = useState([]);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [discountList, setDiscountList] = useState([]);
  const [startLoader, setStartLoader] = useState(false);

  
    const [openPackageModal, setOpenPackageModal] = useState(false);

  const [availableTime, setAvailableTime] = useState( "regular");
  const [timeSlots, setTimeSlots] = useState([]);

  const [regularTime, setRegularTime] = useState({
    start: "",
    end: "",
  });

  const togglePackageModal = () => {
    setOpenPackageModal(!openPackageModal);
  };

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  // const [crop, setCrop] = useState({
  //   unit: "px",
  //   x: 25,
  //   y: 25,
  //   width: 250,
  //   height: 250,
  //   aspect: 1,
  //   maxWidth: 250,
  //   maxHeight: 250,
  //   minHeight: 250,
  //   minWidth: 250,
  // });

  const [crop, setCrop] = useState({
    unit: "px",
    x: 0,
    y: 0,
    width: 270,
    height: 480,
    aspect: 9 / 16, // Enforces a 9:16 ratio
    maxWidth: 1080,
    maxHeight: 1920,
    minWidth: 270,
    minHeight: 480,
  });

  const [cropRectangle, setCropRectangle] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 540,
    height: 300,
    aspect: 1.8,
    maxWidth: 540,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 540,
  });


  const [cropSquare, setCropSquare] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 300,
    height: 300,
    aspect: 1,
    maxWidth: 300,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 300,
  });


  useEffect(() => {
      const fetch = async () => {
        let formData = new FormData();
        formData.append("file", selectedBadge);
        const response = await postRequestForm("comman/doc/upload", formData);
        if (response.status === 1) {
          setVoucherData({ ...voucherData, offer_badge: response.data.url });
        }
        console.log(response);
      };
    
      if (selectedBadge) {
        fetch();
      }
    }, [selectedBadge]); // Note the dependency array

  

  const updateCropRectangle = (crop) => {
    const aspectRatio = 1.8; // Desired aspect ratio (1.8:1)
    
    // Calculate the new width and height based on the aspect ratio
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }
  
    setCropRectangle(crop);
  };

  const updateCropSquare = (crop) => {
    const aspectRatio = 1; // Desired aspect ratio (1:1)

    // Calculate the new width and height based on the aspect ratio
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }

    setCropSquare(crop);
  };

  


  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    setVenueTimings(venue?.timings);

    const days = venue?.timings?.map((item) => item.day);
    setVenueDays(days);
    closeModalVenue();
  };


  useEffect(() => {
    if(voucherData?.isAddOffer == "false"){

      // remove offer ids
      setSelectedOffers([]);      
    }

  }, [voucherData?.isAddOffer]);

    useEffect(() => {
      //console.log("venueTimings", venueTimings);
  
      if(!voucherData?.markup)  return;
      let costPrice = voucherData?.discountedPrice - (voucherData?.discountedPrice * (voucherData?.markup / 100));
  
      console.log("costPrice", costPrice);
  
  
      setVoucherData({ ...voucherData, costPrice: costPrice });
  
    }, [voucherData?.markup]);





  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const selectOfferRow = (id) => { 
    console.log("id", id);
    setSelectedOffers([...id]);
    setOfferModel(false);
  };

  useEffect(() => {
    if (!venueDays) return;

    const fullDayNames = {
      mon: "Monday",
      tue: "Tuesday",
      wed: "Wednesday",
      thu: "Thursday",
      fri: "Friday",
      sat: "Saturday",
      sun: "Sunday",
    };

    const days = venueDays.map((abbr) => {
      const day = fullDayNames[abbr.toLowerCase()];
      return { id: abbr, name: day || abbr };
    });
    setDayList(days);
    setSelectedDay([]);
  }, [venueDays]);


  const selectRowEventOrg = (id) => {  
    setEventOrgId(id?._id);
    setEventOrgText(id?.name);
    setEventOrgImage(id?.logo);
    closeModalEventOrg();
  };

  const selectRowActivity = (id, name, venue) => {
    setActivityId(venue?._id);
    setActivityText(venue?.name);
    setActivityImage(venue?.logo);    
    closeModalActivity();
  };

  const closeIconModal = () => {
    setIconModal(false);
  };

  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };
  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };

  const selectIconRow = (icon) => {
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  const addRow = () => {
    setPackages([...packages, { icon: "", feature: "", iconName: "" }]);
  };

  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);
    setPackages(newPackages);
  };

  const closeModalVenue = () => {
    setModel(false);
  };

  const closeModalEventOrg = () => {
    setModelOrg(false);
  };

  const closeModalActivity = () => {
    setModelActivity(false);
  };

  const toggleModelEventOrg = () => {
    setModelOrg(!modelOrg);
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };

  const toggleModelActivity = () => {
    setModelActivity(!modelActivity);
  };

  const toggleModel = () => {
    setModel(!model);
  };  

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const msUpdateVenue = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedVenue(ids);
  };

  const updateRegulaTime = (type, value) => {
    const updatedSlots = { ...regularTime };
    updatedSlots[type] = value;
    setRegularTime(updatedSlots);
  };

  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedDay(ids);
  };

  const handleDeletePackage = (index) => {
    const newPackages = [...packages];
    newPackages.splice(index, 1);
    setPackages(newPackages);
  };

  useEffect(() => {
    if (!state) return;

    state.allowWhosin = state.allowWhosIn ? 1 : 0;    

    let days = state.days?.split(",") || [];
    state.isAddOffer = "false";
    if(state.offerList?.length){
      setSelectedOffers([...state.offerList]);
      state.isAddOffer = "true";
    }

    

    if (state?.features?.length) {
      const newPackages = state.features.map((item) => {
        return {
          icon: item.icon,
          feature: item.title,
          saved: true,
        };
      });
      setPackages(newPackages);
    }

    state.type = state.providerType;

    if(state.type == "venue"){      
      setVenueId(state?.venueId);
      setVenueText(state?.venueName);
      setVenueImage(state?.venueLogo);
    }

    if(state.type == "event-org"){
      setEventOrgId(state?.eventOrgId);
      setEventOrgText(state?.eventOrgName);
      setEventOrgImage(state?.eventOrgLogo);
    }

    if(state.type == "activity-provider"){
      setActivityId(state?.activityProviderId);
      setActivityText(state?.activityProviderName);
      setActivityImage(state?.activityProviderLogo);
    }


    let daysFull = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];

    // Filter days based on `days` array from state
    days = daysFull.filter((dayFull) => days.includes(dayFull.id));

    setDefaultDays(days);
    setPreviewImage(state.image);
    

    console.log("state timing", state?.dealTiming);

    if (state?.dealTiming?.type == "regular") {

      const time = state?.dealTiming?.time?.split("-");

      if(time?.length == 2) {
        state.start_time = time[0];
        state.end_time = time[1];
        setRegularTime({
          start: time[0],
          end: time[1],
        });
      }

      state.totalSeats = state?.dealTiming.totalSeats;

    }


    state.isTaxIncluded = state.isTaxIncluded ? "true" : "false";
    state.vat = state.vat || 0;

    setPackages(state?.packages || [])

    setVoucherData(state);
    
    //   state.totalSeats = state?.offerTiming.totalSeats;
    //   setAvailableTime("regular");
    // }

    // If you need to fetch data, call those functions here
    // fetchList();
    // fetchPackages();
  }, [state]);

  let history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (!selectedVenue.length) return;
    if (listFetched) return;

    fetchList();
  }, [selectedVenue]);

  useEffect(() => {
    fetchCategoryList();
    const days = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];
    setDayList(days);
  }, []);

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);

      const defaultItems = list.filter((item) => {
        return selectedVenue.includes(item.id);
      });
      setListFetched(true);
    }
    setLoading(false);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list?.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setCategoryList(list);
    }
    setLoading(false);
  };

  const addPolicyData = (data) => {
    console.log("cancel policy", data);
    let venueDt = {...voucherData}
    venueDt.cancelPolicy = data;
    data.unit = "day";
    setVoucherData(venueDt);   
  }


  const editPolicyData = (index) => {
    let currentPolicyRule = policyRules[index];
    setCurrentPolicyData(currentPolicyRule);
    setPolicyModal(true);
  }

  const deletePolicyData = (index) => {
    let policyLists = [...policyRules];
    policyLists.splice(index, 1);
    setPolicyRules(policyLists);
  }

  function closePolicyModal() {
    setPolicyModal(false);
  }

  const deleteJson = (event) => {
    event.preventDefault();
    setVoucherData({ ...voucherData, offer_badge: "" });
  } 


  const updateVoucher = async (e) => {
    e.preventDefault();
    //return;

    if (
      voucherData.discountedPrice > voucherData.actualPrice ||
      !voucherData.discountedPrice
    ) {
      if (voucherData.discountedPrice > voucherData.actualPrice) {
        toast.error("Discounted price should be less than actual price");
      } else {
        toast.error("Please enter discounted price");
      }
      return;
    }

    // check discounted value 

    // if (!voucherData.discountValue) {
    //   toast.error("Please enter discount value");
    //   return;
    // }

    // check discount value is less than 100

    if (voucherData.discountValue > 100) {
      toast.error("Discount value should be less than 100");
      return;
    }

    if(!voucherData.type){  
      toast.error("Please select type");
      return;
    }

    // check type venue && venueId is selected

    if(voucherData.type == "venue"){
      if(!venueId){
        toast.error("Please select venue");
        return;
      }
    }

    if(voucherData.type == "event-org"){
      if(!eventOrgId){
        toast.error("Please select event org");
        return;
      }
    }

    if(voucherData.type == "activity-provider"){
      if(!activityId){
        toast.error("Please select activity provider");
        return;
      }
    }   


    // check discount value is less than 0

    if (voucherData.discountValue < 0) {
      toast.error("Discount value should be greater than 0");
      return;
    }

    // check paxPerVoucher value must be 1 

    if (voucherData.paxPerVoucher < 1) {
      toast.error("Pax per voucher must be greater than 0");
      return;
    }


    if(voucherData.isTaxIncluded == "false"){
        if(voucherData.vat === ""){
          toast.error("Please enter VAT value");
          return
        }
    }

    // if (packages?.length < 2) {
    //   toast.error("Please select atleast 2 features");
    //   return;
    // }



    // for (let i = 0; i < packages.length; i++) {
    //   if (!packages[i].icon) {
    //     toast.error("Please select icon for feature " + (i + 1));
    //     return;
    //   }

    //   if (!packages[i].feature) {
    //     toast.error("Please enter title for feature " + (i + 1));
    //     return;
    //   }
    // }


   




    const newPackages = packages.map((item) => {
      return {
        title: item.feature,
        icon: item.icon,
      };
    });

    // combine date and time
    let startDate = null;
    let endDate = null;

    if (voucherData.startDate) {
      if (voucherData.startTime) {
        startDate = new Date(
          voucherData.startDate + " " + voucherData.startTime
        );
      } else {
        startDate = new Date(voucherData.startDate + " " + "00:00");
      }
    }

    if (voucherData.endDate) {
      if (voucherData.endTime) {
        endDate = new Date(voucherData.endDate + " " + voucherData.endTime);
      } else {
        endDate = new Date(voucherData.endDate + " " + "00:00");
      }
    }
    setStartLoader(true);
    const x = new FormData();
    var imgres = null;

    if (selectedImage) {
      x.append("image", selectedImage);
      imgres = await postRequestForm("comman/doc/upload", x);
    }




    setStartLoader(false);
    setLoading(true);

    const dataObj = {
      dealPackageId: id,
      title: voucherData.title,
      description: voucherData.description,      
      providerType: voucherData.type,
      categoryId: voucherData.categoryId,
      days: selectedDay.join(","),
      startDate: voucherData.startDate,
      startTime: voucherData.startTime,
      endDate: voucherData.endDate,
      endTime: voucherData.endTime,
      actualPrice: voucherData.actualPrice,
      discountedPrice: voucherData.discountedPrice,
      paxPerVoucher: voucherData.paxPerVoucher,
      offer_badge: voucherData.offer_badge || "",
      discountValue: voucherData.discountValue,
      buttonText: voucherData.buttonText,
      isTaxIncluded: voucherData.isTaxIncluded == "true" ? true : false,
      vat: voucherData.vat || 0,
      features: newPackages,
      packages: packages
    };

     let dealTiming = {};
            if (availableTime === "regular") {
              if (!regularTime?.start || !regularTime?.end) {
                toast.error("Please select regular time");
                return;
              }
              dealTiming = {
                type: "regular",
                time: `${regularTime?.start}-${regularTime?.end}`,
                totalSeats: voucherData?.totalSeats
              };
              dataObj.dealTiming = dealTiming;          
            }
    
            if(voucherData.cancelPolicy){         
              dataObj.cancelPolicy = voucherData.cancelPolicy; 
            }


    if(voucherData.type == "venue"){
      dataObj.venueId = venueId;
    }

    if(voucherData.type == "event-org"){
      dataObj.eventOrgId = eventOrgId;
    }

    if(voucherData.type == "activity-provider"){
      dataObj.activityProviderId = activityId;
    }

    if (imgres?.data?.url) {
      dataObj.image = imgres?.data?.url;
    }

    console.log("images", remoteRectangle, remoteSquare, remoteStory);

    const uploadPromises = [
      selectedImage ? postRequestForm("comman/img/upload", createFormData("image", selectedImage)) : Promise.resolve(null),
      remoteRectangle ? postRequestForm("comman/img/upload", createFormData("image", remoteRectangle)) : Promise.resolve(null),
      remoteSquare ? postRequestForm("comman/img/upload", createFormData("image", remoteSquare)) : Promise.resolve(null),
      remoteStory ? postRequestForm("comman/img/upload", createFormData("image", remoteStory)) : Promise.resolve(null)
    ];

    const [imageResponse, rectangleResponse, squareResponse, storyResponse] = await Promise.all(uploadPromises);

    // appendFormData(formData, {
    //   image: imageResponse?.data?.url || "",
    //   rectangleImage: rectangleResponse?.data?.url || "",
    //   squareImage: squareResponse?.data?.url || "",
    //   storyImage: storyResponse?.data?.url || "",
    // });

    dataObj.image = imageResponse?.data?.url || "";
    dataObj.rectangleImage = rectangleResponse?.data?.url || "";
    dataObj.squareImage = squareResponse?.data?.url || "";
    dataObj.storyImage = storyResponse?.data?.url || "";

    dataObj.offerIds = selectedOffers.map((offer) => offer._id);

    dataObj.markup = voucherData.markup;
    dataObj.costPrice = voucherData.costPrice;    




    const response = await postRequest(
      "homeblock/deal-package/update",
      dataObj
    );
    setLoading(false);

    if (response.status == 1) {
      // If the update is successful, call the pushUpdates function
      toast.success("Voucher updated successfully");

      setTimeout(() => {
        // history.push("/offer");
        history.goBack();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  
  const createFormData = (key, file) => {
    const formData = new FormData();
    formData.append(key, file);
    return formData;
  };

  const handleDeleteOffer = async (id) => {

    let tempOffers = [...selectedOffers];
    tempOffers = tempOffers.filter((item) => item._id !== id);
    setSelectedOffers(tempOffers);
  
  
   }

  const updateValue = (index, value) => {
    const list = { ...voucherData };
    if (index == 'discountValue') {
      if (!list.actualPrice) {
        toast.error("Please Enter Actual Price First");
        return false
      }
      list['discountedPrice'] = list?.actualPrice - (value * list?.actualPrice) / 100;
    }
    if (index == 'actualPrice') {
      if (list.discountValue) {
        list['discountedPrice'] = value - (list.discountValue * value) / 100;
      }else{
        list['discountedPrice'] = value 
     }
    }
    
    list[index] = value;
    setVoucherData(list);
  };

  const addPackage = () => {
    setOpenPackage(true);
    // setPackages([
    //   ...packages,
    //   { title: "", amount: "", discount: "", qty: "",description:"", isFeatured: false },
    // ]);
  };

  const addPackageData = (data) => {
    setPackages([...packages, data]);
    setOpenPackage(false);
  };

  const updatePackageData = (index, field, value) => {
    const newPackages = [...packages];
    newPackages[index][field] = value;
    setPackages(newPackages);
  };

  const closePackageModal = () => {
    setOpenPackage(false);
  };

  const handleRectangleImageChange = (e) => {
    const file = e.target.files[0];
    setRectangleImage(file);
    setRectangleImagePreview(URL.createObjectURL(file));
  };
  
  const handleSquareImageChange = (e) => {
    const file = e.target.files[0];
    setSquareImage(file);
    setSquareImagePreview(URL.createObjectURL(file));
  };
  
  const handleStoryImageChange = (e) => {
    const file = e.target.files[0];
    setStoryImage(file);
    setStoryImagePreview(URL.createObjectURL(file));
  };

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], voucherData.title + "-rectangle.jpg", {
        type: "image/jpeg",
      });
      setRemoteRectangle(file);
    });
  }, [rectangleSelected]);

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], voucherData.title + "-square.jpg", {
        type: "image/jpeg",
      });

      setRemoteSquare(file);
    });
  }, [squareSelected]);

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], voucherData.title + "-story.jpg", {
        type: "image/jpeg",
      });

      setRemoteStory(file);
    });

  }, [storySelected]);

  const handleCropComplete2 = (crop) => {
    setCompletedCrop2(crop);
  };

  const handleCropComplete3 = (crop) => {
    setCompletedCrop3(crop);
  };

  const handleCropComplete4 = (crop) => {
    setCropImage(crop);
  };

    const handleSavePackage = (packageData) => {
      // Validation: Quantity and Price per Unit must not be negative
      if (packageData.qty < 0 || packageData.pricePerUnit < 0) {
        toast.error("Quantity and Price per Unit must not be negative");
        return;
      }
      // Calculate totalPrice (for preview purposes; backend will recalc)
      packageData.totalPrice = packageData.qty * packageData.pricePerUnit;
      setPackages([...packages, packageData]);
      setOpenPackageModal(false);
    };

  useDebounceEffect(
    async () => {
      if (
        completedCrop2?.width &&
        completedCrop2?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop2,
          scale,
          rotate
        );

        setrectangleSelected(rectangleSelected + 1);
      }
    },
    100,
    [completedCrop2]
  );

  useDebounceEffect(
    async () => {
      if (
        completedCrop3?.width &&
        completedCrop3?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop3,
          scale,
          rotate
        );
        setSquareSelected(squareSelected + 1);
      }
    },
    100,
    [completedCrop3]
  );

  useDebounceEffect(
    async () => {
      if (
        cropImage?.width &&
        cropImage?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          cropImage,
          scale,
          rotate
        );
        setStorySelected(storySelected + 1);
      }
    },
    100,
    [cropImage]
  );


  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Hot Deals Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Hot Deals</li>:{" "}
              <li className="breadcrumb-item">Update</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Voucher </h4>
                <Form onSubmit={updateVoucher}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              value={voucherData.title}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={voucherData.description}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Category
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("categoryId", e.target.value)
                              }
                              value={voucherData.categoryId}
                              name="categoryId"
                              class="form-select"
                            >
                              <option value="">Select Category</option>
                              {categoryList?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              // defaultValue={"venue"}
                              value={voucherData.type}
                              name="categoryId"
                              class="form-select"
                            >
                              <option value="">Select Type</option>                             
                              <option value="venue">Venue</option>
                            </select>
                          </div>
                        </div>


                        {
                          voucherData.type == "venue" && ( */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Venue
                            </label>
  
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModel()}
                            >
                              {venueText ? (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readOnly
                                    value={venueText}
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              ) : (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {/* )
                        } */}

                    <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Want to add Offers
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("isAddOffer", e.target.value)
                            }
                            value={voucherData?.isAddOffer}
                            className="form-select"
                          >
                            
                            <option value="false" selected>No</option>
                            <option value="true">
                              Yes
                            </option>
                          </select>
                        </div>
                      </div>  

                      {voucherData?.isAddOffer == "true" && (
                        <>
                             <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Offers
                          </label>
                            <div className="col-sm-6">

                              <Button onClick={() => toggleOfferModel()}>
                                Choose Offers
                              </Button>
                              
                            </div>
                        </div>
                        </>
                      )}


                      {selectedOffers && selectedOffers.length > 0 && (

                                  <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Selected Offers
                                  </label>
                                    <div className="col-sm-6">

                                    <SelectedOfferList notification={selectedOffers} handlerdelete={handleDeleteOffer} />
                                      
                                    </div>
                                  </div>

                       
                      )}


                        {
                          voucherData.type == "event-org" && (
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Event Org
                            </label>
  
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModelEventOrg()}
                            >
                              {eventOrgText ? (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModelEventOrg()}
                                  >
                                    Choose Event Org
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={eventOrgImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readOnly
                                    value={eventOrgText}
                                    id="selectedVenue"
                                    placeholder="No Event org chosen"
                                  />
                                </div>
                              ) : (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModelEventOrg()}
                                  >
                                    Choose Event Org
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No Event org chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          )
                        } 

{
                          voucherData.type == "activity-provider" && (
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Activty Provider
                            </label>
  
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModelActivity() }
                            >
                              {activityText ? (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModelActivity()}
                                  >
                                    Choose Activty Provider
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={activityImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readOnly
                                    value={activityText}
                                    id="selectedVenue"
                                    placeholder="No Activity Provider chosen"
                                  />
                                </div>
                              ) : (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModelActivity()}
                                  >
                                    Choose Activty Provider
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No Activity Provider chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          )
                        }  

                       


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Days
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={dayList}
                              onSelect={msUpdateDay}
                              onRemove={msUpdateDay}
                              selectedValues={defaultDays}
                            />
                          </div>
                        </div>

                        
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Actual Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("actualPrice", e.target.value)
                              }
                              value={voucherData.actualPrice}
                              name="actualPrice"
                              min={0}
                              className="form-control"
                            />
                          </div>
                        </div>{" "}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Value (In Percent)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("discountValue", e.target.value)
                              }
                              value={voucherData.discountValue}
                              name="discountValue"
                              min={5}
                              max={100}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discounted Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("discountedPrice", e.target.value)
                              }
                              value={voucherData.discountedPrice}
                              name="discountedPrice"
                              min={0}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>{" "}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Markup Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("markup", e.target.value)
                              }
                              value={voucherData.markup}
                              name="markup"
                              min={0}
                              max={100}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Cost Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              value={voucherData.costPrice}
                              onChange={(e) =>
                                updateValue("costPrice", e.target.value)
                              }
                              name="costPrice"
                              // min={0}                        
                             
                              className="form-control"
                            />
                          </div>
                        </div>{" "}
                      
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Per Person Voucher
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("paxPerVoucher", e.target.value)
                              }
                              value={voucherData.paxPerVoucher}
                              name="paxPerVoucher"                              
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("startDate", e.target.value)
                              }
                              name="startDate"
                              value={voucherData.startDate}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) =>
                                updateValue("startTime", e.target.value)
                              }
                              name="startTime"
                              value={voucherData.startTime}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("endDate", e.target.value)
                              }
                              name="endDate"
                              value={voucherData.endDate}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) =>
                                updateValue("endTime", e.target.value)
                              }
                              name="endTime"
                              value={voucherData.endTime}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                           Select Available Time for Booking
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-select"
                              onChange={(e) => setAvailableTime(e.target.value)}
                              value={availableTime || "regular"}

                            >
                              <option value="">Select Time</option>
                              <option value="regular">Regular</option>
                              {/* <option value="slot">Multiple Slot</option> */}
                            </select>
                          </div>
                        </div>

                      
                          
                           <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Quantity of Seats
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    updateValue("totalSeats", e.target.value)
                                  }
                                  defaultValue={voucherData?.totalSeats}
                                  name="discount"
                                  className="form-control"
                                />
                              </div>
                            </div> 

                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Start Time / End Time
                              </label>
                              <div className="col-sm-3">
                                <label>Start Time:</label>
                                <input
                                  type="time"
                                  onChange={(e) =>
                                    updateRegulaTime("start", e.target.value)
                                  }
                                  className="form-control"
                                  defaultValue={regularTime.start}
                                />
                              </div>
                              <div className="col-sm-3">
                                <label>End Time:</label>
                                <input
                                  type="time"
                                  onChange={(e) =>
                                    updateRegulaTime("end", e.target.value)
                                  }
                                  className="form-control"
                                  defaultValue={regularTime.end}
                                />
                              </div>
                            </div>
                        {/* )} */}

                        {/* {availableTime === "slot" && (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Select Slots
                            </label>

                            <div className="col-sm-6">
                              {timeSlots.map((slot, index) => (
                                <div className="col-sm-12" key={index}>
                                  <div
                                    class="row"
                                    style={{ marginBottom: "16px" }}
                                  >
                                    <div className="col-sm-3">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={slot.start}
                                        onChange={(e) =>
                                          updateSlot(
                                            index,
                                            "start",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="col-sm-3">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={slot.end}
                                        onChange={(e) =>
                                          updateSlot(
                                            index,
                                            "end",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="col-sm-3">
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={slot.totalSeats}
                                        onChange={(e) =>
                                          updateSlot(
                                            index,
                                            "totalSeats",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="col-sm-3">
                                      <button
                                        type="button"
                                        style={{
                                          color: "white",
                                          backgroundColor: "red",
                                          border: "none",
                                          padding: "10px 20px",
                                        }}
                                        onClick={() => deleteSlot(index)}
                                      >
                                        Delete Slot
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}

                              <button
                                type="button"
                                style={{
                                  color: "white",
                                  backgroundColor: "green",
                                  border: "none",
                                  padding: "10px 20px",
                                }}
                                onClick={addSlot}
                              >
                                Add Slot
                              </button>
                            </div>
                          </div>
                        )} */}

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Rectangle Image</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleRectangleImageChange} name="rectangleImage" className="form-control" />
                          <br />                         

                          {rectangleImagePreview && rectangleImage  ? (
                             <ReactCrop
                             crop={cropRectangle}
                             src={rectangleImagePreview}
                             onChange={(newCrop) => updateCropRectangle(newCrop)}
                             onComplete={handleCropComplete2}
                             style={{
                               maxWidth: "720px",
                               maxHeight: "600px",
                             }}
                             >                             
                              <img ref={imgRef} src={rectangleImagePreview} />
                            </ReactCrop>
                         
                          ) : rectangleImagePreview ? (
                            <img
                              style={{ width: "180px", height: "100px" }}
                              src={rectangleImagePreview}
                              onClick={() => showLightBox2(rectangleImagePreview)}
                            />
                          ) : null}

                          {completedCrop2 && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: completedCrop2.width,
                                  height: completedCrop2.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Square Image</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleSquareImageChange} name="squareImage" className="form-control" />
                          <br />
                          {squareImagePreview && squareImage ? (
                             <ReactCrop
                             crop={cropSquare}
                             src={squareImagePreview}
                             onChange={(newCrop) => updateCropSquare(newCrop)}
                             onComplete={handleCropComplete3}
                             style={{
                               maxWidth: "720px",
                               maxHeight: "600px",
                             }}
                             >
                             
                             <img ref={imgRef} src={squareImagePreview} />
                            </ReactCrop>
                            
                          ): squareImagePreview ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={squareImagePreview}
                              onClick={() => showLightBox2(squareImagePreview)}
                            />
                          ) : null}

                          {completedCrop3 && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: completedCrop3.width,
                                  height: completedCrop3.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Story Image</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleStoryImageChange} name="storyImage" className="form-control" />
                          <br />
                          {storyImagePreview && storyImage ? (
                            <ReactCrop
                            crop={crop}
                            src={storyImagePreview}
                            onChange={(newCrop) => setCrop(newCrop)}
                            onComplete={handleCropComplete4}
                            style={{
                              maxWidth: "720px",
                              maxHeight: "600px",
                            }}
                            >
                            
                            <img ref={imgRef} src={storyImagePreview} />
                           </ReactCrop>
                           
                          ): storyImagePreview ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={storyImagePreview}
                              onClick={() => showLightBox2(storyImagePreview)}
                            />
                          ) : null}

                          {cropImage && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: cropImage.width,
                                  height: cropImage.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Is Tax Included
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("isTaxIncluded", e.target.value)
                            }
                            value={voucherData.isTaxIncluded}
                            className="form-select"
                          >
                            <option value="true">
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>

                      {voucherData.isTaxIncluded == "false" && (
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">VAT %</label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              min="0"
                              max="99"
                              onChange = {(e) => updateValue("vat", e.target.value)}
                              // defaultValue={offerData?.vat || ""}
                              
                              name="vat"
                              value={voucherData.vat}
                              className="form-control"
                            />
                          </div>
                        </div>
                      )}


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Button Text
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("buttonText", e.target.value)
                              }
                              value={voucherData.buttonText}
                              name="buttonText"
                              min={0}
                              className="form-control"
                            />
                          </div>
                        </div>

                        

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Feature List
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Icon</th>
                                  <th>Select Feature</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding:
                                            " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          borderRadius: "0.25rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <div style={{ minWidth: "100%" }}>
                                          {item?.icon && (
                                            <img
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              src={item.icon}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}

                                          {!item?.icon && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "tomato",
                                                border: "none",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Select Icon
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${
                                          item?.feature || item?.title || ""
                                        }`}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "feature",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => savePackage(index)}
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() => deletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addRow}
                                    >
                                      Add Feature
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Packages</label>
                          <div className="col-sm-6">
                            <button
                              type="button"
                              className="btn btn-primary mb-3"
                              onClick={togglePackageModal}
                            >
                              Add Package
                            </button>
                            {packages.length > 0 && (
                              <table className="table mt-3">
                                <thead>
                                  <tr>
                                    <th>Title</th>
                                    <th>Quantity</th>
                                    <th>Price Per Unit</th>
                                    <th>Total Price</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {packages.map((pkg, index) => (
                                    <tr key={index}>
                                      <td>{pkg.title}</td>
                                      <td>{pkg.qty}</td>
                                      <td>{pkg.pricePerUnit}</td>
                                      <td>{pkg.qty * pkg.pricePerUnit}</td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() => handleDeletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Cancellation Policy
                          </label>
                          <div className="col-sm-8">
                            <button
                              type="button"
                              onClick={() => setPolicyModal(true)}
                              className="btn btn-primary mb-3"
                            >
                              Update Cancellation Policy
                            </button> 
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Offer Badge (lottei animation JSON)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedBadge(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br /> 

                            { voucherData?.offer_badge?.length ? (
                            <>   
                                <div>                        
                                  <a href={voucherData.offer_badge} target="_blank"> Download </a> &nbsp;&nbsp;&nbsp;&nbsp;
                                  <a href="#" onClick={deleteJson} >  Remove </a>
                                </div> 
                            </>
                              
                            ) : null} 


                          </div>

                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

      <VenueModal
        show={model}
        closeModal={closeModalVenue}
        selectRow={selectRow}
      />

      <EventOrgModal
        show={modelOrg}
        closeEventOrgModal={closeModalEventOrg}
        selectEventOrgRow={selectRowEventOrg}
      />

      <ActivityProviderModal
        show={modelActivity}
        closeModal={closeModalActivity}
        selectRow={selectRowActivity}
      />

      
<OfferModal
          show={offerModel}
          closeOfferModal={closeOfferModal}
          selectRow={selectOfferRow}
          venueId={venueId}         
        />


      <IconPackModal
        show={iconModal}
        closeModal={closeIconModal}
        selectRow={selectIconRow}
      />

      <OfferPackageModal
        show={openPackage}
        closeModal={closePackageModal}
        addData={addPackageData}
      />

     <CancelPolicyModal
        show={policyModal}
        closeModal={closePolicyModal}
        addData={addPolicyData} 
        currentPolicyData={voucherData?.cancelPolicy}       
      />

      {openPackageModal && (
        <PackageModal
          show={openPackageModal}
          closeModal={() => setOpenPackageModal(false)}
          addData={handleSavePackage}
        />
      )}


      {/* modal */}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
