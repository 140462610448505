import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner, Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  postRequestForm,
  pushUpdates,
} from "../../components/Common/Utils.js";
import ReactCrop from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import PackageModal from "components/Modal/PackageModalDeal.js";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import Lightbox from "react-image-lightbox";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import OfferModal from "../../components/Modal/OfferModalMultiple.js";
import EventOrgModal from "../../components/Modal/EventOrgModal.js";
import ActivityProviderModal from "../../components/Modal/ActivityProviderModal.js";
import CancelPolicyModal from "../../components/Modal/CancelPolicyModal.js";
import OfferPackageModal from "../../components/Modal/OfferPackageModal.js";
import IconPackModal from "../../components/Modal/IconPackModal.js";
import FileUpload from "../../components/Common/FileUpload.js";

import SelectedOfferList from "./SelectedOfferList.js";

export default function CreateVoucher() {
  const location = useLocation();
  const state = location.state?.row;
  const [offerData, setHomeBlockData] = useState({ type: "video" });
  const [offerModel, setOfferModel] = useState(false);
  const [validTimeRange, setValidTimeRange] = useState({ startTime: '', endTime: '', range: '' });
  const [dateInputValue, setDateInputValue] = useState('');
  const [voucherData, setVoucherData] = useState({ type: "video", isTaxIncluded: "true" });
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [openLightbox, setOpenLightBox] = useState(false);
  const [venueList, setVenueList] = useState([]);
  const [iconModal, setIconModal] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [openFeature, setOpenFeature] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState("");
  const [currentPolicyData, setCurrentPolicyData] = useState(null);
  const [policyRules, setPolicyRules] = useState([]);
  const [venueTimings, setVenueTimings] = useState([]);
  const [dayList, setDayList] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [venueDays, setVenueDays] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryText, setCategoryText] = useState("");
  const [rectangleImage, setRectangleImage] = useState(null);
  const [squareImage, setSquareImage] = useState(null);
  const [storyImage, setStoryImage] = useState(null);
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);
  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(false);
  const [modelOrg, setModelOrg] = useState(false);
  const [modelActivity, setModelActivity] = useState(false);
  const [features, setFeatures] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [eventOrgId, setEventOrgId] = useState("");
  const [eventOrgText, setEventOrgText] = useState("");
  const [eventOrgImage, setEventOrgImage] = useState("");
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [discountList, setDiscountList] = useState([]);
  const [startLoader, setStartLoader] = useState(false);
  const blobUrlRef = useRef("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [rectangleImagePreview, setRectangleImagePreview] = useState(null);
  const [squareImagePreview, setSquareImagePreview] = useState(null);
  const [storyImagePreview, setStoryImagePreview] = useState(null);
  const [completedCrop2, setCompletedCrop2] = useState(null);
  const [completedCrop3, setCompletedCrop3] = useState(null);
  const [cropImage, setCropImage] = useState(null);
  const [remoteRectangle, setRemoteRectangle] = useState(null);
  const [remoteSquare, setRemoteSquare] = useState(null);
  const [remoteStory, setRemoteStory] = useState(null);
  const [rectangleSelected, setrectangleSelected] = useState(0);
  const [squareSelected, setSquareSelected] = useState(0);
  const [storySelected, setStorySelected] = useState(0);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [availableTime, setAvailableTime] = useState("regular");
  const [timeSlots, setTimeSlots] = useState([]);
  const [regularTime, setRegularTime] = useState({
    start: "",
    end: "",
  });
  const [crop, setCrop] = useState({
    unit: "px",
    x: 0,
    y: 0,
    width: 270,
    height: 480,
    aspect: 9 / 16,
    maxWidth: 1080,
    maxHeight: 1920,
    minWidth: 270,
    minHeight: 480,
  });
  const [cropRectangle, setCropRectangle] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 540,
    height: 300,
    aspect: 1.8,
    maxWidth: 540,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 540,
  });
  const [cropSquare, setCropSquare] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 300,
    height: 300,
    aspect: 1,
    maxWidth: 300,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 300,
  });
  const today = new Date().toISOString().split("T")[0];

  // NEW: Packages state and modal control
  const [packages, setPackages] = useState([]);
  const [openPackageModal, setOpenPackageModal] = useState(false);

  const togglePackageModal = () => {
    setOpenPackageModal(!openPackageModal);
  };

  const handleSavePackage = (packageData) => {
    // Validation: Quantity and Price per Unit must not be negative
    if (packageData.qty < 0 || packageData.pricePerUnit < 0) {
      toast.error("Quantity and Price per Unit must not be negative");
      return;
    }
    // Calculate totalPrice (for preview purposes; backend will recalc)
    packageData.totalPrice = packageData.qty * packageData.pricePerUnit;
    setPackages([...packages, packageData]);
    setOpenPackageModal(false);
  };

  const handleDeletePackage = (index) => {
    const newPackages = [...packages];
    newPackages.splice(index, 1);
    setPackages(newPackages);
  };

  // Remaining functions and useEffects...


  useEffect(() => {
    //console.log("venueTimings", venueTimings);

    if(!voucherData?.markup)  return;
    let costPrice = voucherData?.discountedPrice - (voucherData?.discountedPrice * (voucherData?.markup / 100));

    console.log("costPrice", costPrice);


    setVoucherData({ ...voucherData, costPrice: costPrice });

  }, [voucherData?.markup]);




  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const selectOfferRow = (id) => { 
    setSelectedOffers([...id]);
    setOfferModel(false);
  };

  useEffect(() => {
    if (!selectedDay.length) return;
    const calculateValidTimeRange = (selected) => {
      const filteredTimings = venueTimings.filter(timing => selected.includes(timing.day));
      const maxStartTime = Math.max(...filteredTimings.map(timing => new Date(`1970/01/01 ${timing.openingTime}`).getTime()));
      const minEndTime = Math.min(...filteredTimings.map(timing => new Date(`1970/01/01 ${timing.closingTime}`).getTime()));
      const validStartTime = new Date(maxStartTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
      const validEndTime = new Date(minEndTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
      const validRange = `${validStartTime} - ${validEndTime}`;
      setValidTimeRange({ startTime: validStartTime, endTime: validEndTime, range: validRange });
    };
    calculateValidTimeRange(selectedDay);
  }, [selectedDay, venueTimings]);

  useEffect(() => {
    if (!selectedDay.length) return;
    const getNextDate = (days) => {
      const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      let today = new Date();
      let nextDate = new Date();
      for (let i = 0; i < 7; i++) {
        const dayIndex = (today.getDay() + i) % 7;
        const dayName = daysOfWeek[dayIndex];
        if (days.includes(dayName)) {
          nextDate.setDate(today.getDate() + i);
          return nextDate.toISOString().split('T')[0];
        }
      }
      return today.toISOString().split('T')[0];
    };
    const nextDate = getNextDate(selectedDay);
    setDateInputValue(nextDate);
  }, [selectedDay]);

  const isDayEnabled = (date) => {
    const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const dayName = daysOfWeek[date.getDay()];
    return selectedDay.includes(dayName);
  };

  useEffect(() => {
    if (!venueDays) return;
    const fullDayNames = {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday'
    };
    const days = venueDays.map((abbr) => {
      const day = fullDayNames[abbr.toLowerCase()];
      return { id: abbr, name: day || abbr };
    });
    setDayList(days);
    setSelectedDay([]);
  }, [venueDays]);

  const handleCropComplete2 = (crop) => {
    setCompletedCrop2(crop);
  };

  const handleCropComplete3 = (crop) => {
    setCompletedCrop3(crop);
  };

  const handleCropComplete4 = (crop) => {
    setCropImage(crop);
  };

  const updateCropRectangle = (crop) => {
    const aspectRatio = 1.8;
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }
    setCropRectangle(crop);
  };

  const updateCropSquare = (crop) => {
    const aspectRatio = 1;
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }
    setCropSquare(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop2?.width &&
        completedCrop2?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop2,
          scale,
          rotate
        );
        setrectangleSelected(rectangleSelected + 1);
      }
    },
    100,
    [completedCrop2]
  );

  useDebounceEffect(
    async () => {
      if (
        completedCrop3?.width &&
        completedCrop3?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop3,
          scale,
          rotate
        );
        setSquareSelected(squareSelected + 1);
      }
    },
    100,
    [completedCrop3]
  );

  useDebounceEffect(
    async () => {
      if (
        cropImage?.width &&
        cropImage?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          cropImage,
          scale,
          rotate
        );
        setStorySelected(storySelected + 1);
      }
    },
    100,
    [cropImage]
  );

  const handleRectangleImageChange = (e) => {
    const file = e.target.files[0];
    setRectangleImage(file);
    setRectangleImagePreview(URL.createObjectURL(file));
  };
  
  const handleSquareImageChange = (e) => {
    const file = e.target.files[0];
    setSquareImage(file);
    setSquareImagePreview(URL.createObjectURL(file));
  };
  
  const handleStoryImageChange = (e) => {
    const file = e.target.files[0];
    setStoryImage(file);
    setStoryImagePreview(URL.createObjectURL(file));
  };

  useEffect(() => {
    if (!previewCanvasRef.current) return;
    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) throw new Error("Failed to create blob");
      if (blobUrlRef.current) URL.revokeObjectURL(blobUrlRef.current);
      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], offerData.title + "-rectangle.jpg", {
        type: "image/jpeg",
      });
      setRemoteRectangle(file);
    });
  }, [rectangleSelected]);

  useEffect(() => {
    if (!previewCanvasRef.current) return;
    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) throw new Error("Failed to create blob");
      if (blobUrlRef.current) URL.revokeObjectURL(blobUrlRef.current);
      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], offerData.title + "-square.jpg", {
        type: "image/jpeg",
      });
      setRemoteSquare(file);
    });
  }, [squareSelected]);

  useEffect(() => {
    if (!previewCanvasRef.current) return;
    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) throw new Error("Failed to create blob");
      if (blobUrlRef.current) URL.revokeObjectURL(blobUrlRef.current);
      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], offerData.title + "-story.jpg", {
        type: "image/jpeg",
      });
      setRemoteStory(file);
    });
  }, [storySelected]);

  const fetchDiscount = async () => {
    setLoading(true);
    const response = await postRequest("discount/list", { limit: 10000000 });
    if (response.status === 1) {
      setDiscountList(response.data.list);
    }
    setLoading(false);
  };

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };

  const updateFeatureField = (index, fieldName, value) => {
    const newFeatures = [...features];
    newFeatures[index][fieldName] = value;
    setFeatures(newFeatures);
  };

  const addRow = () => {
    setFeatures([...features, { icon: "", title: "", iconName: "" }]);
  };

  const saveFeature = (index) => {
    const newFeatures = [...features];
    newFeatures[index] = { ...newFeatures[index], saved: true };
    setFeatures(newFeatures);
  };

  const deleteFeature = (index) => {
    const newFeatures = features.filter((item, i) => i !== index);
    setFeatures(newFeatures);
  };

  const getTodayDayAbbreviation = () => {
    const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const today = new Date();
    return days[today.getDay()];
  };

  const findNextAvailableTiming = (timings, startDayIndex) => {
    const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    for (let i = 0; i < days.length; i++) {
      const dayIndex = (startDayIndex + i) % days.length;
      const day = days[dayIndex];
      const timing = timings.find(t => t.day.toLowerCase() === day);
      if (timing) return timing;
    }
    return null;
  };

  const handleDeleteOffer = async (id) => {
    let tempOffers = [...selectedOffers];
    tempOffers = tempOffers.filter((item) => item._id !== id);
    setSelectedOffers(tempOffers);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    setVenueTimings(venue?.timings || []);
    const days = venue?.timings?.map(item => item.day.toLowerCase());
    setVenueDays(days);
    closeModalVenue();
    const todayDay = getTodayDayAbbreviation();
    let todayTiming = venue?.timings?.find(item => item.day.toLowerCase() === todayDay);
    if (todayTiming) {
      setRegularTime({
        start: todayTiming.openingTime,
        end: todayTiming.closingTime,
      });
    } else {
      const daysOrder = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      const todayIndex = daysOrder.indexOf(todayDay);
      const nextTiming = findNextAvailableTiming(venue?.timings, todayIndex + 1);
      if (nextTiming) {
        setRegularTime({
          start: nextTiming.openingTime,
          end: nextTiming.closingTime,
        });
      } else {
        setRegularTime({ start: "", end: "" });
      }
    }
  };

  const selectRowEventOrg = (id) => {  
    setEventOrgId(id?._id);
    setEventOrgText(id?.name);
    setEventOrgImage(id?.logo);
    closeModalEventOrg();
  };

  const selectRowActivity = (id, name, venue) => {
    setActivityId(venue?._id);
    setActivityText(venue?.name);
    setActivityImage(venue?.logo);    
    closeModalActivity();
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const toggleModelEventOrg = () => {
    setModelOrg(!modelOrg);
  };

  const toggleModelActivity = () => {
    setModelActivity(!modelActivity);
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const closeModalVenue = () => {
    setModel(false);
  };

  const closeModalEventOrg = () => {
    setModelOrg(false);
  };

  const closeModalActivity = () => {
    setModelActivity(false);
  };

  const closeFeatureModal = () => {
    setOpenFeature(false);
  };

  const closeIconModal = () => {
    setIconModal(false);
  };

  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };

  const selectIconRow = (icon) => {
    const featuresList = [...features];
    featuresList[selectedIcon].icon = icon.image;
    featuresList[selectedIcon].iconName = icon.title;
    setFeatures(featuresList);
    closeIconModal();
  };

  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedDay(ids);
  };

  const updateDiscount = (index, value) => {
    const list = [...features];
    list[index].discount = value;
    setFeatures(list);
  };

  let history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (!state) return;
    if (state?.venueId) {
      setVenueId(state?.venueId);
      setVenueText(state?.venueName);
    }
    if (state?.categoryId) {
      setCategoryId(state?.categoryId);
      const tempData = { ...voucherData };
      tempData.categoryId = state?.categoryId;
      setVoucherData(tempData);
    }
  }, [state]);

  useEffect(() => {
    fetchList();
    fetchCategoryList();
    const days = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];
    setDayList(days);
  }, []);

  const fetchfFeatures = async () => {
    setLoading(true);
    const response = await postRequest("subscription/membership/list", {
      limit: 10000000,
    });
    if (response.status === 1) {
      setFeatures(response.data.list);
    }
    setLoading(false);
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", {
      limit: 10000000,
    });
    if (response.status === 1) {
      const list = response.data.list.map((item) => {
        return { id: item._id, name: item.name };
      });
      setVenueList(list);
    }
    setLoading(false);
  };

  const updateRegulaTime = (type, value) => {
    const updatedSlots = { ...regularTime };
    updatedSlots[type] = value;
    setRegularTime(updatedSlots);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status === 1) {
      const list = response.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setCategoryList(list);
    }
    setLoading(false);
  };

  const addPolicyData = (data) => {    
    let venueDt = { ...voucherData };
    venueDt.cancelPolicy = data;
    data.unit = "day";
    setVoucherData(venueDt);   
  };

  const editPolicyData = (index) => {
    let currentPolicyRule = policyRules[index];
    setCurrentPolicyData(currentPolicyRule);
    setPolicyModal(true);
  };

  const deletePolicyData = (index) => {
    let policyLists = [...policyRules];
    policyLists.splice(index, 1);
    setPolicyRules(policyLists);
  };

  const createFormData = (key, file) => {
    const formData = new FormData();
    formData.append(key, file);
    return formData;
  };

  const appendFormData = (formData, data) => {
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  };

  function closePolicyModal() {
    setPolicyModal(false);
  }

  const createVoucher = async (e) => {
    e.preventDefault();

    if (!voucherData.title) {
      toast.error("Please enter title");
      return;
    }
    if (!voucherData.description) {
      toast.error("Please enter description");
      return;
    }
    if (!voucherData.categoryId) {
      toast.error("Please select category");
      return;
    }
    if (!voucherData.type) {  
      toast.error("Please select type");
      return;
    }
    if (voucherData.type === "venue" && !venueId) {
      toast.error("Please select venue");
      return;
    }
    if (voucherData.type === "event-org" && !eventOrgId) {
      toast.error("Please select event org");
      return;
    }
    if (voucherData.type === "activity-provider" && !activityId) {
      toast.error("Please select activity provider");
      return;
    }
    if (selectedDay?.length === 0) {
      toast.error("Please select days");
      return;
    }
    if (!voucherData.actualPrice) {
      toast.error("Please enter actual price");
      return;
    }
    if (voucherData.discountedPrice > voucherData.actualPrice || !voucherData.discountedPrice) {
      if (voucherData.discountedPrice > voucherData.actualPrice) {
        toast.error("Discounted price should be less than actual price");
      } else {
        toast.error("Please enter discounted price");
      }
      return;
    }
    if (voucherData.discountValue > 100) {
      toast.error("Discount value should be less than 100");
      return;
    }
    if (voucherData.paxPerVoucher < 1) {
      toast.error("Pax per voucher must be greater than 0");
      return;
    }
    if (!voucherData.startDate) {
      toast.error("Please enter start date");
      return;
    }
    if (!voucherData.startTime) {
      toast.error("Please enter start time");
      return;
    }
    if (!voucherData.endDate) {
      toast.error("Please enter end date");
      return;
    }
    if (!voucherData.endTime) {
      toast.error("Please enter end time");
      return;
    }
    let startDate = voucherData.startTime ? new Date(voucherData.startDate + " " + voucherData.startTime) : new Date(voucherData.startDate + " 00:00");
    let endDate = voucherData.endTime ? new Date(voucherData.endDate + " " + voucherData.endTime) : new Date(voucherData.endDate + " 00:00");

    if (!selectedImage) {
      toast.error("Please select image");
      return;
    }
    if (voucherData.isTaxIncluded === "false" && voucherData.vat === "") {
      toast.error("Please enter VAT value");
      return;
    }

    setStartLoader(true);
    setLoading(true);
    const search = location.search;
    const from = new URLSearchParams(search).get("from");

    const x = new FormData();
    if (selectedImage) {
      x.append("image", selectedImage);
    }
    const imgres = await postRequestForm("comman/img/upload", x);
    setStartLoader(false);

    const dataObj = {
      title: voucherData.title,
      description: voucherData.description,
      providerType: "venue",
      categoryId: voucherData.categoryId,
      days: selectedDay.join(","),
      venueId: venueId,
      startDate: voucherData.startDate,
      startTime: voucherData.startTime,
      endDate: voucherData.endDate,
      endTime: voucherData.endTime,
      actualPrice: voucherData.actualPrice,
      discountedPrice: voucherData.discountedPrice,
      discountValue: voucherData.discountValue,
      image: imgres?.data.url,
      paxPerVoucher: voucherData.paxPerVoucher,
      buttonText: voucherData.buttonText,
      offer_badge: voucherData?.offer_badge || "",
      isTaxIncluded: voucherData.isTaxIncluded === "true",
      vat: voucherData?.vat || 0,
      features: features,
      // NEW: Include packages here
      packages: packages,
    };

    let dealTiming = {};
    if (availableTime === "regular") {
      if (!regularTime?.start || !regularTime?.end) {
        toast.error("Please select regular time");
        return;
      }
      dealTiming = {
        type: "regular",
        time: `${regularTime.start}-${regularTime.end}`,
        totalSeats: voucherData?.totalSeats
      };
      dataObj.dealTiming = dealTiming;          
    }
    if (voucherData.cancelPolicy) {         
      dataObj.cancelPolicy = voucherData.cancelPolicy; 
    }
    if (voucherData.type === "event-org") {
      dataObj.eventOrgId = eventOrgId;
    }
    if (voucherData.type === "activity-provider") {
      dataObj.activityProviderId = activityId;
    }
    
    const uploadPromises = [
      selectedImage ? postRequestForm("comman/img/upload", createFormData("image", selectedImage)) : Promise.resolve(null),
      remoteRectangle ? postRequestForm("comman/img/upload", createFormData("image", remoteRectangle)) : Promise.resolve(null),
      remoteSquare ? postRequestForm("comman/img/upload", createFormData("image", remoteSquare)) : Promise.resolve(null),
      remoteStory ? postRequestForm("comman/img/upload", createFormData("image", remoteStory)) : Promise.resolve(null)
    ];
    const [imageResponse, rectangleResponse, squareResponse, storyResponse] = await Promise.all(uploadPromises);
    
    dataObj.image = imageResponse?.data?.url || '';
    dataObj.rectangleImage = rectangleResponse?.data?.url || "";
    dataObj.squareImage = squareResponse?.data?.url || "";
    dataObj.storyImage = storyResponse?.data?.url || "";
    dataObj.offerIds = selectedOffers.map((item) => item._id);
    dataObj.markup = voucherData.markup;
    dataObj.costPrice = voucherData.costPrice;    

    const response = await postRequest("homeblock/deal-package/create", dataObj);
    setLoading(false);
    if (response.status === 1) {
      toast.success("Voucher created successfully");
      // Optionally navigate back or to another route
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...voucherData };
    if (index === 'discountValue') {
      if (!list.actualPrice) {
        toast.error("Please Enter Actual Price First");
        return false;
      }
      list['discountedPrice'] = list.actualPrice - (value * list.actualPrice) / 100;
    }
    if (index === 'actualPrice') {
      if (list.discountValue) {
        list['discountedPrice'] = value - (list.discountValue * value) / 100;
      } else {
        list['discountedPrice'] = value;
      }
    }
    list[index] = value;
    setVoucherData(list);
  };

  const addFeature = () => {
    setOpenFeature(true);
  };

  const addFeatureData = (data) => {
    setFeatures([...features, data]);
    setOpenFeature(false);
  };

  const updateFeatureData = (index, field, value) => {
    const newFeatures = [...features];
    newFeatures[index][field] = value;
    setFeatures(newFeatures);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Hot Deals Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Hot Deals</li>:{" "}
              <li className="breadcrumb-item">Create</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Voucher </h4>
                <Form onSubmit={createVoucher}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        {/* Existing form fields */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Title</label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) => updateValue("title", e.target.value)}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Description</label>
                          <div className="col-sm-6">
                            <textarea
                              onChange={(e) => updateValue("description", e.target.value)}
                              name="description"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Select Category</label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) => updateValue("categoryId", e.target.value)}
                              defaultValue={categoryId}
                              name="categoryId"
                              className="form-select"
                            >
                              <option value="">Select Category</option>
                              {categoryList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {/* Venue selection section */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Select Venue</label>
                          <div className="col-sm-6" onClick={() => toggleModel()}>
                            {venueText ? (
                              <div className="input-group">
                                <button
                                  className="btn"
                                  style={{ border: "1px solid #ced4da", background: "#E22A2A", color: "#ffff" }}
                                  type="button"
                                  onClick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <div style={{ border: "1px solid #ced4da", width: "16px" }}></div>
                                <img
                                  src={venueImage}
                                  style={{ width: "40px", height: "40px", border: "1px solid #ced4da", borderRight: "none", borderLeft: "none" }}
                                  alt="venue"
                                />
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  className="form-control"
                                  readOnly
                                  value={venueText}
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              <div className="input-group">
                                <button
                                  className="btn"
                                  style={{ border: "1px solid #ced4da", background: "#E22A2A", color: "#ffff" }}
                                  type="button"
                                  onClick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  placeholder="No venue chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {/* Offers section */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Want to add Offers</label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) => updateValue("isAddOffer", e.target.value)}
                              value={voucherData?.isAddOffer}
                              className="form-select"
                            >
                              <option value="false">No</option>
                              <option value="true">Yes</option>
                            </select>
                          </div>
                        </div>
                        {voucherData?.isAddOffer === "true" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Select Offers</label>
                            <div className="col-sm-6">
                              <Button onClick={() => toggleOfferModel()}>Choose Offers</Button>
                            </div>
                          </div>
                        )}
                        {selectedOffers && selectedOffers.length > 0 && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Selected Offers</label>
                            <div className="col-sm-6">
                              <SelectedOfferList notification={selectedOffers} handlerdelete={handleDeleteOffer} />
                            </div>
                          </div>
                        )}
                        {/* Event Org and Activity Provider sections */}
                        {voucherData.type === "event-org" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Select Event Org</label>
                            <div className="col-sm-6" onClick={() => toggleModelEventOrg()}>
                              {eventOrgText ? (
                                <div className="input-group">
                                  <button
                                    className="btn"
                                    style={{ border: "1px solid #ced4da", background: "#E22A2A", color: "#ffff" }}
                                    type="button"
                                    onClick={() => toggleModelEventOrg()}
                                  >
                                    Choose Event Org
                                  </button>
                                  <div style={{ border: "1px solid #ced4da", width: "16px" }}></div>
                                  <img
                                    src={eventOrgImage}
                                    style={{ width: "40px", height: "40px", border: "1px solid #ced4da", borderRight: "none", borderLeft: "none" }}
                                    alt="event org"
                                  />
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    className="form-control"
                                    readOnly
                                    value={eventOrgText}
                                    placeholder="No Event org chosen"
                                  />
                                </div>
                              ) : (
                                <div className="input-group">
                                  <button
                                    className="btn"
                                    style={{ border: "1px solid #ced4da", background: "#E22A2A", color: "#ffff" }}
                                    type="button"
                                    onClick={() => toggleModelEventOrg()}
                                  >
                                    Choose Event Org
                                  </button>
                                  <input
                                    type="text"
                                    className="form-control"
                                    readOnly
                                    placeholder="No Event org chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {voucherData.type === "activity-provider" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Select Activity Provider</label>
                            <div className="col-sm-6" onClick={() => toggleModelActivity()}>
                              {activityText ? (
                                <div className="input-group">
                                  <button
                                    className="btn"
                                    style={{ border: "1px solid #ced4da", background: "#E22A2A", color: "#ffff" }}
                                    type="button"
                                    onClick={() => toggleModelActivity()}
                                  >
                                    Choose Activity Provider
                                  </button>
                                  <div style={{ border: "1px solid #ced4da", width: "16px" }}></div>
                                  <img
                                    src={activityImage}
                                    style={{ width: "40px", height: "40px", border: "1px solid #ced4da", borderRight: "none", borderLeft: "none" }}
                                    alt="activity"
                                  />
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    className="form-control"
                                    readOnly
                                    value={activityText}
                                    placeholder="No Activity Provider chosen"
                                  />
                                </div>
                              ) : (
                                <div className="input-group">
                                  <button
                                    className="btn"
                                    style={{ border: "1px solid #ced4da", background: "#E22A2A", color: "#ffff" }}
                                    type="button"
                                    onClick={() => toggleModelActivity()}
                                  >
                                    Choose Activity Provider
                                  </button>
                                  <input
                                    type="text"
                                    className="form-control"
                                    readOnly
                                    placeholder="No Activity Provider chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {/* Select Days */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Select Days</label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={dayList}
                              onSelect={msUpdateDay}
                              selectedValues={[]}
                              placeholder="Select Days"
                            />
                          </div>
                        </div>
                        {venueTimings && venueTimings.length > 0 && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Venue Timings</label>
                            <div className="col-sm-6">
                              <div className="venue-timings-table">
                                {venueTimings.map((timing, index) => (
                                  <div key={index} className="timing-row" style={{ display: 'grid', gridTemplateColumns: '1fr auto auto', alignItems: 'center', borderBottom: '1px solid #ccc' }}>
                                    <div className="day-cell" style={{ padding: '8px', backgroundColor: timing ? 'transparent' : '#f8f9fa' }}>
                                      {timing.day.charAt(0).toUpperCase() + timing.day.slice(1)}
                                    </div>
                                    {timing && (
                                      <>
                                        <div className="status-cell" style={{ padding: '8px' }}>
                                          <span style={{ display: 'inline-block', padding: '0.25em 0.6em', fontSize: '75%', fontWeight: '700', lineHeight: '1', textAlign: 'center', whiteSpace: 'nowrap', verticalAlign: 'baseline', borderRadius: '0.25rem', color: '#fff', backgroundColor: '#28a745' }}>
                                            OPENING
                                          </span>
                                        </div>
                                        <div className="time-cell" style={{ padding: '8px' }}>
                                          {timing.openingTime} - {timing.closingTime}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                        {/* Price fields */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Actual Price</label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) => updateValue("actualPrice", e.target.value)}
                              name="actualPrice"
                              min={0}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Discount Value (In Percent)</label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) => updateValue("discountValue", e.target.value)}
                              name="discountValue"
                              min={5}
                              max={100}
                              className="form-control"
                            />
                          </div>
                        </div>




                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Discounted Price</label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              value={voucherData.discountedPrice}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Per Person Voucher</label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) => updateValue("paxPerVoucher", e.target.value)}
                              name="paxPerVoucher"
                              min={0}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* Date and time fields */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Start Date</label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) => updateValue("startDate", e.target.value)}
                              name="startDate"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Start Time</label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) => updateValue("startTime", e.target.value)}
                              name="startTime"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">End Date</label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) => updateValue("endDate", e.target.value)}
                              name="endDate"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">End Time</label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) => updateValue("endTime", e.target.value)}
                              name="endTime"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* Available time selection */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Select Available Time for Booking</label>
                          <div className="col-sm-6">
                            <select
                              className="form-select"
                              onChange={(e) => setAvailableTime(e.target.value)}
                              value={availableTime || "regular"}
                            >
                              <option value="">Select Time</option>
                              <option value="regular">Regular</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Quantity of Seats</label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) => updateValue("totalSeats", e.target.value)}
                              defaultValue={voucherData?.totalSeats}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Start Time / End Time</label>
                          <div className="col-sm-3">
                            <label>Start Time:</label>
                            <input
                              type="time"
                              onChange={(e) => updateRegulaTime("start", e.target.value)}
                              value={regularTime.start}
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-3">
                            <label>End Time:</label>
                            <input
                              type="time"
                              onChange={(e) => updateRegulaTime("end", e.target.value)}
                              value={regularTime.end}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* Image upload fields */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Image</label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) => setSelectedImage(e.target.files[0])}
                              name="cover"
                              className="form-control"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "50px", height: "50px" }}
                                alt="preview"
                              />
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Rectangle Image</label>
                          <div className="col-sm-6">
                            <input type="file" onChange={handleRectangleImageChange} name="rectangleImage" className="form-control" />
                            <br />                         
                            {rectangleImagePreview && (
                              <ReactCrop
                                crop={cropRectangle}
                                src={rectangleImagePreview}
                                onChange={(newCrop) => updateCropRectangle(newCrop)}
                                onComplete={handleCropComplete2}
                                style={{ maxWidth: "720px", maxHeight: "600px" }}
                              >
                                <img ref={imgRef} src={rectangleImagePreview} alt="rectangle crop" />
                              </ReactCrop>
                            )}
                            {completedCrop2 && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{ border: "1px solid black", objectFit: "contain", width: completedCrop2.width, height: completedCrop2.height }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Square Image</label>
                          <div className="col-sm-6">
                            <input type="file" onChange={handleSquareImageChange} name="squareImage" className="form-control" />
                            <br />
                            {squareImagePreview && (
                              <ReactCrop
                                crop={cropSquare}
                                src={squareImagePreview}
                                onChange={(newCrop) => updateCropSquare(newCrop)}
                                onComplete={handleCropComplete3}
                                style={{ maxWidth: "720px", maxHeight: "600px" }}
                              >
                                <img ref={imgRef} src={squareImagePreview} alt="square crop" />
                              </ReactCrop>
                            )}
                            {completedCrop3 && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{ border: "1px solid black", objectFit: "contain", width: completedCrop3.width, height: completedCrop3.height }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Story Image</label>
                          <div className="col-sm-6">
                            <input type="file" onChange={handleStoryImageChange} name="storyImage" className="form-control" />
                            <br />
                            {storyImagePreview && (
                              <ReactCrop
                                crop={crop}
                                src={storyImagePreview}
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={handleCropComplete4}
                                style={{ maxWidth: "720px", maxHeight: "600px" }}
                              >
                                <img ref={imgRef} src={storyImagePreview} alt="story crop" />
                              </ReactCrop>
                            )}
                            {cropImage && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{ border: "1px solid black", objectFit: "contain", width: cropImage.width, height: cropImage.height }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Is Tax Included</label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) => updateValue("isTaxIncluded", e.target.value)}
                              value={voucherData?.isTaxIncluded}
                              className="form-select"
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                        {voucherData.isTaxIncluded === "false" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">VAT %</label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                min="0"
                                max="99"
                                value={voucherData?.vat}
                                onChange={(e) => updateValue("vat", e.target.value)}
                                name="vat"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Button Text</label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) => updateValue("buttonText", e.target.value)}
                              name="buttonText"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* NEW: Packages Section */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Packages</label>
                          <div className="col-sm-6">
                            <button
                              type="button"
                              className="btn btn-primary mb-3"
                              onClick={togglePackageModal}
                            >
                              Add Package
                            </button>
                            {packages.length > 0 && (
                              <table className="table mt-3">
                                <thead>
                                  <tr>
                                    <th>Title</th>
                                    <th>Quantity</th>
                                    <th>Price Per Unit</th>
                                    <th>Total Price</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {packages.map((pkg, index) => (
                                    <tr key={index}>
                                      <td>{pkg.title}</td>
                                      <td>{pkg.qty}</td>
                                      <td>{pkg.pricePerUnit}</td>
                                      <td>{pkg.qty * pkg.pricePerUnit}</td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() => handleDeletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                        {/* Cancellation Policy */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Cancellation Policy</label>
                          <div className="col-sm-8">
                            <button
                              type="button"
                              onClick={() => setPolicyModal(true)}
                              className="btn btn-primary mb-3"
                            >
                              Update Cancellation Policy
                            </button>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Offer Badge (lottei animation JSON)</label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) => setSelectedBadge(e.target.files[0])}
                              name="cover"
                              className="form-control"
                            />
                            <br />
                            {voucherData?.offer_badge?.length ? (
                              <div>
                                <a href={voucherData.offer_badge} target="_blank" rel="noopener noreferrer">Download</a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a href="#" onClick={(e) => { e.preventDefault(); setVoucherData({ ...voucherData, offer_badge: "" }); }}>Remove</a>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button type="submit" className="btn btn-primary w-md">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <OfferPackageModal
        show={openFeature}
        closeModal={() => setOpenFeature(false)}
        addData={addFeatureData}
      />
      <IconPackModal
        show={iconModal}
        closeModal={closeIconModal}
        selectRow={selectIconRow}
      />
      <OfferModal
        show={offerModel}
        closeOfferModal={closeOfferModal}
        selectRow={selectOfferRow}
        venueId={venueId}
      />
      <VenueModal
        show={model}
        closeModal={closeModalVenue}
        selectRow={selectRow}
      />
      <EventOrgModal
        show={modelOrg}
        closeEventOrgModal={closeModalEventOrg}
        selectEventOrgRow={selectRowEventOrg}
      />
      <ActivityProviderModal
        show={modelActivity}
        closeModal={closeModalActivity}
        selectRow={selectRowActivity}
      />
      <CancelPolicyModal
        show={policyModal}
        closeModal={closePolicyModal}
        addData={addPolicyData}
        currentPolicyData={voucherData?.cancelPolicy}
      />
      <FileUpload message="File Uploading" status={startLoader} />
      {/* NEW: Package Modal */}
      {openPackageModal && (
        <PackageModal
          show={openPackageModal}
          closeModal={() => setOpenPackageModal(false)}
          addData={handleSavePackage}
        />
      )}
    </React.Fragment>
  );
}
