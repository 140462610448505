import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { postRequest } from "../../components/Common/Utils.js";

export default function CreateBlockSize() {
  const [sizeData, setSizeData] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  let history = useHistory();


  
  // **Validation Function**
  const validateForm = () => {
    let tempErrors = {};

    if (!sizeData?.type) {
      tempErrors.type = "Please enter type";
    }
    if (!sizeData?.ratio) {
      tempErrors.ratio = "Please enter ratio";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const updateSizeData = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      return;
    }

  //  if (sizeData.type == "") {
  //     toast.error("Please enter type");
  //     return false;
  //   }
  //   if (sizeData.ratio == "") {
  //     toast.error("Please enter ratio");
  //     return false;
  //   }
    setLoading(true);

    const dataObj = {
      id: sizeData._id,
      type: sizeData.type,
      ratio: sizeData.ratio,
    };

    const response = await postRequest("homeblock/size/create", dataObj);

    setLoading(false);

    if (response.status == 1) {
      toast.success("Size Created");
      setTimeout(() => {
        history.push("/blocksize");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...sizeData };
    list[index] = value;
    setSizeData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Size Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Size Management</li>:{" "}
              <li className="breadcrumb-item">
                <Link to="#">Size create</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title ms-5">Size Create </h4>
                <Form onSubmit={updateSizeData}>
                  <Row>
                    <Col lg={5} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Type
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              name="type"
                              defaultValue={sizeData?.type}
                              className="form-control"
                              id="horizontal-firstname-input"
                             
                            />

                            {errors.type && <span className="text-danger">{errors.type}</span>}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Ratio
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("ratio", e.target.value)
                              }
                              name="ratio"
                              defaultValue={sizeData?.ratio}
                              className="form-control"
                              id="horizontal-firstname-input"
                             
                            />
                            {errors.ratio && <span className="text-danger">{errors.ratio}</span>}
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
