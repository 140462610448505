import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Button,
  Modal,
  Input,
  Label,
} from "reactstrap";
import ReactQuill from "react-quill";

const BOOKING_DATE = new Date("2025-03-10T00:00:00");
const BOOKING_AMOUNT = 100; // AED

const FormComponent = ({ addData, currentPolicyData, closeModal }) => {
  // State for Cancellation Policy Text
  const [cancelPolicyText, setCancelPolicyText] = useState("");

  // New state for Time Unit (hour/day), default is "hour"
  const [timeUnit, setTimeUnit] = useState("hour");

  // State for Cancellation Policy Rules
  const [cancelPolicyRules, setCancelPolicyRules] = useState([
    { from: "", to: "", type: "percentage", amount: "" },
  ]);

  // State for tracking validation errors
  const [errors, setErrors] = useState({});

  // State to display instructions
  const [instructions, setInstructions] = useState([]);

  /**
   * Initialize form state based on currentPolicyData
   */
  useEffect(() => {
    if (currentPolicyData) {
      const { text, rules, unit } = currentPolicyData;

      setCancelPolicyText(text || "");
      setTimeUnit(unit || "hour");

      if (Array.isArray(rules) && rules.length > 0) {
        setCancelPolicyRules(
          rules.map((rule) => ({
            from: rule.from || "",
            to: rule.to || "",
            type: rule.type || "percentage",
            amount: rule.amount || "",
          }))
        );
      } else {
        setCancelPolicyRules([{ from: "", to: "", type: "percentage", amount: "" }]);
      }
    } else {
      // If there is no currentPolicyData, show the default
      setCancelPolicyText("");
      setTimeUnit("hour");
      setCancelPolicyRules([{ from: "", to: "", type: "percentage", amount: "" }]);
    }
  }, [currentPolicyData]);

  /**
   * Whenever rules or timeUnit changes, recalculate instructions
   */
  useEffect(() => {
    const newInstructions = generateInstructions(cancelPolicyRules, timeUnit);
    setInstructions(newInstructions);
  }, [cancelPolicyRules, timeUnit]);

  /**
   * Handler to update Cancellation Policy Text
   */
  const handleCancelPolicyTextChange = (value) => {
    setCancelPolicyText(value);
  };

  /**
   * Handlers for Cancellation Policy Rules
   */
  const handleRuleChange = (index, field, value) => {
    const updatedRules = cancelPolicyRules.map((rule, i) =>
      i === index ? { ...rule, [field]: value } : rule
    );
    setCancelPolicyRules(updatedRules);
  };

  const addRule = () => {
    setCancelPolicyRules([
      ...cancelPolicyRules,
      { from: "", to: "", type: "percentage", amount: "" },
    ]);
  };

  const removeRule = (index) => {
    const updatedRules = cancelPolicyRules.filter((_, i) => i !== index);
    setCancelPolicyRules(updatedRules);
  };

  /**
   * Validation Function
   */
  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    // 1) Validate Cancellation Policy Text
    const plainText = new DOMParser()
      .parseFromString(cancelPolicyText, "text/html")
      .body.textContent || "";
    if (!plainText.trim()) {
      newErrors.cancelPolicyText = "Cancellation policy text is required.";
      valid = false;
    }

    // 2) Validate Cancellation Policy Rules
    if (cancelPolicyRules.length === 0) {
      newErrors.cancelPolicyRules =
        "At least one cancellation policy rule is required.";
      valid = false;
    } else {
      cancelPolicyRules.forEach((rule, index) => {
        const { from, to, type, amount } = rule;
        if (from === "" || to === "" || type === "" || amount === "") {
          newErrors[`rule_${index}`] = "All fields in the rule are required.";
          valid = false;
        } else {
          // numeric checks
          if (isNaN(from) || isNaN(to)) {
            newErrors[`rule_${index}`] = "From and To must be numbers.";
            valid = false;
          } else {
            if (Number(from) < Number(to)) {
              newErrors[`rule_${index}`] =
                "From must be greater than or equal to To.";
              valid = false;
            }
            if (Number(from) < 0 || Number(to) < 0) {
              newErrors[`rule_${index}`] = "From and To cannot be negative.";
              valid = false;
            }
          }

          const allowedTypes = ["percentage", "fixed_deduction"];
          if (!allowedTypes.includes(type)) {
            newErrors[`rule_${index}`] =
              "Type must be either 'percentage' or 'fixed_deduction'.";
            valid = false;
          }

          if (isNaN(amount) || Number(amount) < 0) {
            newErrors[`rule_${index}`] =
              "Amount must be a non-negative number.";
            valid = false;
          }
        }
      });

      // 3) Check for overlapping ranges
      const sortedRules = [...cancelPolicyRules].sort(
        (a, b) => Number(b.from) - Number(a.from)
      );
      for (let i = 0; i < sortedRules.length - 1; i++) {
        const currentRule = sortedRules[i];
        const nextRule = sortedRules[i + 1];
        if (Number(currentRule.to) < Number(nextRule.from)) {
          newErrors[`rule_${i}`] = `Rule ${i + 1} overlaps with Rule ${i + 2}.`;
          valid = false;
        }
      }
    }

    setErrors(newErrors);
    return valid;
  };

  /**
   * Handler for Form Submission
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Prepare the cancellation policy data
      const cancellationPolicy = {
        text: cancelPolicyText.trim(),
        unit: timeUnit,
        rules: cancelPolicyRules.map((rule) => ({
          from: Number(rule.from),
          to: Number(rule.to),
          type: rule.type,
          amount: Number(rule.amount),
        })),
      };

      // Submit the data
      addData(cancellationPolicy);

      // Close the modal
      closeModal();

      // Clear errors
      setErrors({});
    }
  };

  /**
   * Handler for Reset
   */
  const handleReset = () => {
    // 1) Clear local states
    setCancelPolicyText("");
    setTimeUnit("hour");
    setCancelPolicyRules([{ from: "", to: "", type: "percentage", amount: "" }]);
    setErrors({});

    // 2) Send empty data to parent
    addData({
      text: "",
      unit: "hour",
      rules: [],
    });

    // 3) Close modal
    closeModal();
  };

  /**
   * Generate instructions from user-defined rules
   */
  const generateInstructions = (rules, unit) => {
    if (!rules || rules.length === 0) {
      return [
        "9th March - 10th March => refund amount 0",
        "8th March - 1st March => refund amount 60",
      ];
    }

    // Sort rules in descending order based on 'from'
    const sorted = [...rules].sort((a, b) => Number(b.from) - Number(a.from));

    // For each rule, figure out the date range & refund info
    return sorted.map((rule) => {
      const startDate = subtractTime(BOOKING_DATE, Number(rule.from), unit);
      const endDate = subtractTime(BOOKING_DATE, Number(rule.to), unit);

      // Format the date range for display
      const startStr = formatDate(startDate);
      const endStr = formatDate(endDate);

      // Decide how to display the refund
      let refundStr = "";
      if (rule.type === "percentage") {
        refundStr = `${rule.amount}% of ${BOOKING_AMOUNT} AED`;
      } else {
        // fixed deduction
        refundStr = `${BOOKING_AMOUNT - Number(rule.amount)} AED`;
      }

      return `${endStr} - ${startStr} => refund: ${refundStr}`;
    });
  };

  /**
   * Helper to subtract (days/hours) from a base date
   */
  const subtractTime = (baseDate, amount, unit) => {
    const newDate = new Date(baseDate);
    if (unit === "day") {
      newDate.setDate(newDate.getDate() - amount);
    } else {
      // unit === "hour"
      newDate.setHours(newDate.getHours() - amount);
    }
    return newDate;
  };

  /**
   * Helper to format date as "D MMM" (e.g., "9 Mar")
   */
  const formatDate = (date) => {
    const options = { day: "numeric", month: "short" };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              {/* Cancellation Policy Text */}
              <div className="mb-4">
                <Label for="cancelPolicyText">Cancellation Policy</Label>
                <ReactQuill
                  style={{
                    height: "200px",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  value={cancelPolicyText}
                  onChange={handleCancelPolicyTextChange}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, false] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link", "image", "video"],
                      ["clean"],
                    ],
                  }}
                  formats={[
                    "header",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "list",
                    "bullet",
                    "link",
                    "image",
                    "video",
                  ]}
                />
                {errors.cancelPolicyText && (
                  <div className="text-danger">{errors.cancelPolicyText}</div>
                )}
              </div>

              {/* Select Unit (Hour/Day) */}
              <div className="mb-4" style={{ marginTop: 40 }}>
                <Label for="timeUnit">Select Unit</Label>
                <Input
                  type="select"
                  name="timeUnit"
                  id="timeUnit"
                  value={timeUnit}
                  onChange={(e) => setTimeUnit(e.target.value)}
                >
                  <option value="hour">Hour</option>
                  <option value="day">Day</option>
                </Input>
              </div>


              {/* Cancellation Policy Rules */}
              <div className="mb-4">
                <Label style={{ margin: 40 }}>Cancellation Policy Rules</Label>
                {cancelPolicyRules.map((rule, index) => (
                  <Row key={index} className="mb-3 align-items-end">
                    <Col md={3}>
                      <Label for={`from_${index}`}>From</Label>
                      <Input
                        type="number"
                        name={`from_${index}`}
                        id={`from_${index}`}
                        value={rule.from}
                        onChange={(e) =>
                          handleRuleChange(index, "from", e.target.value)
                        }
                        placeholder="e.g., 30"
                        min="0"
                      />
                    </Col>
                    <Col md={3}>
                      <Label for={`to_${index}`}>To</Label>
                      <Input
                        type="number"
                        name={`to_${index}`}
                        id={`to_${index}`}
                        value={rule.to}
                        onChange={(e) =>
                          handleRuleChange(index, "to", e.target.value)
                        }
                        placeholder="e.g., 10"
                        min="0"
                      />
                    </Col>
                    <Col md={3}>
                      <Label for={`type_${index}`}>Type</Label>
                      <Input
                        type="select"
                        name={`type_${index}`}
                        id={`type_${index}`}
                        value={rule.type}
                        onChange={(e) =>
                          handleRuleChange(index, "type", e.target.value)
                        }
                      >
                        <option value="percentage">Percentage</option>
                        <option value="fixed_deduction">Fixed Deduction</option>
                      </Input>
                    </Col>
                    <Col md={2}>
                      <Label for={`amount_${index}`}>Amount</Label>
                      <Input
                        type="number"
                        name={`amount_${index}`}
                        id={`amount_${index}`}
                        value={rule.amount || 0}
                        onChange={(e) =>
                          handleRuleChange(index, "amount", e.target.value)
                        }
                        placeholder="e.g., 50"
                        min="0"
                      />
                    </Col>
                    <Col md={1}>
                      <Button
                        color="danger"
                        onClick={() => removeRule(index)}
                        disabled={cancelPolicyRules.length === 1}
                      >
                        &times;
                      </Button>
                    </Col>
                    {errors[`rule_${index}`] && (
                      <Col md={12}>
                        <div className="text-danger">
                          {errors[`rule_${index}`]}
                        </div>
                      </Col>
                    )}
                  </Row>
                ))}
                <Button color="secondary" onClick={addRule}>
                  Add Rule
                </Button>

                <br/><br/><br/><br/>


                              {/* Sample Test Case & Guidelines */}
              <h4>Sample Test Case</h4>
              <h5>
                Booking Date: {formatDate(BOOKING_DATE)} 2025, Amount: {BOOKING_AMOUNT} AED
              </h5>

              {/* Guidelines for the User */}
              <div className="mb-4">
                <Label>Guidelines:</Label>
                <ul>

                  <li>
                    Enter cancellation rules by specifying the "From" and "To" values, which represent
                    the time before the booking date.
                  </li>

                  <li>
                    Like <strong>"From 10 to 1 Days Type % Amount 10"</strong> means Feb 28 - Feb 18 you will get 10% refund
                  </li>

                  <li>
                    Choose the appropriate time unit ("Hour" or "Day") to indicate how the time
                    values should be interpreted.
                  </li>
                  <li>
                    The refund amount will be calculated based on the rule type: percentage or fixed
                    deduction.
                  </li>
                  <li>
                    Ensure that the "From" value is greater than or equal to the "To" value, and that
                    neither value is negative.
                  </li>

                  <li>
                    The static instructions below update dynamically based on the rules you enter.
                  </li>
                </ul>
              </div>

              {/* Dynamic / Default Instructions */}
              <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                <strong>Instructions:</strong>
                <ul>
                  {instructions.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </div>




                {errors.cancelPolicyRules && (
                  <div className="text-danger mt-2">
                    {errors.cancelPolicyRules}
                  </div>
                )}
              </div>

              {/* Action Buttons */}
              <div className="d-flex justify-content-end" style={{ gap: 10 }}>
                <Button color="danger" onClick={handleReset}>
                  Reset
                </Button>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const PackageModal = ({ show, closeModal, addData, currentPolicyData }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Cancellation Policy Info
        </h5>
        <button
          onClick={closeModal}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <FormComponent
          addData={addData}
          currentPolicyData={currentPolicyData}
          closeModal={closeModal}
        />
      </div>
    </Modal>
  );
};

export default PackageModal;
