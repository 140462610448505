import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner, Badge, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import Lightbox from "react-image-lightbox";

import FilterModal from "../../../components/Modal/FilterModal";
import CustomAvatar from "../../../components/Common/Avatar.js";
import cryptLib from "@skavinvarnan/cryptlib";
import { postRequest, deleteRequest, putRequestForm } from "../../../components/Common/Utils.js";

import "./ConvoManagementlist.scss"; // custom styles

// Default filter object
const filterObj = {
  venueName: "",
  userName: "",
  title: "",
  stars: "",
  review: "",
  reply: "",
  status: "",
  createdAt: "",
};

function base64Decode(str, channel = "") {
  try {
    return cryptLib.decryptCipherTextWithRandomIV(str, channel);
  } catch (error) {
    return "";
  }
}

const RatingManagementlist = () => {
  // State variables
  const [ratingdata, setRatingData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = useState("");
  const [openLightbox, setOpenLightbox] = useState(false);
  const [model, setModel] = useState(false);
  const [canExport, setCanExport] = useState(true);
  const [canReply, setCanReply] = useState(true);
  const [filter, setFilter] = useState({ ...filterObj });

  useEffect(() => {
    ratingManagement();
    // eslint-disable-next-line
  }, [currentPage, sizePerPageOrg, sort, sortOrder]);

  // Fetch data for the table
  const ratingManagement = async (filterArgs = []) => {
    setLoader(true);
    const vid = localStorage.getItem("stored_venueId");
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
      venueId: vid,
    };
    if (filterArgs.length) {
      payload.filterArgs = filterArgs;
    } else if (searchText) {
      payload.search = searchText;
    }
    try {
      const response = await postRequest(`chat/venue/chat-list`, payload);
      const data = response.data || [];
      setTotalPage(data.length);
      setRatingData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  // Reload only the table data
  const handleReload = () => {
    ratingManagement();
  };

  // Filter modal functions
  const toggleModel = () => setModel(!model);
  const closeModal = () => setModel(false);
  const updateFilter = (key, value) =>
    setFilter((prev) => ({ ...prev, [key]: value }));
  const applyFilter = () => {
    const filterArgs = [];
    Object.keys(filter).forEach((key) => {
      if (filter[key]) {
        let filterObject = { [key]: filter[key] };
        if (["venueName", "userName", "review", "reply"].includes(key)) {
          filterObject.type = "regex";
        } else if (["status", "stars"].includes(key)) {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }
        filterArgs.push(filterObject);
      }
    });
    toggleModel();
    ratingManagement(filterArgs);
  };
  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    ratingManagement();
  };

  // Lightbox function for images
  const showLightbox = (img) => {
    setImage(img);
    setOpenLightbox(true);
  };

  // Update rating status
  const GetValueOnChange = async (val, _id) => {
    const status = val === 1 ? 1 : 0;
    const formData = new FormData();
    formData.append("ratingId", _id);
    formData.append("status", status);
    const res = await putRequestForm("rating/update", formData);
    if (res.status === 1) {
      toast.success("Rating Status updated Successfully");
      ratingManagement();
    } else {
      toast.error("Something went wrong");
    }
  };

  // Delete rating
  const handlerDelete = (id) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`rating/update`, { ratingId: id });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          ratingManagement();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  // Renderers for table cells
  const userFormatter = (row) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {row.userImage ? (
          <img
            src={row.userImage}
            onClick={() => showLightbox(row.userImage)}
            alt="user"
            width="30"
            style={{ cursor: "pointer", borderRadius: "4px" }}
          />
        ) : (
          <CustomAvatar iconSize={30} name={row?.member?.title} />
        )}
        <span style={{ marginLeft: "8px" }}>{row?.member?.title}</span>
      </div>
    );
  };

  const lastMessageFormatter = (row) => {
    const msg = base64Decode(row.msg, row.chatId);
    if (row.type === "image") {
      return (
        <img
          src={msg}
          alt="chat"
          width="50"
          height="50"
          style={{ objectFit: "cover", borderRadius: "4px" }}
        />
      );
    }
    if (row.type === "audio") {
      return (
        <audio controls style={{ width: "100px" }}>
          <source src={msg} type="audio/mpeg" />
        </audio>
      );
    }
    if (!msg) return "--";
    return msg.length > 50 ? msg.substring(0, 50) + "..." : msg;
  };

  const actionsFormatter = (row) => {
    return (
      <>
        {canReply && (
          <Button color="info">
            <Link
              to={{
                pathname: `/venue-account/chat-window`,
                state: { row },
              }}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              <i className="fas fa-comment"></i>
            </Link>
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <ToastContainer />
      {openLightbox && (
        <Lightbox mainSrc={image} onCloseRequest={() => setOpenLightbox(false)} />
      )}
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={[
          <div className="row mb-4" key="venue">
            <label className="col-sm-3 col-form-label">Venue</label>
            <div className="col-sm-6">
              <input
                type="text"
                value={filter.venueName}
                onChange={(e) => updateFilter("venueName", e.target.value)}
                className="form-control"
              />
            </div>
          </div>,
          <div className="row mb-4" key="user">
            <label className="col-sm-3 col-form-label">User</label>
            <div className="col-sm-6">
              <input
                type="text"
                value={filter.userName}
                onChange={(e) => updateFilter("userName", e.target.value)}
                className="form-control"
              />
            </div>
          </div>,
          <div className="row mb-4" key="title">
            <label className="col-sm-3 col-form-label">Title</label>
            <div className="col-sm-6">
              <input
                type="text"
                value={filter.title}
                onChange={(e) => updateFilter("title", e.target.value)}
                className="form-control"
              />
            </div>
          </div>,
          // Add additional filter inputs for stars, review, reply, status, createdAt as needed...
          <div className="mt-5 mt-lg-4" key="submit">
            <div className="row justify-content-end">
              <div className="col-sm-9">
                <button
                  type="button"
                  onClick={applyFilter}
                  className="btn btn-primary w-md"
                >
                  Filter
                </button>
                <button
                  onClick={resetFilter}
                  type="button"
                  className="btn btn-danger w-md mx-4"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>,
        ]}
      />

      <Row>
        <Col md={12}>
          <div className="page-title-box">
            <h4>All Conversation</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Conversation</li>
              <li className="breadcrumb-item">Conversation List</li>
            </ol>
          </div>
        </Col>

        {/* <Col md={12} className="text-end mb-3">
          {canExport && (
            <Button onClick={exportCSV} color="primary" className="mx-2">
              <i className="fas fa-upload"></i> Export CSV
            </Button>
          )}
        </Col> */}

        <Col md={12}>
          <Card className="custom-shadow-card">
            {loader ? (
              <CardBody style={{ height: "100px", position: "relative" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody className="table-shorting">
                {/* Controls */}
                {/* <div className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <label className="me-2">Page Size:</label>
                    <select
                      value={sizePerPageOrg}
                      onChange={(e) => {
                        setSizePerPage(e.target.value);
                        setCurrentPage(1);
                      }}
                      className="form-select d-inline-block"
                      style={{ width: "80px" }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>

                    <label className="ms-3 me-2">Sort By:</label>
                    <select
                      value={sort}
                      onChange={(e) => {
                        setSort(e.target.value);
                        setCurrentPage(1);
                      }}
                      className="form-select d-inline-block"
                      style={{ width: "120px" }}
                    >
                      <option value="createdAt">Created At</option>
                      <option value="venue">Venue</option>
                      <option value="user">User</option>
                      <option value="title">Title</option>
                      <option value="star">Stars</option>
                      <option value="review">Review</option>
                      <option value="reply">Reply</option>
                    </select>

                    <label className="ms-3 me-2">Order:</label>
                    <select
                      value={sortOrder}
                      onChange={(e) => {
                        setSortOrder(e.target.value);
                        setCurrentPage(1);
                      }}
                      className="form-select d-inline-block"
                      style={{ width: "120px" }}
                    >
                      <option value="desc">Descending</option>
                      <option value="asc">Ascending</option>
                    </select>
                  </div>

                  <div>
                    <input
                      type="search"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      className="form-control d-inline-block"
                      style={{ width: "200px" }}
                    />
                    <Button
                      onClick={() => ratingManagement()}
                      color="primary"
                      className="mx-2"
                    >
                      Search
                    </Button>
                    <Button onClick={handleReload} color="danger" className="mx-2">
                      Reload
                    </Button>
                    <Button onClick={toggleModel} color="secondary">
                      Filter
                    </Button>
                  </div>
                </div> */}

                {/* Custom Table */}
                <div className="custom-table-wrapper">
                  <table className="my-custom-table">
                    <thead>
                      <tr>
                        <th>S.NO</th>
                        <th>User</th>
                        <th>New Conversation</th>
                        <th>Total Messages</th>
                        <th>Last Message</th>
                        <th>Last Message Time</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ratingdata.length === 0 && (
                        <tr>
                          <td colSpan="7" style={{ textAlign: "center" }}>
                            No Data
                          </td>
                        </tr>
                      )}
                      {ratingdata.map((row, index) => (
                        <tr key={row._id || index}>
                          <td>{index + 1}</td>
                          <td>{userFormatter(row)}</td>
                          <td>
                            {row.newMessage ? (
                              <Badge color="danger" className="badge-soft-danger">
                                Yes
                              </Badge>
                            ) : (
                              <Badge color="success" className="badge-soft-success">
                                No
                              </Badge>
                            )}
                          </td>
                          <td>{row.totalMessage || 0}</td>
                          <td>{lastMessageFormatter(row)}</td>
                          <td>
                            {row.createdAt
                              ? new Date(row.createdAt).toLocaleString()
                              : "--"}
                          </td>
                          <td>{actionsFormatter(row)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RatingManagementlist;
