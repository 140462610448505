import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import ReactCrop from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import { parseISO, format } from "date-fns";
import moment from "moment";
import OfferPackageModal from "../../../components/Modal/OfferPackageModal.js";
import FileUpload from "../../../components/Common/FileUpload";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  putRequestForm,
  postRequestForm,
  deleteRequest,
  pushUpdates,
} from "../../../components/Common/Utils.js";
//import DateTimePicker from 'react-datetime-picker';

import PackageModal from "components/Modal/PackageModal.js";
import HolidayModal from "../../../components/Modal/HolidayModal.js";

import MultiSelect from "../../../components/Multiselect/MultiselectCommon.js";
import CancelPolicyModal from "../../../components/Modal/CancelPolicyModal.js";
import IconPackModal from "components/Modal/IconPackModal.js";
import VenueModal from "../../../components/Modal/VenueModalOrg.js";
import Lightbox from "react-image-lightbox";
export default function UpdateOffer() {
  const location = useLocation();
  const blobUrlRef = useRef("");
  const state = location.state?.row;
  const vId = localStorage.getItem("stored_venueId");
  const [isBookingAllowed, setIsBookingAllowed] = useState(state?.venueisBookingAllowed || false);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const today = new Date().toISOString().split("T")[0];
  const [offerData, setOfferData] = useState({ type: "video" ,isTaxIncluded:"true" });
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [iconModal, setIconModal] = useState(false);
  const [icon, setIcon] = useState(state?.bookingIcon || null);

  const [rectangleImage, setRectangleImage] = useState(null);
  const [squareImage, setSquareImage] = useState(null);
  const [storyImage, setStoryImage] = useState(null);
 
  const [policyModal, setPolicyModal] = useState(false);
  const [currentPolicyData, setCurrentPolicyData] = useState(null);
  const [policyRules, setPolicyRules] = useState([]);

  const [remoteRectangle, setRemoteRectangle] = useState(null);
  const [remoteSquare, setRemoteSquare] = useState(null);
  const [remoteStory, setRemoteStory] = useState(null);

  const [rectangleSelected, setrectangleSelected] = useState(0);
const [squareSelected, setSquareSelected] = useState(0);
const [storySelected, setStorySelected] = useState(0);



const [rectangleImagePreview, setRectangleImagePreview] = useState(state?.rectangleImage || null);
const [squareImagePreview, setSquareImagePreview] = useState(state?.squareImage || null);
const [storyImagePreview, setStoryImagePreview] = useState(state?.storyImage || null);

const [completedCrop2, setCompletedCrop2] = useState(null);
  const [completedCrop3, setCompletedCrop3] = useState(null);
  const [cropImage, setCropImage] = useState(null);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });

  const [cropRectangle, setCropRectangle] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 540,
    height: 300,
    aspect: 1.8,
    maxWidth: 540,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 540,
  });


  const [cropSquare, setCropSquare] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 300,
    height: 300,
    aspect: 1,
    maxWidth: 300,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 300,
  });

  const updateCropRectangle = (crop) => {
    const aspectRatio = 1.8; // Desired aspect ratio (1.8:1)
    
    // Calculate the new width and height based on the aspect ratio
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }
  
    setCropRectangle(crop);
  };

  const updateCropSquare = (crop) => {
    const aspectRatio = 1; // Desired aspect ratio (1:1)

    // Calculate the new width and height based on the aspect ratio
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }

    setCropSquare(crop);
  };

  const clearStartDate = () => {
    setOfferData({ ...offerData, startTime: '' });
    document.querySelector('[name="endTime"]').min = '';
  };

  const clearEndDate = () => {
    setOfferData({ ...offerData, endTime: '' });
  };





  const [startLoader, setStartLoader] = useState(false);
  const [venueList, setVenueList] = useState([]);

  const [venueTimings, setVenueTimings] = useState([]);
  const [venueDays, setVenueDays] = useState([]);

  

  const [openPackage, setOpenPackage] = useState(false);

  const [dayList, setDayList] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);

  const [defaultDays, setDefaultDays] = useState([]);

  const [listFetched, setListFetched] = useState(false);

  const [open, setOpen] = useState(false);
  const [packages, setPackages] = useState([]);

  const [model, setModel] = useState(false);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [discountList, setDiscountList] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editingIndex1, setEditingIndex1] = useState(null);
  const [currentPackageData, setCurrentPackageData] = useState({});

  const [modelHoliday, setModelHoliday] = useState(false);

  const [selectedHoliday, setSelectedHoliday] = useState([]);

  const [availableTime, setAvailableTime] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);

  const [regularTime, setRegularTime] = useState({
    start: "",
    end: "",
  });


  const selectRow = (id, name, venue) => {
    // setVenueId(id);
    // setVenueText(name);
    // setVenueImage(venue?.logo);
    // closeModalVenue();

    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    setVenueTimings(venue?.timings);
    setIsBookingAllowed(venue?.venueisBookingAllowed || false);

    const days = venue?.timings?.map((item) => item.day);
    setVenueDays(days);
    closeModalVenue();
  };

  useEffect(() => {
    if (!venueDays) return;

    const fullDayNames = {
      mon: "Monday",
      tue: "Tuesday",
      wed: "Wednesday",
      thu: "Thursday",
      fri: "Friday",
      sat: "Saturday",
      sun: "Sunday",
    };

    const days = venueDays.map((abbr) => {
      const day = fullDayNames[abbr.toLowerCase()];
      return { id: abbr, name: day || abbr };
    });
    setDayList(days);
    setSelectedDay([]);
  }, [venueDays]);

  const closeModalVenue = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const fetchPackages = async () => {
    setLoading(true);
    const response = await postRequest("subscription/membership/list", {
      limit: 10000000,
    });
    setPackages(state.pack);
    setLoading(false);
  };

  const msUpdateCategory = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCategory(ids);
  };

  const updateDiscount = (index, value) => {
    const list = [...packages];
    list[index].discount = value;
    setPackages(list);
  };

  const toggleModal = () => {
    setOpen(!open);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const msUpdateVenue = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedVenue(ids);
  };

  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedDay(ids);
  };

  useEffect(() => {
    if (!state) return;

    state.allowWhosin = state.allowWhosIn ? 1 : 0;

    const parseTime = (dateTime) => {
      const dateObj = parseISO(dateTime);
      const date = format(dateObj, "yyyy-MM-dd"); // Formats to date string
      const time = format(dateObj, "HH:mm:ss"); // Formats to local time string

      return { date, time };
    };
    // Process start time and date


    // remove Z from time    
    state.startTime = state.startTime?.replace("Z", "") || "";
    state.endTime = state.endTime?.replace("Z", "") || "";


    if (state?.startTime) {
      const { date: startDate, time: startTime } = parseTime(state?.startTime);
      state.startTime = startDate;     
    }


    if (state?.allowCliamed === "public-holiday") {
      setSelectedHoliday(state?.publicHolidays || []);
    }

    if (state?.endTime) {
      // Process end time and date
      const { date: endDate, time: endTime } = parseTime(state.endTime);
      state.endTime = endDate;      
    }

    let days = state.days?.split(",") || [];

    if (state?.offerTiming?.type == "regular") {

      const time = state?.offerTiming?.time?.split("-");

      if(time?.length == 2) {
        state.start_time = time[0];
        state.end_time = time[1];
        setRegularTime({
          start: time[0],
          end: time[1],
        });
      }


    
      state.totalSeats = state?.offerTiming.totalSeats;
      setAvailableTime("regular");
    } else {
      
      const timeSlotsTemp = state?.offerTiming?.slots?.map((item) => {
        const time = item?.time?.split("-");
        return {
          start: time[0],
          end: time[1],
          totalSeats: item?.totalSeats,
        };
      });

      if(timeSlotsTemp?.length) {
        setAvailableTime("slot");
        //console.log("timeslots", timeSlotsTemp);

        setTimeSlots([...timeSlotsTemp]);
      }else{
        setAvailableTime("");
        setTimeSlots([]);
      }
      
    }




    if (state?.pack?.length) {

      // in state

      //console.log("state.pack", state.pack)

      const packages = state.pack.map((item) => {
        return {
          ...item,
          package_type: item.type || "regular",
          buyQty: item.buyQty || 0,
          getQty: item.getQty || 0
        }
      });
      setPackages(packages);
    }

    setVenueId(state?.venueId);
    setVenueText(state?.venueName);
    setVenueImage(state?.venue?.logo);

    let daysFull = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];

    // Filter days based on `days` array from state
    days = daysFull.filter((dayFull) => days.includes(dayFull.id));

    setDefaultDays(days);

    //console.log("days", days);

    setSelectedDay(days.map((item) => item.id));

    setPreviewImage(state.image);
    setOfferData(state);
    setSelectedCategory(state?.categoryId ? state?.categoryId : []);    // If you need to fetch data, call those functions here
    
    // fetchPackages();
  }, [state]);

  let history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (!selectedVenue.length) return;
    if (listFetched) return;    
  }, [selectedVenue]);

  useEffect(() => {
    fetchCategoryList();
    const days = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];
    setDayList(days);
  }, [selectedCategory]);



  const fetchCategoryList = async () => {
    const param = { page: 1, limit: 100000 };
    const category = await postRequest("venue/category/list/admin", param);
    if (category.status == 1) {
      const options = category.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCategoryList(options);
      if (selectedCategory.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCategory.includes(item.id);
        });
        setDefaultCategory(defaultItems);
      }
    }
  };

  const toggleModelHoliday = () => {
    setModelHoliday(!modelHoliday);
  };

  const closeModalHoliday = () => {
    setModelHoliday(false);
  };

  const selectHoliday = (items) => {

    setSelectedHoliday(items);
    closeModalHoliday();
    //console.log("items", items);

  };

  const deleteHoliday = (index) => {
    const newHoliday = selectedHoliday.filter((_, idx) => idx !== index);
    setSelectedHoliday(newHoliday);
  };




  function isTimeWithinTimings(offerDate, timings) {
    const offerTime = moment(offerDate);
    const openingTime = moment(
      `${offerDate.split("T")[0]}T${timings.openingTime}`
    );
    const closingTime = moment(
      `${offerDate.split("T")[0]}T${timings.closingTime}`
    );

    // Adjust for next day if closing time is earlier than opening time
    if (closingTime.isBefore(openingTime)) {
      closingTime.add(1, "day");
    }
    return offerTime.isBetween(openingTime, closingTime, null, "[]");
  }

  const search = location.search;
  const from = new URLSearchParams(search).get("from");


  

  // const updateOffer = async (e) => {
  //   e.preventDefault();

  //   // combine date and time
  //   let startDate = null;
  //   let endDate = null;

  //   if (!venueId) {
  //     toast.error("Please select venue");
  //     return;
  //   }

  //   if (!offerData.title) {
  //     toast.error("Please enter title");
  //     return;
  //   }

  //   if (!selectedCategory.length) {
  //     toast.error("Please select category");
  //     return;
  //   }

  //   if (!selectedDay.length) {
  //     // mon tue
  //     // check default days
  //     toast.error("Please select days");
  //     return;
  //   }

  //   if (packages.some(pkg => pkg.isAllowClaim)) {
  //     if (!offerData.specialOffer_title) {
  //       toast.error("Please select Special Offer Title");
  //       return false;
  //     }
  //     if (!offerData.specialOffer_discription) {
  //       toast.error("Please select Special Offer Description");
  //       return false;
  //     }
  //     if (!offerData.specialOffer_discount) {
  //       toast.error("Please select Special Offer Discount");
  //       return false;
  //     }
  //   }

  //   // if (!offerData.startDate) {
  //   //   toast.error("Please select start date");
  //   //   return;
  //   // }

  //   // if (!offerData.endDate) {
  //   //   toast.error("Please select end date");
  //   //   return;
  //   // }

  //   // fetch day from start date & endDate and check is its one of the selected day
  //   const dayMap = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  //   // Fetching the day of the week from start and end dates
  //   const startDayOfWeek = dayMap[new Date(offerData.startDate).getDay()];
  //   const endDayOfWeek = dayMap[new Date(offerData.endDate).getDay()];

  //   // Checking if the day of the week is in the selected days
  //   //  if (!selectedDay.includes(startDayOfWeek)) {
  //   //    toast.error("Start date is not on a selected day");
  //   //    return;
  //   //  }

  //   //  if (!selectedDay.includes(endDayOfWeek)) {
  //   //    toast.error("End date is not on a selected day");
  //   //    return;
  //   //  }

  //   //console.log("venueTimings", venueTimings);

  //   const startDayTiming = venueTimings.find(
  //     (timing) => timing.day === startDayOfWeek
  //   );
  //   const endDayTiming = venueTimings.find(
  //     (timing) => timing.day === endDayOfWeek
  //   );

  //   // Checking timings for start and end days
  //   if (
  //     startDayTiming &&
  //     !isTimeWithinTimings(
  //       offerData.startDate + "T" + offerData.startTime,
  //       startDayTiming
  //     )
  //   ) {
  //     toast.error("Start time is not within the venue timings");
  //     return;
  //   }

  //   if (
  //     endDayTiming &&
  //     !isTimeWithinTimings(
  //       offerData.endDate + "T" + offerData.endTime,
  //       endDayTiming
  //     )
  //   ) {
  //     toast.error("End time is not within the venue timings");
  //     return;
  //   }

  //   if (offerData.startTime && offerData.endTime) {
  //     const [startHours, startMinutes] = offerData.startTime.split(':').map(Number);
  //     const [endHours, endMinutes] = offerData.endTime.split(':').map(Number);

  //     // if (startHours > endHours) {
  //     //   toast.error("End time should be greater than start time");
  //     //   return;
  //     // }

  //     // if (startHours === endHours && startMinutes >= endMinutes) {
  //     //   toast.error("End time should be greater than start time");
  //     //   return;
  //     // }
  //   }


  //   if (offerData.startDate) {
  //     if (offerData.startTime) {
  //       startDate = new Date(offerData.startDate + " " + offerData.startTime);
  //     } else {
  //       startDate = new Date(offerData.startDate + " " + "00:00");
  //     }
  //   }

  //   if (offerData.endDate) {
  //     if (offerData.endTime) {
  //       endDate = new Date(offerData.endDate + " " + offerData.endTime);
  //     } else {
  //       endDate = new Date(offerData.endDate + " " + "00:00");
  //     }
  //   }

  //   // check if the start date is greater than end date

  //   if (startDate && endDate) {
  //     if (startDate >= endDate) {
  //       toast.error("End date should be greater than start date");
  //       return;
  //     }
  //   }

  //   if (!offerData.allowCliamed) {
  //     toast.error("Please select cliam on");
  //     return;
  //   }

  //   // if cliamed on = 'public-holiday' then check selected holiday

  //   if (offerData.allowCliamed === "public-holiday") {
  //     if (selectedHoliday.length === 0) {
  //       toast.error("Please select public holiday");
  //       return;
  //     }
  //   }

  //   let offerTiming = {};
  //   if (availableTime == "regular") {
  //     if (!regularTime?.start || !regularTime?.end) {
  //       toast.error("Please select regular time");
  //       return;
  //     }
  
  //     offerTiming = {
  //       type: "regular",
  //       time: `${regularTime?.start}-${regularTime?.end}`,
  //       totalSeats: offerData?.totalSeats
  //     };
  
  //   } else {
  //     if (!timeSlots?.length) {
  //       toast.error("Please select time slots");
  //       return;
  //     }
  
  //     const slots = timeSlots.map((item) => {
  //       return {
  //         time: item.start + "-" + item.end,
  //         totalSeats: item.totalSeats,
  //       };
  //     });
  
  //     offerTiming = {
  //       type: "slot",
  //       slots: slots
  //     };
  //   }



  //   const formData = new FormData();
  //   formData.append("title", offerData.title);
  //   formData.append("description", offerData.description);
  //   formData.append("discountTag", offerData.discountTag);
  //   formData.append("disclaimerTitle", offerData.disclaimerTitle);
  //   formData.append("disclaimerDescription", offerData.disclaimerDescription);
  //   formData.append(`specialOffer_title`, offerData.specialOffer_title,);
  //   formData.append(`specialOffer_discription`, offerData.specialOffer_discription);
  //   formData.append(`specialOffer_discount`, offerData.specialOffer_discount || 0);
  //   formData.append("offerTiming", JSON.stringify(offerTiming));
  //   formData.append("allowCliamed", offerData.allowCliamed);


  //   const selectedHolidayIds = selectedHoliday.map((item) => item._id);

  //   selectedHolidayIds.forEach((id, index) => {
  //     formData.append(`publicHolidays[${index}]`, id);
  //   });


  //   formData.append("venue", venueId || "");

  //   // sort the selected days by day order
  //   selectedDay.sort((a, b) => {
  //     const dayOrder = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  //     return dayOrder.indexOf(a) - dayOrder.indexOf(b);
  //   });
  //   formData.append("days", selectedDay.join(","));
  //   if (selectedCategory && selectedCategory.length > 0) {
  //     selectedCategory.forEach((item) => {
  //       formData.append("categoryId[]", item);
  //     });
  //   }

  //   if (selectedImage) {
  //     formData.append("image", selectedImage);
  //   }

  //   // formData.append("allowWhosin", offerData.allowWhosin == "1" ? true : false);
  //   if (offerData.startDate) {
  //     formData.append("startTime", startDate);
  //   }
  //   if (offerData.endDate) {
  //     formData.append("endTime", endDate);
  //   }
  //   formData.append("offerId", state?._id || "");
  //   const finalPackages = packages.filter((pack) => pack.title && pack.discount && pack.reservationAmount);
  //   if (packages.length && finalPackages.length === 0) {
  //     toast.error("Please add atleast one package with title and valid data");
  //     return;
  //   }
  //   finalPackages.forEach((item, index) => {
  //     formData.append(`packages_type[${index}]`, item.package_type || "regular");
  //     formData.append(`packages_id[${index}]`, item._id || "");
  //     formData.append(`packages_title[${index}]`, item.title);     
  //     formData.append(`packages_reservationAmount[${index}]`, item.reservationAmount || 0);
  //     formData.append(`packages_discount[${index}]`, item.discount || 0);
  //     formData.append(`packages_amount[${index}]`, item.amount || 0);
  //     formData.append(
  //       `packages_description[${index}]`,
  //       item.packages_description || item.description || " "
  //     );
  //   });

  //   // packages.forEach((item, index) => {
  //   //   formData.append(`package_ids[]`, item._id);
  //   //   formData.append(`package_keys[]`, item.package_key);
  //   //   formData.append(`offer_discounts[]`, item.discount);
  //   // });

  //   setLoading(true);
  //   const response = await putRequestForm("venue/offer/update", formData);
  //   setLoading(false);

  //   if (response.status == 1) {
  //     // If the update is successful, call the pushUpdates function
  //     try {
  //       const pushUpdatesResponse = await pushUpdates({
  //         type: "offer",
  //         id: id,
  //         receivers: [],
  //       });
  //       // Check if the pushUpdates call was successful, handle the response as needed
  //       toast.success(response.message);

  //       setTimeout(() => {
  //         if (!from) history.goBack();
  //         else history.push("/venuedetails/" + venueId + "?active=Offers");
  //       }, 3000);
  //     } catch (pushUpdatesError) {
  //       console.error("Error in pushUpdates:", pushUpdatesError);
  //       toast.error("Error in pushUpdates");
  //     }
  //   } else {
  //     toast.error(response.message);
  //   }
  // };

  const addPolicyData = (data) => {
    console.log("cancel policy", data);
    let venueDt = {...offerData}
    venueDt.cancelPolicy = data;
    setOfferData(venueDt);   
  }


  const editPolicyData = (index) => {
    let currentPolicyRule = policyRules[index];
    setCurrentPolicyData(currentPolicyRule);
    setPolicyModal(true);
  }

  const deletePolicyData = (index) => {
    let policyLists = [...policyRules];
    policyLists.splice(index, 1);
    setPolicyRules(policyLists);
  }
 

  const closeIconModal = () => {
    setIconModal(false);
  };
  
  const openIconModal = (index) => {  
    setIconModal(true);
  };
  
  const selectIconRow = (icon) => {
    console.log("icon", icon);
    setIcon(icon.image);  
    closeIconModal();
  };





  const updateOffer = async (e) => {
    e.preventDefault();
  
    let startDate = null;
    let endDate = null;
  
    // Validate required fields
   
    if (!offerData.title) {
      toast.error("Please enter title");
      return;
    }
    if (!offerData.description) {
      toast.error("Please enter description");
      return;
    }
    if (!selectedCategory.length) {
      toast.error("Please select category");
      return;
    }
    if (!selectedDay.length) {
      toast.error("Please select days");
      return;
    }
    if (packages.some(pkg => pkg.isAllowClaim)) {
      if (!offerData.specialOffer_title) {
        toast.error("Please select Special Offer Title");
        return false;
      }
      if (!offerData.specialOffer_discription) {
        toast.error("Please select Special Offer Description");
        return false;
      }
      if (!offerData.specialOffer_discount) {
        toast.error("Please select Special Offer Discount");
        return false;
      }
    }
  
    const dayMap = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const startDayOfWeek = dayMap[new Date(offerData.startTime).getDay()];
    const endDayOfWeek = dayMap[new Date(offerData.endTime).getDay()];
  
    const startDayTiming = venueTimings.find(timing => timing.day === startDayOfWeek);
    const endDayTiming = venueTimings.find(timing => timing.day === endDayOfWeek);
  
    // if (
    //   startDayTiming &&
    //   !isTimeWithinTimings(
    //     offerData.startTime + "T" + offerData.startTimeLabel,
    //     startDayTiming
    //   )
    // ) {
    //   toast.error("Start time is not within the venue timings");
    //   return;
    // }
  
    // if (
    //   endDayTiming &&
    //   !isTimeWithinTimings(
    //     offerData.endTime + "T" + offerData.endTimeLabel,
    //     endDayTiming
    //   )
    // ) {
    //   toast.error("End time is not within the venue timings");
    //   return;
    // }
  
    if (offerData.startTimeLabel && offerData.endTimeLabel) {
      const [startHours, startMinutes] = offerData.startTimeLabel.split(':').map(Number);
      const [endHours, endMinutes] = offerData.endTimeLabel.split(':').map(Number);
    }
  
    if (offerData.startTime) {
      if (offerData.startTimeLabel) {
        startDate = new Date(offerData.startTime + " " + offerData.startTimeLabel);
      } else {
        startDate = new Date(offerData.startTime + " " + "00:00");
      }
    }
  
    if (offerData.endTime) {
      if (offerData.endTimeLabel) {
        endDate = new Date(offerData.endTime + " " + offerData.endTimeLabel);
      } else {
        endDate = new Date(offerData.endTime + " " + "00:00");
      }
    }
  
    if (startDate && endDate) {
      // if (startDate >= endDate) {
      //   toast.error("End date should be greater than start date");
      //   return;
      // }
    }
  
    if (!offerData.allowCliamed) {
      toast.error("Please select cliam on");
      return;
    }
  
    if (offerData.allowCliamed === "public-holiday") {
      if (selectedHoliday.length === 0) {
        toast.error("Please select public holiday");
        return;
      }
    }

    if(isBookingAllowed){
      if(!offerData.bookingText){
        toast.error("Please enter booking text");
        return;
      }
  
      if(!icon){
        toast.error("Please select booking icon");
        return;
      }
    }

    if(offerData.isTaxIncluded == "false"){
      if(offerData.vat === ""){
        toast.error("Please enter VAT value");
        return
      }
  }
  
    let offerTiming = {};
    if (availableTime == "regular") {
      if (!regularTime?.start || !regularTime?.end) {
        toast.error("Please select regular time");
        return;
      }
      offerTiming = {
        type: "regular",
        time: `${regularTime?.start}-${regularTime?.end}`,
        totalSeats: offerData?.totalSeats
      };
    } else {
      if (!timeSlots?.length) {
        toast.error("Please select time slots");
        return;
      }
      const slots = timeSlots.map((item) => {
        return {
          time: item.start + "-" + item.end,
          totalSeats: item.totalSeats,
        };
      });
      offerTiming = {
        type: "slot",
        slots: slots
      };
    }
  
    const formData = new FormData();
   
    formData.append("title", offerData.title.trim());
    formData.append("description", offerData.description);
    formData.append("discountTag", offerData.discountTag);
    formData.append("disclaimerTitle", offerData.disclaimerTitle);
    formData.append("disclaimerDescription", offerData.disclaimerDescription);
    formData.append("specialOffer_title", offerData.specialOffer_title);
    formData.append("specialOffer_discription", offerData.specialOffer_discription);
    formData.append("specialOffer_discount", offerData.specialOffer_discount || 0);
    formData.append("offerTiming", JSON.stringify(offerTiming));
    formData.append("allowCliamed", offerData.allowCliamed);
    formData.append("IsInstantBooking", offerData.IsInstantBooking || false);
    formData.append("isTaxIncluded", offerData.isTaxIncluded || false);
    formData.append("vat", offerData.vat || 0);
    formData.append("totalQuantity", offerData.totalQuantity || 1);
    formData.append("minQuantityPurchase", offerData.minQuantityPurchase || 1);
    formData.append("maxQuantityPurchase", offerData.maxQuantityPurchase || 0);
    
    if(offerData.isBookingAllowed){
    if(offerData.bookingText){
      formData.append("bookingText", offerData.bookingText);
    }

    if(icon){
      formData.append("bookingIcon", icon);
    }
  }
   
    if(offerData.cancelPolicy){
      const cancelPolicy= JSON.stringify(offerData.cancelPolicy);
      formData.append("cancelPolicy", cancelPolicy);
    }

    // add start and end time

    if (offerData.startTime) {
      formData.append("startTime", startDate);
    }

    if (offerData.endTime) {
      formData.append("endTime", endDate);
    }

    // add time label

    if(offerData.startTimeLabel ) formData.append("startTimeLabel", offerData.startTimeLabel);
    if(offerData.endTimeLabel ) formData.append("endTimeLabel", offerData.endTimeLabel); 
  
    const selectedHolidayIds = selectedHoliday.map((item) => item._id);
    selectedHolidayIds.forEach((id, index) => {
      formData.append(`publicHolidays[${index}]`, id);
    });

    const vId = localStorage.getItem("stored_venueId");
  
    formData.append("venue", vId || "");
    formData.append("offerId", state?._id || "");

    selectedDay.sort((a, b) => {
      const dayOrder = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
      return dayOrder.indexOf(a) - dayOrder.indexOf(b);
    });
    formData.append("days", selectedDay.join(","));
    if (selectedCategory && selectedCategory.length > 0) {
      selectedCategory.forEach((item) => {
        formData.append("categoryId[]", item);
      });
    }
  
    // const finalPackages = packages.filter((pack) => pack.title);
    // if (packages.length && finalPackages.length === 0) {
    //   toast.error("Please add at least one package with title and valid data");
    //   return;
    // }

    const finalPackages = packages;

    finalPackages.forEach((item, index) => {
      formData.append(`packages_type[${index}]`, item.package_type || "regular");
      formData.append(`packages_id[${index}]`, item._id || "");
      formData.append(`packages_title[${index}]`, item.title);
      formData.append(`packages_reservationAmount[${index}]`, item.reservationAmount || 0);
      formData.append(`packages_discount[${index}]`, item.discount || 0);
      formData.append(`packages_amount[${index}]`, item.amount || 0);
      formData.append(`packages_qty[${index}]`, item.qty || 0);
      formData.append(`packages_leftqty[${index}]`, item.leftQty || 0);
      formData.append(`packages_buyqty[${index}]`, item.buyQty || 0);
      formData.append(`packages_getqty[${index}]`, item.getQty || 0);
      formData.append(`packages_minQuantityPurchase[${index}]`, item.packages_minQuantityPurchase || 1);
      formData.append(`packages_maxQuantityPurchase[${index}]`, item.packages_maxQuantityPurchase || 0);

      formData.append(
        `packages_description[${index}]`,
        item.packages_description || item.description || " "
      );
    });
  
    setLoading(true);
  
    try {
      const uploadPromises = [
        selectedImage ? postRequestForm("comman/img/upload", createFormData("image", selectedImage)) : Promise.resolve(null),
        remoteRectangle ? postRequestForm("comman/img/upload", createFormData("image", remoteRectangle)) : Promise.resolve(null),
        remoteSquare ? postRequestForm("comman/img/upload", createFormData("image", remoteSquare)) : Promise.resolve(null),
        remoteStory ? postRequestForm("comman/img/upload", createFormData("image", remoteStory)) : Promise.resolve(null)
      ];
  
      const [imageResponse, rectangleResponse, squareResponse, storyResponse] = await Promise.all(uploadPromises);
  
      appendFormData(formData, {
        image: imageResponse?.data?.url || "",
        rectangleImage: rectangleResponse?.data?.url || "",
        squareImage: squareResponse?.data?.url || "",
        storyImage: storyResponse?.data?.url || "",
      });
  
      const response = await putRequestForm("venue/offer/update", formData);
      setLoading(false);
  
      if (response.status === 1) {
        try {
          await pushUpdates({
            type: "offer",
            id: state?._id,
            receivers: [],
          });
          toast.success(response.message);
          setTimeout(() => {
            if (!from) history.goBack();
            else history.push("/venuedetails/" + venueId + "?active=Offers");
          }, 3000);
        } catch (pushUpdatesError) {
          console.error("Error in pushUpdates:", pushUpdatesError);
          toast.error("Error in pushUpdates");
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Failed to update offer:", error);
      toast.error("Failed to process the request.");
    } finally {
      setLoading(false);
    }
  };

  function closePolicyModal() {
    setPolicyModal(false);
  }
  
  // Helper function to create FormData
  const createFormData = (key, file) => {
    const formData = new FormData();
    formData.append(key, file);
    return formData;
  };
  
  // Helper function to append form data
  const appendFormData = (formData, data) => {
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  };
  

  const updateValue = (index, value) => {
    const list = { ...offerData };
    list[index] = value;
    setOfferData(list);
  };

  const addPackage = () => {

    setCurrentPackageData({});

    setOpenPackage(true);
    // setPackages([
    //   ...packages,
    //   { title: "", amount: "", discount: "", qty: "",description:"", isFeatured: false },
    // ]);
  };

  const addPackageData = (data) => {

    // check editingIndex is not -1
    let newPackages = [...packages];

    // If the new package is marked as featured, unselect any previously selected featured packages
    if (data.packages_isFeatured) {
      newPackages = newPackages.map(pkg => {
        pkg.packages_isFeatured = false
        return pkg;
      });
      // setPackages(newPackages);
    }

    console.log("data", data);


    if (editingIndex !== -1) {
      // const newPackages = [...packages];
      newPackages[editingIndex] = data;
      setPackages(newPackages);
      setCurrentPackageData({});
      setEditingIndex(-1);
      setOpenPackage(false);
      return;
    }

    setPackages([...newPackages, data]);
    setOpenPackage(false);
  };

  const updatePackageData = (index, field, value) => {

    // if (field === "discount" && (value == 0 || value < 5)) {
    //   alert("Discount is only allowed to be 0 or greater than 5");
    //   return; // Do not proceed with the update
    // }

    const newPackages = [...packages];



    newPackages[index][field] = value;

    console.log("newPackages", newPackages);

    setPackages(newPackages);
  };

  const saveEditedPackage = (index) => {
    // Add any validation logic here before saving

    // if (packages[index].title === "") {
    //   toast.error("Please enter package title");
    //   return;
    // }

    // check amount is number

    if (isNaN(packages[index].amount)) {
      toast.error("Amount must be number");
      return;
    }

    // check amount is not negative

    if (packages[index].amount < 1) {
      toast.error("Amount must be greater than 0");
      return;
    }

    // check discount 0 - 100

    if (packages[index].discount) {
      if (isNaN(packages[index].discount)) {
        toast.error("Discount must be number");
        return;
      }

      // check discount not be 0

      // if (packages[index].discount < 1) {
      //   toast.error("Discount must be greater than 0");
      //   return;
      // }

      if (packages[index].discount < 5) {
        toast.error("Discount must be 5 or greater than 5");
        return;
      }

      if (packages[index].discount > 99) {
        toast.error("Discount must be less than 100");
        return;
      }
    }

    // // cliam code must be 8 digit
    // if (packages[index].claimCode){
    //   if(packages[index].claimCode.length !== 8) {
    //     toast.error("Claim code must be  8 digit");
    //     return;
    //   }
    //   if (/^[A-Za-z]{2}\d{6}$/.test(packages[index].claimCode) === false) {
    //     toast.error("Claim code must be 2 letter and 6 digit");
    //     return;
    //   }
    // }

    setEditingIndex(null); // Reset editing index
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((_, idx) => idx !== index);
    setPackages(newPackages);
  };

  const editPackage = (index) => {
    setEditingIndex(index);
    let currentPackageDt = packages[index];
    currentPackageDt.packages_description = currentPackageDt.description;
    currentPackageDt.packages_maxQuantityPurchase = currentPackageDt.maxQuantityPurchase;
    currentPackageDt.packages_minQuantityPurchase = currentPackageDt.minQuantityPurchase;
    console.log("currentPackageDt", currentPackageDt);

    setCurrentPackageData({ ...currentPackageDt });
    setOpenPackage(true);
  };

  const closePackageModal = () => {
    setOpenPackage(false);
  };

  const [allAllowSale, setAllAllowSale] = React.useState(false);
  const allAllowSaleSelected = (e) => {
    let value = e.target.value;
    setAllAllowSale(!allAllowSale);
    if (!allAllowSale) {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowSale: true };
      });
      setPackages(updatedPackages);
    } else {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowSale: false };
      });
      setPackages(updatedPackages);
    }
  };
  const [allAllowClaim, setAllAllowClaim] = React.useState(false);
  const allAllowClaimSelected = (e) => {
    let value = e.target.value;
    setAllAllowClaim(!allAllowClaim);
    if (!allAllowClaim) {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowClaim: true };
      });
      setPackages(updatedPackages);
    } else {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowClaim: false };
      });
      setPackages(updatedPackages);
    }
  };


  const deleteSlot = (index) => {
    const updatedSlots = [...timeSlots];
    updatedSlots.splice(index, 1);
    setTimeSlots(updatedSlots);
  };

  const updateRegulaTime = (type, value) => {
    const updatedSlots = { ...regularTime };
    updatedSlots[type] = value;
    setRegularTime(updatedSlots);
  };

  const addSlot = () => {
    setTimeSlots([...timeSlots, { start: "", end: "", totalSeats: 0 }]);
  };

  const updateSlot = (index, type, value) => {
    const updatedSlots = [...timeSlots];
    updatedSlots[index][type] = value;
    setTimeSlots(updatedSlots);
  };

  const handleRectangleImageChange = (e) => {
    const file = e.target.files[0];
    setRectangleImage(file);
    setRectangleImagePreview(URL.createObjectURL(file));
  };
  
  const handleSquareImageChange = (e) => {
    const file = e.target.files[0];
    setSquareImage(file);
    setSquareImagePreview(URL.createObjectURL(file));
  };
  
  const handleStoryImageChange = (e) => {
    const file = e.target.files[0];
    setStoryImage(file);
    setStoryImagePreview(URL.createObjectURL(file));
  };

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], offerData.title + "-rectangle.jpg", {
        type: "image/jpeg",
      });
      setRemoteRectangle(file);
    });
  }, [rectangleSelected]);

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], offerData.title + "-square.jpg", {
        type: "image/jpeg",
      });

      setRemoteSquare(file);
    });
  }, [squareSelected]);

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], offerData.title + "-story.jpg", {
        type: "image/jpeg",
      });

      setRemoteStory(file);
    });

  }, [storySelected]);

  const handleCropComplete2 = (crop) => {
    setCompletedCrop2(crop);
  };

  const handleCropComplete3 = (crop) => {
    setCompletedCrop3(crop);
  };

  const handleCropComplete4 = (crop) => {
    setCropImage(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop2?.width &&
        completedCrop2?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop2,
          scale,
          rotate
        );

        setrectangleSelected(rectangleSelected + 1);
      }
    },
    100,
    [completedCrop2]
  );

  useDebounceEffect(
    async () => {
      if (
        completedCrop3?.width &&
        completedCrop3?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop3,
          scale,
          rotate
        );
        setSquareSelected(squareSelected + 1);
      }
    },
    100,
    [completedCrop3]
  );

  useDebounceEffect(
    async () => {
      if (
        cropImage?.width &&
        cropImage?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          cropImage,
          scale,
          rotate
        );
        setStorySelected(storySelected + 1);
      }
    },
    100,
    [cropImage]
  );

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Offer Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Offer Management</li>:{" "}
              <li className="breadcrumb-item">Update Offer</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update offer </h4>
                <Form onSubmit={updateOffer}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              value={offerData.title}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* {offerData.description} */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={offerData.description}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Discount Tag
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("discountTag", e.target.value)
                              }
                              value={offerData.discountTag}
                              name="discountTag"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Disclaimer Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={offerData?.disclaimerTitle}
                              onChange={(e) =>
                                updateValue("disclaimerTitle", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Disclaimer Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("disclaimerDescription", e.target.value)
                              }
                              value={offerData?.disclaimerDescription}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Category
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={categoryList}
                              onSelect={msUpdateCategory}
                              onRemove={msUpdateCategory}
                              selectedValues={defaultCategory}
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Days
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={dayList}
                              onSelect={msUpdateDay}
                              onRemove={msUpdateDay}
                              selectedValues={defaultDays}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) => {
                                const selectedDate = e.target.value;
                                if (selectedDate) {
                                  updateValue("startTime", selectedDate);
                                  // Update the minimum date for the End Date input
                                  const endDateInput =
                                    document.querySelector('[name="endTime"]');
                                  if (endDateInput) {
                                    endDateInput.min = selectedDate;
                                  }
                                }
                              }}
                              name="startTime"
                              value={offerData.startTime}                              
                              className="form-control"
                            />

                            <a
                              color="secondary"                              
                              onClick={clearStartDate}
                              style={{ marginLeft: "10px", marginTop: "10px", display: "block" }}
                            >
                              Clear
                            </a>

                          </div>
                        </div>



                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) =>
                                updateValue("startTimeLabel", e.target.value)
                              }
                              name="startTime"
                              value={offerData.startTimeLabel}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) => {
                                const selectedDate = e.target.value;
                                if (selectedDate >= offerData?.startTime) {
                                  updateValue("endTime", selectedDate);
                                }
                              }}
                              name="endTime"
                              className="form-control"
                              value={offerData.endTime}
                              min={offerData.startTime} // Set the minimum date to today
                            // value={formData.endDate}
                            />

                            <a
                              color="secondary"
                              onClick={clearEndDate}
                              style={{ marginLeft: "10px", marginTop: "10px", display: "block" }}
                            >
                              Clear
                            </a>

                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) =>
                                updateValue("endTimeLabel", e.target.value)
                              }
                              name="endTime"
                              value={offerData.endTimeLabel}
                              className="form-control"
                            />
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Whosin
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("allowWhosin", e.target.value)
                              }
                              value={offerData.allowWhosin}
                              name="allowWhosin"
                              className="form-select"
                            >
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                        </div> */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Allow Claimed On
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("allowCliamed", e.target.value)
                              }
                              value={offerData.allowCliamed}
                              name="allowWhosin"
                              className="form-select"
                            >
                              <option value="all-week" selected>All week</option>
                              <option value="weekeneds">Weekends</option>
                              <option value="weekdays">Weekdays</option>
                              <option value="public-holiday">Public Holidays</option>
                            </select>
                          </div>
                        </div>

                        {offerData?.allowCliamed === "public-holiday" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Holiday's List
                            </label>
                            <div className="col-sm-8">
                              <button
                                type="button"
                                onClick={toggleModelHoliday}
                                className="btn btn-primary mb-3"
                              >
                                Select Holiday
                              </button>
                              <div className="table-responsive">

                                <table className="table table-bordered">

                                  <thead>
                                    <tr>
                                      <th className="col-2">Holiday Name</th>
                                      <th className="col-2">Holiday Date</th>
                                      <th className="col-1">Action</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {selectedHoliday.map((item, index) => (
                                      <tr key={index}>
                                        <td className="col-2">
                                          {item.title}
                                        </td>
                                        <td className="col-2">
                                          {item.date}
                                        </td>
                                        <td className="col-1">
                                          <button
                                            type="button"
                                            onClick={() => deleteHoliday(index)}
                                            className="btn btn-danger mb-3"
                                          >
                                            Delete
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>

                              </div>
                            </div>
                          </div>
                        )}


                      <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Available Time for Booking
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-select"
                              onChange={(e) => setAvailableTime(e.target.value)}
                              value={availableTime || "regular"}

                            >
                              <option value="">Select Time</option>
                              <option value="regular">Regular</option>
                              {/* <option value="slot">Multiple Slot</option> */}
                            </select>
                          </div>
                        </div>

                        {/* {availableTime === "regular" && ( */}
                          <>
                            {/* <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Quantity of Seats
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    updateValue("totalSeats", e.target.value)
                                  }
                                  defaultValue={offerData?.totalSeats}
                                  name="discount"
                                  className="form-control"
                                />
                              </div>
                            </div> */}

                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Start Time / End Time
                              </label>
                              <div className="col-sm-3">
                                <label>Start Time:</label>
                                <input
                                  type="time"
                                  onChange={(e) =>
                                    updateRegulaTime("start", e.target.value)
                                  }
                                  className="form-control"
                                  defaultValue={regularTime.start}
                                />
                              </div>
                              <div className="col-sm-3">
                                <label>End Time:</label>
                                <input
                                  type="time"
                                  onChange={(e) =>
                                    updateRegulaTime("end", e.target.value)
                                  }
                                  className="form-control"
                                  defaultValue={regularTime.end}
                                />
                              </div>
                            </div>
                          </>
                        {/* )} */}

                        {/* {availableTime === "slot" && (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Select Slots
                            </label>

                            <div className="col-sm-6">
                              {timeSlots.map((slot, index) => (
                                <div className="col-sm-12" key={index}>
                                  <div
                                    class="row"
                                    style={{ marginBottom: "16px" }}
                                  >
                                    <div className="col-sm-3">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={slot.start}
                                        onChange={(e) =>
                                          updateSlot(
                                            index,
                                            "start",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="col-sm-3">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={slot.end}
                                        onChange={(e) =>
                                          updateSlot(
                                            index,
                                            "end",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="col-sm-3">
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={slot.totalSeats}
                                        onChange={(e) =>
                                          updateSlot(
                                            index,
                                            "totalSeats",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="col-sm-3">
                                      <button
                                        type="button"
                                        style={{
                                          color: "white",
                                          backgroundColor: "red",
                                          border: "none",
                                          padding: "10px 20px",
                                        }}
                                        onClick={() => deleteSlot(index)}
                                      >
                                        Delete Slot
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}

                              <button
                                type="button"
                                style={{
                                  color: "white",
                                  backgroundColor: "green",
                                  border: "none",
                                  padding: "10px 20px",
                                }}
                                onClick={addSlot}
                              >
                                Add Slot
                              </button>
                            </div>
                          </div>
                        )} */}



                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Rectangle Image</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleRectangleImageChange} name="rectangleImage" className="form-control" />
                          <br />                         

                          {rectangleImagePreview && rectangleImage  ? (
                             <ReactCrop
                             crop={cropRectangle}
                             src={rectangleImagePreview}
                             onChange={(newCrop) => updateCropRectangle(newCrop)}
                             onComplete={handleCropComplete2}
                             style={{
                               maxWidth: "720px",
                               maxHeight: "600px",
                             }}
                             >                             
                              <img ref={imgRef} src={rectangleImagePreview} />
                            </ReactCrop>
                         
                          ) : rectangleImagePreview ? (
                            <img
                              style={{ width: "180px", height: "100px" }}
                              src={rectangleImagePreview}
                              onClick={() => showLightBox2(rectangleImagePreview)}
                            />
                          ) : null}

                          {completedCrop2 && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: completedCrop2.width,
                                  height: completedCrop2.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Square Image</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleSquareImageChange} name="squareImage" className="form-control" />
                          <br />
                          {squareImagePreview && squareImage ? (
                             <ReactCrop
                             crop={cropSquare}
                             src={squareImagePreview}
                             onChange={(newCrop) => updateCropSquare(newCrop)}
                             onComplete={handleCropComplete3}
                             style={{
                               maxWidth: "720px",
                               maxHeight: "600px",
                             }}
                             >
                             
                             <img ref={imgRef} src={squareImagePreview} />
                            </ReactCrop>
                            
                          ): squareImagePreview ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={squareImagePreview}
                              onClick={() => showLightBox2(squareImagePreview)}
                            />
                          ) : null}

                          {completedCrop3 && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: completedCrop3.width,
                                  height: completedCrop3.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Story Image</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleStoryImageChange} name="storyImage" className="form-control" />
                          <br />
                          {storyImagePreview && storyImage ? (
                            <ReactCrop
                            crop={crop}
                            src={storyImagePreview}
                            onChange={(newCrop) => setCrop(newCrop)}
                            onComplete={handleCropComplete4}
                            style={{
                              maxWidth: "720px",
                              maxHeight: "600px",
                            }}
                            >
                            
                            <img ref={imgRef} src={storyImagePreview} />
                           </ReactCrop>
                           
                          ): storyImagePreview ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={storyImagePreview}
                              onClick={() => showLightBox2(storyImagePreview)}
                            />
                          ) : null}

                          {cropImage && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: cropImage.width,
                                  height: cropImage.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>




                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Enter Packages Discounts
                          </label>
                          <div className="col-sm-6">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Package Title</th>
                                  <th>Package Amount</th>
                                  <th>Package Sub title</th>
                                  <th>Offer Discount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr>
                                    <td>{item.title}</td>
                                    <td>
                                      {item.discountedPrice} / {item.time}
                                    </td>
                                    <td>{item.subTitle}</td>
                                    <td>
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          updateDiscount(index, e.target.value)
                                        }
                                        value={item.discount}
                                        name="discount"
                                        className="form-control"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div> */}
                          <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Is Tax Included
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("isTaxIncluded", e.target.value)
                            }
                            value={offerData.isTaxIncluded}
                            className="form-select"
                          >
                            <option value="true">
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>

                      {offerData.isTaxIncluded == "false" && (
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">VAT %</label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              min="0"
                              max="99"
                              onChange = {(e) => updateValue("vat", e.target.value)}
                              // defaultValue={offerData?.vat || ""}
                              
                              name="vat"
                              value={offerData.vat}
                              className="form-control"
                            />
                          </div>
                        </div>
                      )}

<div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Is Instant Booking
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("IsInstantBooking", e.target.value)
                            }
                            value={offerData.IsInstantBooking}
                            className="form-select"
                          >
                            <option value="true">
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>

                     {/* <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Total Quantity
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            min="1" // Set minimum value to 1
                            onChange={(e) =>
                              updateValue("totalQuantity", e.target.value)
                            }
                            value={offerData.totalQuantity}
                            name="title"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Minimun Quantity Purchase
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            min="1" // Set minimum value to 1
                            onChange={(e) =>
                              updateValue("minQuantityPurchase", e.target.value)
                            }
                            value={offerData.minQuantityPurchase}
                            name="title"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Maximum Quantity Purchase
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"                          
                            onChange={(e) =>
                              updateValue("maxQuantityPurchase", e.target.value)
                            }
                            value={offerData.maxQuantityPurchase}
                            name="title"
                            className="form-control"
                          />
                        </div>
                      </div> */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Enter Packages Discounts
                          </label>
                          <div className="col-sm-8">
                            <button
                              type="button"
                              onClick={addPackage}
                              className="btn btn-primary mb-3"
                            >
                              Add Package
                            </button>
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th className="col-2">Type</th>
                                    <th className="col-2">Title</th>
                                    <th className="col-2">
                                      Description
                                    </th>
                                    <th className="col-1">Amount</th>
                                    <th className="col-1">Discount</th>
                                    <th className="col-1">Reservation Amount %</th>
                                    <th className="col-1">QTY</th>
                                    <th className="col-1">Left Qty Alert On</th>
                                    <th className="col-1">Buy QTY</th>
                                    <th className="col-1">Get QTY</th>
                                    {/* <th className="col-1">Claim Code</th> */}
                                   
                                    {/* <th className="col-1">Is Featured</th> */}
                                    <th className="col-1">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {packages.map((item, index) => (
                                    <tr key={index}>
                                      <td className="col-2">
                                        {item?.package_type || "Regular"}
                                      </td>
                                      <td className="col-2">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="text"
                                            value={item.title}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "title",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.title
                                        )}
                                      </td>
                                      <td className="col-2">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="text"
                                            defaultValue={
                                              item.packages_description
                                                ? item.packages_description
                                                : item.description
                                            }
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "packages_description",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : item.packages_description ? (
                                          item.packages_description
                                        ) : (
                                          item.description
                                        )}
                                      </td>    

                                      <td className="col-1">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="number"
                                            value={item.amount || 0}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "amount",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.amount || 0
                                        )}
                                      </td>                              
                                      <td className="col-1">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="number"
                                            value={item.discount || 0}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "discount",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.discount || 0
                                        )}
                                      </td>

                                      <td className="col-1">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="number"
                                            value={item.reservationAmount || 0}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "reservationAmount",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.reservationAmount || 0
                                        )}
                                      </td>

                                      <td className="col-1">
                                        {editingIndex === index ? (
                                          <input
                                            type="text"
                                            value={item.qty}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "qty",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.qty
                                        )}
                                      </td>

                                      <td className="col-1">
                                        {editingIndex === index ? (
                                          <input
                                            type="text"
                                            value={item.leftQtyAlert}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "leftQtyAlert",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.leftQtyAlert
                                        )}
                                      </td>

                                      <td className="col-1">
                                        {editingIndex === index ? (
                                          <input
                                            type="text"
                                            value={item.buyQty}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "buyQty",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.buyQty
                                        )}
                                      </td>

                                      <td className="col-1">
                                        {editingIndex === index ? (
                                          <input
                                            type="text"
                                            value={item.getQty}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "getQty",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.getQty
                                        )}
                                      </td>



                                      {/* <td className="col-1">
                                        {editingIndex === index ? (
                                          <input
                                            type="text"
                                            value={item.claimCode}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "claimCode",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.claimCode
                                        )}
                                      </td> */}
                                    

                                      {/* <td className="col-1">
                                        <input
                                          type="checkbox"
                                          checked={item.isFeatured}
                                          onChange={(e) =>
                                            updatePackageData(
                                              index,
                                              "isFeatured",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </td> */}

                                      <td className="col-1">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {/* {editingIndex === index ? (
                                            <button
                                              type="button"
                                              className="btn btn-success btn-sm me-1"
                                              onClick={() => {
                                                saveEditedPackage(index);
                                              }}
                                            >
                                              Save
                                            </button>
                                          ) : (
                                            <span
                                              role="button"
                                              className="btn btn-primary btn-sm me-1"
                                              onClick={() => {
                                                setEditingIndex(index);
                                              }}
                                            >
                                              Edit
                                            </span>
                                          )} */}

                                          <i
                                            className="fas fa-pencil-alt"
                                            onClick={() => editPackage(index)}
                                            style={{ cursor: "pointer", color: "#E22A2A" }}
                                          ></i>


                                          <i
                                            className="fas fa-trash-alt mx-3"
                                            onClick={() => deletePackage(index)}
                                            style={{ cursor: "pointer", color: "red" }}
                                          ></i>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        
                        {packages.some(pkg => pkg.isAllowClaim) && (<>
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Special Offer Title
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                defaultValue={offerData?.specialOffer_title}
                                onChange={(e) =>
                                  updateValue("specialOffer_title", e.target.value)
                                }
                                name="title"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Special Offer Description
                            </label>
                            <div className="col-sm-6">
                              <textarea
                                type="textarea"
                                onChange={(e) =>
                                  updateValue("specialOffer_discription", e.target.value)
                                }
                                defaultValue={offerData?.specialOffer_discription}
                                name="specialOffer_discription"
                                className="form-control"
                                id="horizontal-password-input"
                              />
                            </div>
                          </div>

                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Special Offer Discount
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                defaultValue={offerData?.specialOffer_discount}
                                onChange={(e) =>
                                  updateValue("specialOffer_discount", e.target.value)
                                }
                                name="specialOffer_discount"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </>)}
                        { isBookingAllowed && (

                        <> 
                        <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Booking Text
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                value={offerData?.bookingText}
                                onChange={(e) =>
                                  updateValue("bookingText", e.target.value)
                                }
                                name="bookingText"
                                className="form-control"
                              />
                            </div>
                          </div>


                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Booking Icon
                          </label>
                          <div className="col-sm-6">

                              {icon && (
                                            <img
                                              onClick={() => {
                                                openIconModal();
                                              }}
                                              src={icon}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}

                                          <br/><br/>

                                         
                                            <button
                                              type="button"
                                              onClick={() => {
                                                openIconModal();
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "tomato",
                                                border: "none",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Select Icon
                                            </button>
                                         
                           
                          </div>
                        </div>
                        </> 
                        
                        )}


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Cancellation Policy
                          </label>
                          <div className="col-sm-8">
                            <button
                              type="button"
                              onClick={() => setPolicyModal(true)}
                              className="btn btn-primary mb-3"
                            >
                              Update Cancellation Policy
                            </button> 
                          </div>
                        </div>


                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <VenueModal
        show={model}
        closeModal={closeModalVenue}
        selectRow={selectRow}
      />


      <HolidayModal
        showUser={modelHoliday}
        closeUserModal={closeModalHoliday}
        onSelectUser={selectHoliday}
      />


      <OfferPackageModal
        show={openPackage}
        closeModal={closePackageModal}
        addData={addPackageData}
        currentPackageData={currentPackageData}
      />
       <CancelPolicyModal
        show={policyModal}
        closeModal={closePolicyModal}
        addData={addPolicyData} 
        currentPolicyData={offerData?.cancelPolicy}       
      />
         <IconPackModal
        show={iconModal}
        closeModal={closeIconModal}
        selectRow={selectIconRow}
      />
      <FileUpload message="Logo & Cover Uploading" status={startLoader} />
      {/* modal */}
    </React.Fragment>
  );
}
