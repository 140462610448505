import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,  
  Form, 
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm } from "../../components/Common/Utils.js";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { te } from "date-fns/locale";

const CreateSubscription = () => {
 
  const [errors, setErrors] = useState({});
  const [isVenueListningAllowed, setIsVenueListningAllowed] = useState(false);
  const [isMessaging, setIsMessaging] = useState(false);
  const [isDealAllowed, setIsDealAllowed] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [isStoryListing, setIsStoryListing] = useState(false);
  const [isPromote, setIsPromote] = useState(false);
  const [isPromote5, setIsPromote5] = useState(false);
  const [isAnalytic, setIsAnalytic] = useState(false);
  const [isPlacementDiscoverAllowed, setIsPlacementDiscoverAllowed] = useState(false);  
  const [isManageBy7, setisManageBy7] = useState(false);  
  const [isAppBannerAllowed, setIsAppBannerAllowed] = useState(false);
  const [isOfferListning, setIsOfferListning] = useState(false);
  const [isUnlimitedOffers, setIsUnlimitedOffers] = useState(false);
  const [isPushNotificationAllowed, setIsPushNotificationAllowed] = useState(false);
  const [isDiscountAllowed, setIsDiscountAllowed] = useState(false);
  const [isSliderVenue, setIsSliderVenue] = useState(false);
  const [isDashboardAllowed, setIsDashboardAllowed] = useState(false);
  const [isFilterOnTop, setIsFilterOnTop] = useState(false);
  const [promoteReel, setPromoteReel] = useState(false);
  const [isPlacementRecommendedVenue, setIsPlacementRecommendedVenue] = useState(false);
  const [isPlacementTrendingVenue, setIsPlacementTrendingVenue] = useState(false);
  const [isSocialMediaExposure, setIsSocialMediaExposure] = useState(false);
  const [isSocialMediaPromotion, setIsSocialMediaPromotion] = useState(false);
  const [isMarketingAndAdvertising, setIsMarketingAndAdvertising] = useState(false);
  const [packageData, setPackageData] = useState({
    validity: "month",
    type: "basic"
  });
  const [loading, setLoading] = useState(false);
  let history = useHistory();   


    // **Validation Function**
    const validateForm = () => {
      let tempErrors = {};

      if(!packageData?.title) {
        tempErrors.title = "Title is required";
      }

      if(!packageData?.type) {
        tempErrors.type = "Type is required";
      }

      if(!packageData?.amount) {
        tempErrors.amount = "Amount is required";
      }

      // check amount not negative
      if(packageData?.amount < 0) {
        tempErrors.amount = "Amount should be greater than 0";
      }
      setErrors(tempErrors);
      return Object.keys(tempErrors).length === 0;
    };

  const Createpackage = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      return;
    }

    // if(!packageData?.title) {
    //  tempErrors.title = "Title is required";
    //   return;
    // }
    // if (!packageData?.type) {
    //   tempErrors.type = "Type is required";
    //   return;
    // }
    // if (!packageData?.amount) {
    //   tempErrors.amount = "Amount is required";
    //   return;
    // }
    // // check amount not negative 
    // if (packageData?.amount < 0) {
    //   tempErrors.amount = "Amount should be greater than 0";
    //   return;
    // }

    // check commissionOnOfferPurchase 0 -`100

    if (packageData?.commissionOnOfferPurchase < 0 || packageData?.commissionOnOfferPurchase > 100) {
      toast.error("Commission on offer purchase should be between 0 to 100");
      return;
    }

    const formData = new FormData();  

    formData.append("title", packageData.title);
    formData.append("type", packageData.type);
    formData.append("amount", packageData.amount || 0);
    formData.append("validity", packageData.validity);
    formData.append("isPopular", packageData.isPopular || false);
    formData.append("isVenueListioning", 1);
    formData.append("placementDiscover", isPlacementDiscoverAllowed);
    formData.append("placementDiscoverCount", packageData?.placementDiscoverCount || 0);
    formData.append("placementRecommendedVenue", isPlacementRecommendedVenue);
    formData.append("placementOnTrendingVenue", isPlacementTrendingVenue);
    formData.append("isMessangingAllowed", isMessaging);
    formData.append("isDealAllowed", isDealAllowed);
    formData.append("isBookingAllowed", isBooking);
    formData.append("isOfferlisting", isOfferListning);
    formData.append("OfferlistingLimit",  isUnlimitedOffers ? - 1 :  packageData?.offerListningLimit || 0    );
    formData.append("commissionOnOfferPurchase", packageData?.commissionOnOfferPurchase || 0);
    formData.append("offerAddCharge", packageData?.offerAddCharge || 0);

    formData.append("isStoryListing", isStoryListing);
    formData.append("StoryListingLimit", packageData?.storyListingLimit || 0);     
    
    formData.append("isPromoteAllowed", isPromote);
    formData.append("isPromoteTop5", isPromote5);
    formData.append("isManageBy7n", isManageBy7); 
    formData.append("haveAnalytics", isAnalytic);
    
    formData.append("isAppBannerAllowed", isAppBannerAllowed);
    formData.append("appBannerLimit", packageData?.appBannerLimit || 0);
    formData.append("isPushNotificationAllowed", isPushNotificationAllowed);
    formData.append("pushNotificationLimit", packageData?.pushNotificationLimit || 0);

    formData.append("isDiscount", isDiscountAllowed);
    formData.append("discountLimit", packageData?.discountLimit || 0);
    formData.append("isDashboard", 1);
    formData.append("isFilterOnTop", isFilterOnTop);
    formData.append("sliderVenue", isSliderVenue);
    formData.append("socialMediaExposure", isSocialMediaExposure);
    formData.append("socialMediaPromotion", isSocialMediaPromotion);
    formData.append("marketingAd", isMarketingAndAdvertising);
    formData.append("promotedReel", promoteReel);    
    
    setLoading(true);
    const response = await postRequestForm(
      "subscription/venue/create",
      formData
    );
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/venue/subscriptions");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...packageData };
    list[index] = value;
    setPackageData(list);   
  };


  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Subscription Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Subscription Management</li>:{" "}
              <li className="breadcrumb-item">Create Subscription</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Package </h4>
                <Form onSubmit={Createpackage}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input                              
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                             {errors.title && <span className="text-danger">{errors.title}</span>}
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              name="type"
                              class="form-select"
                            >
                              
                              <option value="basic">Basic</option>
                              <option value="basic-benefits">Basic Benefits</option>
                              <option value="add-on-package">Add on Package</option>
                              <option value="get-promoted">Get Promoted</option>
                              <option value="pay-as-you-go">Pay As You Go</option>
                            </select>
                            {errors.type && <span className="text-danger">{errors.type}</span>}
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("amount", e.target.value)
                              }
                              name="amount"
                              className="form-control"
                            />
                            {errors.amount && <span className="text-danger">{errors.amount}</span>}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Validity
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("validity", e.target.value)
                              }
                              name="validity"
                              class="form-select"
                            >
                              <option value="month">Monthly</option> 
                              <option value="quarter">quarterly</option>                            
                              <option value="year">Yearly</option>
                              <option value="week">Weekly</option>
                              <option value="day">Daily</option>
                            </select>
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Is Popular
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("isPopular", e.target.value)
                              }
                              name="validity"
                              class="form-select"
                            >
                              <option value="false">No</option> 
                              <option value="true">Yes</option>                          
                             
                            </select>
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Placement on Discover
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPlacementDiscoverAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPlacementDiscoverAllowed(e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="isPlacementDiscoverAllowed"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPlacementDiscoverAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPlacementDiscoverAllowed(!e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="false"
                                  name="isPlacementDiscoverAllowed"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>


                        {isPlacementDiscoverAllowed && (
                              <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Placement  on Discover Limit                           
                              </label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                min={0}
                                onChange={(e) =>
                                  updateValue("placementDiscoverCount", e.target.value)
                                }
                                name="placementDiscoverCount"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Placement on Recommended Venue
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPlacementRecommendedVenue}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPlacementRecommendedVenue(e.target.checked)                                      
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="isPlacementDiscoverAllowed"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPlacementRecommendedVenue}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPlacementRecommendedVenue(!e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="false"
                                  name="isPlacementDiscoverAllowed"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Get listed on trending & hottest venues
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPlacementTrendingVenue}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPlacementTrendingVenue(e.target.checked)                                  
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="isPlacementDiscoverAllowed"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPlacementTrendingVenue}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPlacementTrendingVenue(!e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="false"
                                  name="isPlacementDiscoverAllowed"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>




                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Messaging With users
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isMessaging}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsMessaging(e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="isMessanging"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isMessaging}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsMessaging(!e.target.checked)
                                    }                                    
                                  }}
                                  color="primary"
                                  value="false"
                                  name="isMessanging"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Booking System
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isBooking}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsBooking(e.target.checked)
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="true"
                                  name="isBooking"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isBooking}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsBooking(!e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="false"
                                  name="isBooking"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>


                         <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Offer Listning
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isOfferListning}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsOfferListning(e.target.checked)                                      
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="true"
                                  name="isOfferAdd"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isOfferListning}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsOfferListning(!e.target.checked)
                                    }                                    
                                  }}
                                 
                                  color="primary"
                                  value="false"
                                  name="isOfferAddAllowed"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        {isOfferListning && (

                          <>

                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Offer Add Commission                            </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              max={100}
                              onChange={(e) =>
                                updateValue("commissionOnOfferPurchase", e.target.value)
                              }
                              name="commissionOnOfferPurchase"
                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Unlimited Offers
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isUnlimitedOffers}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsUnlimitedOffers(e.target.checked)                                                                         
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="true"
                                  name="isOfferAdd"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isUnlimitedOffers}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsUnlimitedOffers(!e.target.checked)
                                    }                                    
                                  }}
                                 
                                  color="primary"
                                  value="false"
                                  name="isOfferAddAllowed"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>


                        { !isUnlimitedOffers && (
                                  <>


                              <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Offer Listning Limit
                              </label>

                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  min={0}
                                  onChange={(e) =>
                                    updateValue("offerListningLimit", e.target.value)
                                  }
                                  name="offerListningLimit"
                                  className="form-control"
                                />
                              </div>                              
                            </div>

                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Additional Offer Add Charges
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  min={0}
                                  onChange={(e) =>
                                    updateValue("offerAddCharge", e.target.value)
                                  }
                                  name="offerAddCharge"
                                  className="form-control"
                                />
                              </div>
                            </div>   

                            </>

                              )}



                        </>
                        )}
                          

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Stories Placement on 7nights
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isStoryListing}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsStoryListing(e.target.checked)                                      
                                    }                                    
                                  }}
                                  color="primary"
                                  value="true"
                                  name="isAddStoryAllowed"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isStoryListing}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsStoryListing(!e.target.checked)
                                    }                                    
                                  }}
                                  color="primary"
                                  value="false"
                                  name="isAddStoryAllowed"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        { isStoryListing && (
                            <>

                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Story Listning Limit                            </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  min={0}
                                  onChange={(e) =>
                                    updateValue("storyListingLimit", e.target.value)
                                  }
                                  name="addStoryLimit"
                                  className="form-control"
                                />
                              </div>
                            </div> 
                          </>
                        )}


                        { isPromote && (<>
                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Promote In Top 5
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPromote5}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPromote5(e.target.checked)
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="true"
                                  name="isPromote"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPromote5}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPromote5(!e.target.checked)
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="false"
                                  name="isPromote"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Manage By 7 Nights
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isManageBy7}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setisManageBy7(e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="isManageBy7n"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isManageBy7}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setisManageBy7(!e.target.checked)
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="false"
                                  name="isManageBy7n"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>
                        </>)}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Dashboard users & Business Analytics
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isAnalytic}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsAnalytic(e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isAnalytic}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsAnalytic(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            App Banners Allowed
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isAppBannerAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsAppBannerAllowed(e.target.checked)                                      
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isAppBannerAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsAppBannerAllowed(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        {isAppBannerAllowed && (

                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            App Banner Limit
                          </label>

                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              onChange={(e) =>
                                updateValue("appBannerLimit", e.target.value)
                              }
                              name="appBannerLimit"
                              className="form-control"
                            />

                          </div>
                        </div>
                          
                          )}


                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Push Notification Allowed
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPushNotificationAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPushNotificationAllowed(e.target.checked)                                                                         
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPushNotificationAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPushNotificationAllowed(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        {isPushNotificationAllowed && (
                           
                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Push Notification Limit
                          </label>

                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              onChange={(e) =>
                                updateValue("pushNotificationLimit", e.target.value)
                              }
                              name="pushNotificationLimit"
                              className="form-control"
                            />

                          </div>

                        </div>

                        )} 

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Heighlight Special Discount
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isDiscountAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsDiscountAllowed(e.target.checked)                                                                         
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isDiscountAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsDiscountAllowed(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        {isDiscountAllowed && (
                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Limit
                          </label>

                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              onChange={(e) =>
                                updateValue("discountLimit", e.target.value)
                              }
                              name="discountLimit"
                              className="form-control"
                            />

                          </div>
                        </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Highligted offers on Top within Search & Filter
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isFilterOnTop}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsFilterOnTop(e.target.checked)                                                                                                               
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isFilterOnTop}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsFilterOnTop(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Special Slider for Venue
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isSliderVenue}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsSliderVenue(e.target.checked)                                                                                                                                                                                                                            
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isSliderVenue}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsSliderVenue(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Social Media Exposure
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isSocialMediaExposure}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsSocialMediaExposure(e.target.checked)                                                                   
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isSocialMediaExposure}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsSocialMediaExposure(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Promote Reel
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={promoteReel}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setPromoteReel(e.target.checked)                                                                                                                                   
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!promoteReel}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setPromoteReel(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>      

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Social Media Promotion
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isSocialMediaPromotion}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsSocialMediaPromotion(e.target.checked)                                                                                   
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isSocialMediaPromotion}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsSocialMediaPromotion(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>     


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Marketing And Advertising
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isMarketingAndAdvertising}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsMarketingAndAdvertising(e.target.checked)                                 
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isMarketingAndAdvertising}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsMarketingAndAdvertising(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div> 

                    
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>            
    </React.Fragment>
  );
}

export default CreateSubscription