import React, { useState, useEffect } from "react";
import { Row, Col, Button, Badge } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/src/sweetalert2.scss";
import "./SpecialOfferClaimManagementlist.scss";
import FilterModal from "../../components/Modal/FilterModal"; // assumed to exist
import { postRequest } from "../../components/Common/Utils.js";
import { useLocation } from "react-router-dom";

// Alternative placeholder for venue logo using dummyimage.com
const VENUE_IMAGE_PLACEHOLDER =
  "https://dummyimage.com/100x60/cccccc/000000.png&text=SC";
// For offer image, no placeholder is needed.
  
// Initial filter values
const initialFilterObj = {
  offerTitle: "",
  userName: "",
  venueName: "",
  offerName: "",
  typeT: "",
  discount: "",
  claimCode: "",
  billAmount: "",
  createdAt: "",
};

/**
 * Helper: Returns voucher count text if applicable.
 */
const getVoucherCount = (row) => {
  if (row.qty && row.qty > 1) {
    return `X ${row.qty}`;
  }
  return null;
};

/**
 * Computes summary values for a deal order.
 */
const calculateSummary = (row) => {
  const subtotal = row.price || 0;
  const vat = 0; // VAT will be computed in packages section if applicable.
  const discount = 0;
  const finalPrice = subtotal;
  return { subtotal, vat, discount, finalPrice };
};

// ------------------ PRINT & PDF FUNCTIONS ------------------
// (Print functionality is hidden for now)
function buildAllItemsHTML(dataList) {
  const inlineCSS = `
    body {
      font-family: Arial, sans-serif;
      margin: 20px;
      background: #f2f2f2;
      color: #000;
    }
    .account-statement {
      width: 80%;
      margin: 0 auto;
    }
    .statement-item {
      background-color: #fff;
      border-radius: 8px;
      padding: 15px;
      min-height: 980px;   
      margin-bottom: 20px;
      box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    }
    .top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .left-content {
      display: flex;
      flex-direction: column;
    }
    .date-time {
      font-size: 0.875rem;
      color: #777;
      margin-bottom: 0.25rem;
    }
    .title {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 0.25rem;
      color: #333;
    }
    .payment-status-badge {
      font-size: 0.8rem;
      text-transform: capitalize;
      padding: 0.4em 0.6em;
      border-radius: 0.25rem;
    }
    .paid {
      background-color: #28a745 !important;
      color: #fff !important;
    }
    .unpaid {
      background-color: #dc3545 !important;
      color: #fff !important;
    }
    .amount {
      font-size: 0.95rem;
    }
    .credit {
      color: green;
    }
    .debit {
      color: red;
    }
    .detail-section {
      margin-top: 15px;
      background-color: #F0F1F2;
      border-radius: 6px;
      padding: 15px;
    }
    .detail-card {
      background-color: #fff;
      border-radius: 8px;
      padding: 15px;
      box-shadow: 0 2px 8px rgba(0,0,0,0.1);
      margin-bottom: 15px;
    }
    .ticket-ui {
      border: 2px dashed #ccc;
      border-radius: 8px;
      padding: 1rem;
      text-align: center;
      margin-bottom: 1rem;
      position: relative;
    }
    .ticket-count {
      font-size: 1.6rem;
      font-weight: 700;
      color: #d9534f;
      margin-bottom: 0.5rem;
    }
    .ticket-title {
      font-size: 1.1rem;
      font-weight: 600;
    }
    .ticket-offer {
      font-size: 0.9rem;
      color: #555;
      margin: 0.3rem 0;
    }
    .ticket-desc {
      font-size: 0.85rem;
      color: #777;
    }
    .deal-info {
      border: 1px solid #ccc;
      padding: 1rem;
      margin-top: 1rem;
    }
    .deal-image {
      width: 150px;
      margin-top: 0.5rem;
    }
    .invoice-container {
      margin-bottom: 10px;
    }
    .invoice-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
    .user-info {
      display: flex;
      align-items: center;
    }
    .user-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
    .offer-venue-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .venue-offer-images {
      margin-bottom: 5px;
    }
    .venue-image,
    .offer-image {
      width: 80px;
      height: 50px;
      object-fit: cover;
      border-radius: 4px;
      margin-left: 5px;
    }
    .invoice-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 0.5rem;
    }
    .invoice-table th {
      text-align: left;
      border-bottom: 1px solid #ccc;
      padding-bottom: 8px;
      font-weight: 600;
      font-size: 0.9rem;
    }
    .invoice-table td {
      border-bottom: 1px solid #eee;
      padding: 6px 0;
      font-size: 0.9rem;
    }
    .summary-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 0.5rem;
    }
    .summary-table td {
      border-bottom: 1px solid #eee;
      padding: 6px 0;
      font-size: 0.9rem;
    }
    .total-row td {
      font-weight: 600;
      border-bottom: 2px solid #333;
    }
  `;

  const htmlRows = dataList
    .map((row) => {
      const dateTime = new Date(row.createdAt).toLocaleString();
      const voucherCount = getVoucherCount(row);
      const isPaid = row.paymentStatus === "paid";
      const sign = isPaid && row.price > 0 ? "+" : "-";
      const payBadgeClass = isPaid ? "paid" : "unpaid";
      const absAmount = Math.abs(row.price).toFixed(2);
      const { subtotal, vat, discount, finalPrice } = calculateSummary(row);
      return `
        <div class="statement-item">
          <div class="top-row">
            <div class="left-content">
              <div class="date-time">${dateTime}</div>
              <div class="title">
                ${row.deal?.title || row.typeName || row.type || "Deal Name"}
              </div>
            </div>
            <div>
              <span class="payment-status-badge ${payBadgeClass}">
                ${row.paymentStatus || "N/A"}
              </span>
            </div>
            <div class="amount">
              <span class="${isPaid && row.price > 0 ? "credit" : "debit"}">
                ${sign} AED ${absAmount}
              </span>
            </div>
          </div>
          <!-- Additional details can be added here -->
        </div>
      `;
    })
    .join("");

  return `
    <html>
      <head>
        <meta charset="utf-8"/>
        <title>Printable Deal Summary</title>
        <style>${inlineCSS}</style>
      </head>
      <body>
        <div class="account-statement">
          ${htmlRows}
        </div>
      </body>
    </html>
  `;
}

function printDocument(htmlString) {
  const printWindow = window.open("", "_blank");
  if (!printWindow) return;
  printWindow.document.open();
  printWindow.document.write(htmlString);
  printWindow.document.close();
  setTimeout(() => {
    printWindow.focus();
    printWindow.print();
  }, 500);
}

function printAllItems(offerData) {
  const htmlString = buildAllItemsHTML(offerData);
  printDocument(htmlString);
}

function printSingleItem(row) {
  const htmlString = buildAllItemsHTML([row]);
  printDocument(htmlString);
}

// ------------------ CSV EXPORT FUNCTIONS ------------------

function buildCsvRow(row) {
  const userName = row.user?.name || row.userDetails?.name || "Unknown User";
  const venueName = row.deal?.venue?.name || row.venueName || "Unknown Venue";
  const orderId = row.orderId || "No Order ID";
  const payStatus = row.paymentStatus || "N/A";
  const price = row.price || 0;
  const type = row.typeName || row.type || "Deal Name";
  const dateStr = new Date(row.createdAt).toLocaleString();
  return [
    row._id || "",
    dateStr,
    userName,
    venueName,
    orderId,
    payStatus,
    type,
    price,
  ].join(",");
}

function downloadAllCSV(offerData) {
  const headers = [
    "ID",
    "Created At",
    "User Name",
    "Venue Name",
    "Order ID",
    "Payment Status",
    "Type",
    "Price",
  ].join(",");
  const csvRows = [headers];
  offerData.forEach((row) => {
    csvRows.push(buildCsvRow(row));
  });
  const csvString = csvRows.join("\n");
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "deal-orders.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function downloadSingleCSV(row) {
  const headers = [
    "ID",
    "Created At",
    "User Name",
    "Venue Name",
    "Order ID",
    "Payment Status",
    "Type",
    "Price",
  ].join(",");
  const dataRow = buildCsvRow(row);
  const csvString = headers + "\n" + dataRow;
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `deal-order-${row._id}.csv`);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// ------------------ MAIN COMPONENT ------------------

const SpecialOfferClaimManagementlist = () => {
  const location = useLocation();
  const [offerData, setOfferData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState({ ...initialFilterObj });
  const [filterActive, setFilterActive] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const sortField = "createdAt";
  const sortOrder = "desc";

  const computeFilterArgs = () => {
    const filterArgs = [];
    Object.keys(filter).forEach((key) => {
      if (filter[key] && filter[key].trim() !== "") {
        let filterObject = { [key]: filter[key].trim() };
        if (
          ["offerTitle", "description", "userName", "venueName", "offerName"].includes(key)
        ) {
          filterObject.type = "regex";
        } else if (
          ["status", "claimCode", "typeT", "discount", "billAmount"].includes(key)
        ) {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }
        filterArgs.push(filterObject);
      }
    });
    return filterArgs;
  };

  const fetchSpecialOfferClaims = async (page) => {
    const limit = page === 1 ? 50 : 25;
    // Updated endpoint and payload for deal orders
    const payload = { limit, page, sortField, sortOrder, type: "deal", paymentStatus: "paid" };

    if (searchText.trim() !== "") {
      payload.search = searchText.trim();
    }
    if (filterActive) {
      payload.filterArgs = computeFilterArgs();
    }
    if (location.state) {
      const { startDate, endDate, paymentStatus } = location.state;
      let locationFilter = [
        { paymentStatus: paymentStatus, type: "eq" },
        { startDate: startDate, type: "date-range" },
        { endDate: endDate, type: "date-range" },
      ];
      payload.filterArgs = payload.filterArgs
        ? [...payload.filterArgs, ...locationFilter]
        : locationFilter;
    }

    try {
      const response = await postRequest("subscription/order/list/admin", payload);
      const data = response.data;
      setTotalItems(data.count);
      if (page === 1) {
        setOfferData(data.list);
      } else {
        setOfferData((prev) => [...prev, ...data.list]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    fetchSpecialOfferClaims(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const toggleRow = (id) => {
    setExpandedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const updateFilterField = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const applyFilter = () => {
    setFilterActive(true);
    setCurrentPage(1);
    fetchSpecialOfferClaims(1);
    setFilterModalOpen(false);
  };

  const resetFilter = () => {
    setFilterActive(false);
    setFilter({ ...initialFilterObj });
    setSearchText("");
    setCurrentPage(1);
    fetchSpecialOfferClaims(1);
    setFilterModalOpen(false);
  };

  // Filter modal components for each field
  const filterComponents = [
    <div className="row mb-4" key="offerTitle">
      <label className="col-sm-4 col-form-label">Special Offer</label>
      <div className="col-sm-8">
        <input
          type="text"
          value={filter.offerTitle}
          onChange={(e) => updateFilterField("offerTitle", e.target.value)}
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="userName">
      <label className="col-sm-4 col-form-label">User Name</label>
      <div className="col-sm-8">
        <input
          type="text"
          value={filter.userName}
          onChange={(e) => updateFilterField("userName", e.target.value)}
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="venueName">
      <label className="col-sm-4 col-form-label">Venue</label>
      <div className="col-sm-8">
        <input
          type="text"
          value={filter.venueName}
          onChange={(e) => updateFilterField("venueName", e.target.value)}
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="offerName">
      <label className="col-sm-4 col-form-label">Offer</label>
      <div className="col-sm-8">
        <input
          type="text"
          value={filter.offerName}
          onChange={(e) => updateFilterField("offerName", e.target.value)}
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="discount">
      <label className="col-sm-4 col-form-label">Discount</label>
      <div className="col-sm-8">
        <input
          type="text"
          value={filter.discount}
          onChange={(e) => updateFilterField("discount", e.target.value)}
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="typeT">
      <label className="col-sm-4 col-form-label">Type</label>
      <div className="col-sm-8">
        <select
          value={filter.typeT}
          onChange={(e) => updateFilterField("typeT", e.target.value)}
          className="form-select"
        >
          <option value="">Select Type</option>
          <option value="total">Total</option>
          <option value="brunch">Brunch</option>
        </select>
      </div>
    </div>,
    <div className="row mb-4" key="claimCode">
      <label className="col-sm-4 col-form-label">Claim Code</label>
      <div className="col-sm-8">
        <input
          type="text"
          value={filter.claimCode}
          onChange={(e) => updateFilterField("claimCode", e.target.value)}
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="billAmount">
      <label className="col-sm-4 col-form-label">Claim Amount</label>
      <div className="col-sm-8">
        <input
          type="text"
          value={filter.billAmount}
          onChange={(e) => updateFilterField("billAmount", e.target.value)}
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="createdAt">
      <label className="col-sm-4 col-form-label">Claimed At</label>
      <div className="col-sm-8">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilterField("createdAt", e.target.value)}
          className="form-control"
        />
      </div>
    </div>,
    <div className="mt-4 text-end" key="filterActions">
      <Button onClick={applyFilter} style={{ background: "#E32A2B",  border: "none", color: "white", marginRight: "1rem" }}>
        Apply
      </Button>
      <Button onClick={resetFilter} style={{  border: "none", color: "white" }}>
        Reset
      </Button>
    </div>,
  ];

  return (
    <>
      <ToastContainer />
      <Row>
        <Col className="12">
          <div className="page-title-box">
            <h4>Deal Sales Statement</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Deal Sales Logs</li>
              <li className="breadcrumb-item">Deal Sales Statement</li>
            </ol>
          </div>
        </Col>
        {/* Main Panel: Combined Search, Filter, and CSV Download Controls */}
        <Col className="col-12 mb-3">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              backgroundColor: "#E32A2B",
              padding: "10px 15px",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex align-items-center gap-3">
              {/* <input
                type="search"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="form-control"
                style={{
                  maxWidth: "300px",
                  border: "2px solid white",
                  borderRadius: "5px",
                  padding: "0.5rem 1rem",
                  fontSize: "1rem",
                  background: "transparent",
                  color: "white",
                }}
              /> */}
              {/* <Button
                onClick={() => {
                  setCurrentPage(1);
                  fetchSpecialOfferClaims(1);
                }}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "white",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                }}
              >
                Search
              </Button> */}
              {/* <Button
                onClick={() => {
                  setSearchText("");
                  setFilter({ ...initialFilterObj });
                  setFilterActive(false);
                  setCurrentPage(1);
                  fetchSpecialOfferClaims(1);
                }}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "white",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                }}
              >
                Reset
              </Button> */}
              <Button
                onClick={() => setFilterModalOpen(true)}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "white",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                }}
              >
                Filter
              </Button>
            </div>
            <div className="d-flex align-items-center gap-3">
              {/* Only CSV icon shown */}
              <button
                style={{ border: "none", background: "transparent", color: "white" }}
                onClick={() => downloadAllCSV(offerData)}
                title="Download All CSV"
              >
                <i className="fas fa-file-csv" style={{ fontSize: "1.2rem" }}></i>
              </button>
            </div>
          </div>
        </Col>
        {/* Deal Sales Statement Section */}
        <Col className="col-12">
          <div className="account-statement">
            {offerData.length === 0 && (
              <div className="text-center">No data available</div>
            )}
            {offerData.map((row) => {
              const isExpanded = expandedRows.includes(row._id);
              const { subtotal, vat, discount, finalPrice } = calculateSummary(row);
              const voucherCount = getVoucherCount(row);
              // Prepare user image and name using nested user info
              const userImg =
                row.user && row.user.image && row.user.image.trim() !== ""
                  ? row.user.image
                  : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                      row.user?.name || row.userDetails?.name || "User"
                    )}&size=80&background=random`;
              const userName = row.user?.name || row.userDetails?.name || "Unknown User";
              return (
                <div className="statement-item" key={row._id}>
                  <div className="top-row">
                    <button
                      className="expand-btn"
                      onClick={() => toggleRow(row._id)}
                    >
                      {isExpanded ? "–" : "+"}
                    </button>
                    <div className="left-content">
                      <div className="date-time">
                        {new Date(row.createdAt).toLocaleString()}
                      </div>
                      <div className="title">
                        {row.deal?.title || row.typeName || row.type || "Deal Name"}
                      </div>
                    </div>
                    <div className="middle-content">
                      <Badge
                        className={`payment-status-badge ${
                          row.paymentStatus === "paid" ? "paid" : "unpaid"
                        }`}
                      >
                        {row.paymentStatus || "N/A"}
                      </Badge>
                    </div>
                    <div className="right-content">
                      <div className="amount">
                        {row.price > 0 && row.paymentStatus === "paid" ? (
                          <span className="credit">
                            + AED {row.price.toFixed(2)}
                          </span>
                        ) : (
                          <span className="debit">
                            - AED {Math.abs(row.price).toFixed(2)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {isExpanded && (
                    <div className="detail-section">
                      <div className="detail-card">
                        <div className="ticket-ui">
                          {voucherCount && (
                            <div className="ticket-count">{voucherCount}</div>
                          )}
                          <h5 className="ticket-title">Order Details</h5>
                          <p className="ticket-offer">
                            Order ID: {row.orderId || "N/A"}
                          </p>
                          <p className="ticket-desc">
                            Deal ID: {row.dealId || "N/A"} | Date: {row.date || "N/A"} | Time: {row.time || "N/A"}
                          </p>
                        </div>
                        {/* Deal Details within a bordered container */}
                        <div className="deal-info">
                          <h5>{row.deal?.title || "No Deal Title"}</h5>
                          <p>{row.deal?.description || "No Deal Description"}</p>
                          {row.deal?.image && row.deal.image.trim() !== "" && (
                            <img
                              src={row.deal.image}
                              alt={row.deal.title || "Deal Image"}
                              className="deal-image"
                              style={{ width: "150px" }}
                            />
                          )}
                        </div>
                        <div className="invoice-container">
                          <div className="invoice-header">
                            <div className="user-info">
                              <img
                                src={userImg}
                                alt="User"
                                className="user-image"
                                style={{ width: "40px", height: "40px" }}
                              />
                              <div>
                                <h5>{userName}</h5>
                                <p>ID: {row.userId}</p>
                              </div>
                            </div>
                            <div className="offer-venue-info">
                              <div className="venue-offer-images">
                                <img
                                  src={
                                    row.deal?.venue?.logo && row.deal.venue.logo.trim() !== ""
                                      ? row.deal.venue.logo
                                      : VENUE_IMAGE_PLACEHOLDER
                                  }
                                  alt="Venue"
                                  className="venue-image"
                                />
                                {row.offerImage &&
                                  row.offerImage.trim() !== "" && (
                                    <img
                                      src={row.offerImage}
                                      alt="Offer"
                                      className="offer-image"
                                    />
                                  )}
                              </div>
                              <div className="venue-offer-text">
                                <h5>
                                  {row.deal?.venue?.name || row.venueName || "Unknown Venue"}
                                </h5>
                                <p>
                                  Offer: {row.offerName || row.deal?.title || "Unknown Offer"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="invoice-body">
                            {row.deal?.packages?.length > 0 && (
                              <div className="deal-packages-section mt-3">
                                <h6>Deal Packages Cost Breakdown</h6>
                                <table className="invoice-table">
                                  <thead>
                                    <tr>
                                      <th>Ticket No.</th>
                                      <th>Title</th>
                                      <th>Qty</th>
                                      <th>Price / Unit</th>
                                      <th>Total Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {row.deal.packages.map((pkg, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{pkg.title}</td>
                                        <td>{pkg.qty}</td>
                                        <td>{pkg.pricePerUnit}</td>
                                        <td>{pkg.totalPrice}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                {(() => {
                                  let totalCost = row.deal.packages.reduce(
                                    (acc, p) => acc + p.totalPrice,
                                    0
                                  );

                                  totalCost = row.qty ? totalCost * row.qty : totalCost;



                                  const paymentGateway = totalCost * 0.03; // 3%
                                  const commission = row.deal.venue.commission ?  totalCost * 0.05 : 0;    // 5%
                                  // VAT calculation: if isTaxIncluded then VAT = 0, otherwise calculate using deal.vat percentage
                                  const vat = row.deal?.isTaxIncluded
                                    ? 0
                                    : row.deal?.vat
                                    ? totalCost * (row.deal.vat / 100)
                                    : 0;
                                  const supplierPaid = row.deal?.isTaxIncluded
                                    ? totalCost - paymentGateway - commission
                                    : totalCost - paymentGateway - commission - vat;
                                  return (
                                    <div style={{ marginTop: "1rem" }}>
                                      <p>
                                        <strong>Total Cost:</strong> AED {totalCost.toFixed(2)}
                                      </p>
                                      <p>
                                        <strong>3% Payment Gateway Fee:</strong> - AED {paymentGateway.toFixed(2)}
                                      </p>
                                      <p>
                                        <strong> {row.deal.venue.commission}% 7Nights Contract Commission:</strong> - AED {commission.toFixed(2)}
                                      </p>
                                      <p>
                                        <strong>VAT:</strong> AED {vat.toFixed(2)}
                                      </p>
                                      <p style={{ marginTop: "0.5rem" }}>
                                        <strong>Paid to Supplier:</strong> AED {supplierPaid.toFixed(2)}
                                      </p>
                                    </div>
                                  );
                                })()}
                              </div>
                            )}
                          </div>
                          <div className="invoice-summary">
                            <table className="summary-table">
                              <tbody>
                                <tr>
                                  <td>Subtotal</td>
                                  <td>AED {subtotal.toFixed(2)}</td>
                                </tr>
                                <tr>
                                  <td>VAT</td>
                                  <td>
                                    {row.deal?.isTaxIncluded
                                      ? "AED 0.00"
                                      : row.deal?.vat
                                      ? `AED ${(subtotal * (row.deal.vat / 100)).toFixed(2)}`
                                      : "AED 0.00"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Discount</td>
                                  <td>{discount}</td>
                                </tr>
                                <tr className="total-row">
                                  <td>Total</td>
                                  <td>AED {finalPrice.toFixed(2)}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center gap-3 mt-2">
                        {/* Only CSV download button is shown */}
                        <button
                          style={{ border: "none", background: "transparent", color: "white" }}
                          onClick={() => downloadSingleCSV(row)}
                          title="Download Single CSV"
                        >
                          <i className="fas fa-file-csv" style={{ fontSize: "1.2rem" }}></i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {offerData.length > 0 && offerData.length < totalItems && (
              <div className="text-center mt-3">
                <Button color="primary" onClick={handleLoadMore}>
                  Load More
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
      {filterModalOpen && (
        <FilterModal
          show={filterModalOpen}
          closeModal={() => setFilterModalOpen(false)}
          components={filterComponents}
        />
      )}
    </>
  );
};

export default SpecialOfferClaimManagementlist;
